/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('CatalogFiltersService', function () {

    return {
        // DOM filters bar
        bar : {
            toggled: false,
            toggle: function () {
                this.toggled = !this.toggled;
            }
        }
    };
});