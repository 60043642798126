'use strict';

angular.module('B2B').controller('HomeExportController', [
    '$scope', '$state', '$sce', 'mm.core.api.API', 'CustomerService',
    function ($scope, $state, $sce, API, CustomerService) {

        var vm = this;

        vm.loading = true;

        // Redirect to default home page for no export customers
        CustomerService.getCustomer().then(function (customer) {
            if (!customer || !customer.export) {
                $state.go('home');
            } else {
                API.get('cms/static-pages/s,home-export').then(function (page) {
                    vm.page = page;
                    vm.loading = false
                    vm.page.content = $sce.trustAsHtml(page.content);
                }, function () {
                    vm.loading = false
                });
            }
        });


        // Redirect to default home page for no export customers
        $scope.$on('customer.changed', function (event, customer) {
            if (!customer || !customer.export) {
                $state.go('home');
            }
        });
    }
]);