/**
 * @author Modest Machnicki
 */
'use strict';
//TODO move into core
angular.module('B2B').factory('ScreenService', ['$window', '$timeout', function ($window, $timeout) {

    return {
        /**
         * Simpler window resize listener
         * @param {function} callback
         */
        onResize: function (callback) {
            if (typeof callback === 'function') {
                angular.element($window).bind('resize', function () {
                    $timeout(function () {
                        callback($window.innerWidth, $window.innerHeight);
                    });
                });
            }
        }
    };

}]);