'use strict';

angular.module('B2B').controller('AuthPageController', [
    '$scope', '$state', '$window', '$location', 'CustomerService', 'mm.core.auth.AuthService', 'mm.core.api.CommandFactory',
    function ($scope, $state, $window, $location, CustomerService, AuthService, CommandFactory) {

        $scope.showRestPasswordForm = !!$state.params.passwordReset;

        $scope.$watchCollection('signInCommand.errors', function (newErrors) {
            if (newErrors && newErrors.global && newErrors.global === "Twoje hasło wygasło! Skorzystaj z opcji przypominania hasła, aby utworzyć nowe.") {
                $state.go('new-password');
            }
        });

        $scope.signInCommand = AuthService.signInCommand(['customer', 'trader'], function () {
            if ($state.params.redirect) {
                $location.url($state.params.redirect);
            } else if ($state.params.redirectAbs) { //Absolute redirection url
                $window.location.replace($state.params.redirectAbs);
            } else {
                $state.go('home');
            }
        });

        $scope.registrationCommand = CustomerService.registrationCommand(function () {
            $scope.userRegistered = true;
        });

        $scope.requestPasswordCommand = CommandFactory.create('password/request', 'post', {}, {
            successMessage: 'Email z linkiem do ustawienia nowego hasła został wysłany.'
        }).onSuccess(function () {
            $scope.showRestPasswordForm = false;
            this.fields.email = null;
        });

        $scope.expandedTerms = {'newsletter': false, 'privacy': false};
        $scope.expandTerms = function (termsName) {
            $scope.expandedTerms[termsName] = !$scope.expandedTerms[termsName];
        };
    }
]);
