'use strict';
angular.module('B2B').factory('CartService', [
    '$q',
    '$rootScope',
    'mm.core.api.API',
    'mm.core.auth.AuthService',
    'mm.core.api.CommandFactory',
    'mm.core.dom.NoticeService',
    'mm.core.dom.ConfirmDialogService',
    'CustomerSocketService',
    'Cart',
    function ($q,
              $rootScope,
              API,
              Auth,
              CommandFactory,
              NoticeService,
              ConfirmDialogService,
              CustomerSocketService,
              Cart) {

        var data = {
            loading: false,
            carts: [],
            currentCart: null
        };

        /**
         * Reload cart list and restore states (currentCart and toggled flag on each cart)
         */
        function loadCarts() {
            var toggledCartIds = [];
            data.loading = true;
            if (data.carts && data.carts.length) {
                angular.forEach(data.carts, function (cart) {
                    cart.loading = true;
                });
                toggledCartIds = data.carts.filter(function (cart) {
                    return cart.toggled;
                }).map(function (cart) {
                    return cart.id;
                });
            }

            return Cart.query(function (carts) {
                var recentCart = data.currentCart;
                data.currentCart = null;
                data.carts = carts || [];

                if (data.carts.length) {
                    angular.forEach(data.carts, function (cart) {
                        if (recentCart && recentCart.id === cart.id) {
                            data.currentCart = cart;
                        }
                        if (toggledCartIds.indexOf(cart.id) !== -1) {
                            cart.toggle();
                        }
                    });
                    if (!data.currentCart) {
                        data.currentCart = data.carts[0];
                    }
                }
                data.loading = false;
            }, function () {
                data.carts = [];
                data.currentCart = null;
                data.loading = false;
            }).$promise;
        }

        /**
         * Fetch cart data from API an update in cart collection (or add/remove).
         *
         * @param {number} cartId
         */
        function loadCart(cartId) {
            var cartIndex = _.findIndex(data.carts, {id: cartId}),
                cart = cartIndex !== -1 ? data.carts[cartIndex] : null,
                isCurrent = false,
                isToggled = false;

            if (cart) {
                cart.loading = true;
                isCurrent = data.currentCart === cart;
                isToggled = cart.toggled;
            }

            // Update current cart or add new to the collection
            Cart.get({cartId: cartId}, function (cartDto) {
                if (cart) {
                    data.carts[cartIndex] = cartDto;
                } else {
                    data.carts.push(cartDto);
                }
                if (isCurrent) {
                    data.currentCart = cartDto;
                }
                if (isToggled) {
                    cartDto.toggle();
                }
            });
        }

        /**
         * @param {Object} cart
         * @param {Object} product
         * @param {number} quantity
         * @param {string} [type]
         * @returns {Promise}
         */
        function addToCart(cart, product, quantity, type) {
            if (cart) {
                return API.post('carts/' + cart.id + '/items', {
                    productId: product.id,
                    quantity: quantity ? quantity : 1,
                    type: type ? type : 'product'
                }).then(function (responseCart) {
                    angular.extend(cart, responseCart);
                    NoticeService.success('Produkt ' + product.name + ' został dodany do "' + cart.name + '".');

                    $rootScope.$broadcast('cart.item.added', {
                        product: product,
                        quantity: quantity
                    });
                }, function (response) {
                    if (response.errors && response.errors.message) {
                        NoticeService.error(response.errors.message);
                    }
                    loadCarts();
                });
            }
        }

        /**
         * Clear carts data
         */
        function clearCarts() {
            data.carts = [];
            data.currentCart = null;
        }

        /**
         * @param {Cart} cart
         */
        function removeCart(cart) {
            var index = data.carts.indexOf(cart);
            if (index !== -1) {
                ConfirmDialogService.confirm('Czy na pewno chcesz usunąć "' + cart.name + '"?')
                    .then(function () {
                        cart.$delete(function () {
                            data.carts.splice(index, 1);
                            if (data.currentCart.id === cart.id) {
                                data.currentCart = data.carts[0] ? data.carts[0] : null;
                            }
                            NoticeService.success('"' + cart.name + '" został usunięty.');
                        });
                    });
            }
        }

        // Refresh cart list
        CustomerSocketService.on('cart:updated', function (event) {
            var currentUser = Auth.data.user;
            if (currentUser.hasAccess('cart_preview') && (currentUser.id !== event.user.id)) {
                NoticeService.success('Zaktualizowano ' + event.cart.name + ' (' + event.user.name + ')');
                loadCart(event.cart.id);
            }
        });
        // Remove cart from list when deleted
        CustomerSocketService.on('cart:deleted', function (event) {
            var currentUser = Auth.data.user,
                cartIndex = _.findIndex(data.carts, {id: event.cart.id});
            if (currentUser.hasAccess('cart_preview') && (currentUser.id !== event.user.id)) {
                NoticeService.warning('Usunięto ' + event.cart.name + ' (' + event.user.name + ')');
                if (cartIndex !== -1) {
                    data.carts.splice(cartIndex, 1);
                }
            }
        });
        CustomerSocketService.on('cart:warning:created', function (event) {
            if (Auth.access('cart_preview')) {
                NoticeService.warning('Zawartość "' + event.cart.name + '" uległa zmianie.');
                loadCart(event.cart.id);
            }
        });

        // Carts reloading after auth state changed
        $rootScope.$on('mm.auth.changed', function () {
            if (Auth.access('cart_preview')) {
                loadCarts();
            } else {
                clearCarts();
            }
        });

        // Carts reload after currency change
        $rootScope.$on('currency.changed', loadCarts);

        // Load cart on service init
        if (Auth.access('cart_preview')) {
            loadCarts();
        }

        $rootScope.$on('mm.auth.logout', function () {
            clearCarts();
        });

        return {
            data: data,
            loadCarts: loadCarts,
            getCreatingCartCommand: function () {
                return CommandFactory.create('carts', 'post').onSuccess(function (response) {
                    data.carts.push(new Cart(response));
                });
            },
            removeCurrentCart: function () {
                removeCart(data.currentCart);
            },
            removeCart: removeCart,
            /**
             * @param {Cart} cart
             */
            setCurrentCart: function (cart) {
                data.currentCart = cart;
            },
            /**
             * @param {Object} product
             * @param {number} quantity
             * @param {string} [type]
             * @returns {Promise}
             */
            addToCart: function (product, quantity, type) {
                if (data.currentCart) {
                    return addToCart(data.currentCart, product, quantity, type);
                } else {
                    return loadCarts().then(function () {
                        var defer;
                        if (data.currentCart) {
                            return addToCart(data.currentCart, product, quantity, type);
                        } else {
                            defer = $q.defer();
                            NoticeService.error('Nie wybrano ' + (Auth.access('export_customer') ? 'oferty.' : 'koszyka.'));
                            defer.reject();
                            return defer.promise;
                        }
                    });
                }
            },
            /**
             * @param {Cart} cart
             * @param {Object} item
             * @returns {Promise}
             */
            removeCartProduct: function (cart, item) {
                item.loading = true;
                return API.delete('carts/' + cart.id + '/items/' + item.id).then(function (cartDto) {
                    angular.extend(cart, cartDto);
                    NoticeService.success('Produkt ' + item.item.name + ' został usunięty z "' + cart.name + '".');
                    $rootScope.$broadcast('cart.item.removed', {
                        item: item
                    });
                }, function () {
                    item.loading = false;
                    NoticeService.error('Nie można usunąc produktu z "' + cart.name + '".');
                });
            },
            isPromotionActive: function (cart) {
                var isActive = false;

                _.forEach(cart.items, function (item) {
                    if (item.promotion !== null && item.promotion.autoConfirmed && item.promotion.active) {
                        isActive = true;
                    }
                });

                return isActive;
            },
            isAutoConfirmedPromotion: function (cart) {
                var isAutoConfirmed = false;

                _.forEach(cart.items, function (item) {
                    if (item.promotion !== null && item.promotion.autoConfirmed) {
                        isAutoConfirmed = true;
                    }
                });

                return isAutoConfirmed;
            },
            toggleProductPromotions: function (cart) {
                var items = [],
                    active = false;

                angular.forEach(cart.items, function (item) {
                    if (item.promotion !== null && item.promotion.autoConfirmed && item.promotion.active) {
                        active = true;
                    }
                });

                angular.forEach(cart.items, function (item) {
                    if (item.promotion !== null && item.promotion.autoConfirmed === true) {
                        angular.extend(item, {loading: true, promotion: {active: !active}});
                        items.push(item);
                    }
                });

                return API.put('carts/' + cart.id, {items: items}).then(loadCarts, loadCarts);
            },
            /**
             * @param {Cart} cart
             * @param {Object} item
             * @param {number} quantity
             * @returns {Promise}
             */
            updateCartProductQuantity: function (cart, item, quantity) {
                if (quantity === 0) {
                    return this.removeCartProduct(cart, item);
                }
                item.loading = true;
                return API.put('carts/' + cart.id + '/items/' + item.id, {quantity: quantity}).then(function (cartDto) {
                    $rootScope.$broadcast('cart.item.updated', {
                        item: item,
                        quantityDiff: quantity - item.quantity
                    });
                    angular.extend(cart, cartDto);
                    NoticeService.success('Zaktualizowano ilość produktu ' + item.item.name + '.');
                }, function (response) {
                    if (response.errors && response.errors.message) {
                        NoticeService.error(response.errors.message);
                    }
                    loadCarts();
                });
            },
            getCartNameSuggestion: function () {
                return API.get('carts/name-suggestion');
            },
            /**
             * Send cart confirmation request (confirm cart changes)
             *
             * @param {Cart} cart
             * @returns {Promise}
             */
            confirmCart: function (cart) {
                cart.loading = true;
                return API.post('carts/' + cart.id + '/confirmation').then(function (cartDto) {
                    cart.loading = false;
                    angular.extend(cart, cartDto);
                    return cart;
                });
            },
            /**
             * Refresh cart state and available items quantity
             *
             * @param {Cart} cart
             * @returns {Promise}
             */
            refreshCart: function (cart) {
                cart.loading = true;
                return API.post('carts/' + cart.id + '/refresh').then(function (cartDto) {
                    cart.loading = false;
                    angular.extend(cart, cartDto);
                    return cart;
                });
            }
        };

    }

])
;
