'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('CheckoutShippingStepController', [
    'CheckoutService',
    function (CheckoutService) {


    }
]);
