/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('mmCommand', function () {

    return {
        restrict: 'A',
        scope: {
            mmCommand : '='
        },
        controller: function ($scope, $element) {
            this.getCommand = function(){
                return $scope.mmCommand;
            };
            this.getId = function() {
                return 'mm' + $scope.$id;
            };
        }
    };

});