'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').directive('traderCustomersTab', [
    'TraderService',
    function (TraderService) {

        return {
            restrict: 'E',
            templateUrl: '/views/scaffolding/tabs/trader-customers-tab.html',
            replace: false,
            link: function (scope) {

                scope.data = TraderService.data;

                scope.signAsCustomer = function (customer) {
                    TraderService.signAsCustomer(customer);
                };
                scope.signOutCustomer = function () {
                    TraderService.signOutCustomer();
                };

                scope.getStatus = function (customer) {
                    var timeDiff,
                        days;

                    if (TraderService.data.onlineCustomers[customer.id]) {
                        return 'active';
                    }
                    if (!customer.loggedAt) {
                        return 'old';
                    }
                    timeDiff = new Date() - new Date(customer.loggedAt);
                    days = parseInt((timeDiff / (1000 * 60 * 60 * 24)) % 7);
                    if (days >= 14) {
                        return 'old';
                    }

                    return 'disabled';
                };

                scope.search = {
                    model: '',
                    clear: function () {
                        this.model = '';
                    },
                    filter: function (customer) {
                        if (scope.search.model) {
                            if (customer.user.name.toLowerCase().indexOf(scope.search.model.toLowerCase()) !== -1) {
                                return true;
                            }
                            if (customer.clientNumber.toLowerCase().indexOf(scope.search.model.toLowerCase()) !== -1) {
                                return true;
                            }
                            if (customer.company.toLowerCase().indexOf(scope.search.model.toLowerCase()) !== -1) {
                                return true;
                            }
                            if (customer.nip.toLowerCase().indexOf(scope.search.model.toLowerCase()) !== -1) {
                                return true;
                            }
                            return false;
                        }
                        return true;
                    }
                };

                scope.sorting = {
                    model: 'online',
                    order: function (customer) {
                        switch (scope.sorting.model) {
                            case 'online' :
                                return TraderService.data.onlineCustomers[customer.id] ? 1 : 0;
                            case 'carts' :
                                return customer.filledCarts;
                            case 'login' :
                                return new Date(customer.loggedAt);
                        }
                    }
                }


                TraderService.fetchCustomers();
            }
        };

    }
]);