'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('AccountController', [
    '$scope', '$state', '$location', 'mm.core.api.CommandFactory', 'mm.core.auth.AuthService', 'mm.core.api.API', 'mm.core.dom.NoticeService',
    function ($scope, $state, $location, CommandFactory, AuthService, API, NoticeService) {

        var user = AuthService.data.user,
            userable = AuthService.data.user.userable;

        if ($state.params.code) {
            API.put('user/activate/' + $state.params.code).then(() => {
                NoticeService.success('Aktywacja użytkownika przebiegła pomyślnie!');
            }, () => {
                NoticeService.error('Aktywacja użytkownika NIE powiodła się!');
            }).finally(() =>  {
                $location.search({});
            });
        }

        $scope.accountData = {
            dialogShown: false,
            openDialog: function () {
                this.dialogShown = true;
            }
        };

        $scope.passwordData = {
            dialogShown: false,
            openDialog: function () {
                this.dialogShown = true;
            }
        };

        // Bind newsletter command
        (function () {
            var fields = {
                newsletterAccepted: user.newsletter_accepted,
                privacyAccepted: user.privacy_accepted
            };
            if (userable.type === 'customer') {
                fields.invoiceNotify = userable.invoiceNotify;
                fields.orderNotify = userable.orderNotify;
            }
            $scope.newsletterCommand = CommandFactory.create('user', 'put', fields);
        })();

        // Bind change password command
        $scope.passwordData.command = CommandFactory.create('user', 'put');
        $scope.passwordData.command.onSuccess(function (user) {
            AuthService.bindUser(user);
            $scope.passwordData.dialogShown = false;
        });

        // Bind additional customer data command
        $scope.accountData.command = CommandFactory.create('user', 'put', {
            phone: userable.phone,
            ggNumber: userable.ggNumber,
            dateOfBirth: userable.dateOfBirth
        });
        $scope.accountData.command.onSuccess(function (user) {
            AuthService.bindUser(user);
            $scope.accountData.dialogShown = false;
        });

        $scope.expandedTerms = {'newsletter': false, 'privacy': false};
        $scope.expandTerms = function (termsName) {
            $scope.expandedTerms[termsName] = !$scope.expandedTerms[termsName];
        };
    }
]);
