'use strict';

angular.module('B2B').controller('NewPasswordController', [
    '$rootScope', '$scope', '$state', 'mm.core.api.API', 'mm.core.auth.AuthService', 'mm.core.api.CommandFactory',
    function ($rootScope, $scope, $state, API, AuthService, CommandFactory) {
        $scope.setNewPasswordCommand = CommandFactory.create('password/request', 'post', {}, {
            successMessage: 'Email z linkiem do ustawienia nowego hasła został wysłany.'
        }).onSuccess(function () {
            this.fields.email = null;
        });
    }
]);
