'use strict';
angular.module('B2B').directive('countDownClock', ['$timeout', function ($timeout) {

    var ClockDigit = function (value) {
        this.front = 0;
        this.back = value;
        this.animate = false;
    };

    ClockDigit.prototype.update = function (value) {
        if (this.back != value) {
            this.animate = false;
            $timeout(function () {
                this.front = this.back;
                this.back = value;
                this.animate = true;
            }.bind(this), 1);
        }
    };

    return {
        restrict: 'E',
        template: '<ul class="countdown-clock">' +
        '<li class="digit" ng-repeat="elem in digits" ng-class="{\'animate\' : elem.animate}">' +
        '<div class="line"></div>' +
        '<span class="front">{{ elem.front }}</span>' +
        '<span class="back">{{ elem.back }}</span>' +

        '<div class="hinge-wrap"><div class="hinge">' +
        '<span class="front">{{ elem.back }}</span>' +
        '<span class="back">{{ elem.front }}</span>' +
        '</div></div>' +
        '</li>' +
        '</ul>',
        scope: {
            endDate: "=",
            onFinish: '&',
            showDays: '='
        },
        link: function (scope) {

            var timer;

            scope.digits = [];

            scope.$watch('endDate', function () {
                scope.parsedDate = parseDate(scope.endDate);
                updateTime();
            });

            /**
             * @param {string} dateInput
             * @returns {Date}
             */
            function parseDate(dateInput) {
                if (dateInput instanceof Date) {
                    return dateInput;
                }
                if (!dateInput) {
                    return new Date();
                }
                return new Date(Date.parse(dateInput));
            }

            /**
             * Update countdown remaining time
             */
            function updateTime() {
                var newDigits;
                $timeout.cancel(timer);

                scope.remain = (scope.parsedDate - (new Date())) / 1000;
                if (scope.remain <= 0) {
                    if (scope.showDays === true) {
                        newDigits = [0, 0, 0, 0];
                    } else {
                        newDigits = [0, 0, 0, 0, 0, 0];
                    }
                    updateDigits(newDigits);
                    scope.onFinish();
                } else {
                    newDigits = [];
                    if (scope.showDays === true) {
                        makeDigits(newDigits, scope.remain % 60); // seconds
                        makeDigits(newDigits, scope.remain / 60 % 60); // minutes
                        makeDigits(newDigits, scope.remain / 3600 % 24); // hours
                        makeDigits(newDigits, scope.remain / (3600 * 24)); //days
                    } else {
                        makeDigits(newDigits, scope.remain % 60, true); // seconds
                        makeDigits(newDigits, scope.remain / 60 % 60, true); // minutes
                        makeDigits(newDigits, scope.remain / 3600, true); // hours
                    }
                    updateDigits(newDigits);
                }
                timer = $timeout(updateTime, 1000);
            }

            /**
             * Update scope digits.
             *
             * @param {Array} newDigits
             */
            function updateDigits(newDigits) {
                angular.forEach(newDigits, function (value, key) {
                    if (key in scope.digits) {
                        scope.digits[key].update(value);
                    } else {
                        scope.digits.push(new ClockDigit(value));
                    }
                });
            }

            /**
             * Generate digits basing on given number and append to digits array.
             *
             * @param {Array} digits
             * @param {boolean} [isDoubleDigit] If true, number will be displayed in two digit boxes
             * @param {number} value
             */
            function makeDigits(digits, value, isDoubleDigit) {
                value = Math.floor(value);
                if (value > 99) {  // Promotion duration > 99h will be displayed as 99
                    value = 99;
                }
                if (value <= 0) {
                    digits.unshift(0);
                    if (isDoubleDigit) {
                        digits.unshift(0);
                    }
                    return;
                }

                if (isDoubleDigit) {
                    digits.unshift(Math.floor(value % 10));
                    digits.unshift(Math.floor(value / 10));
                } else {
                    digits.unshift(Math.floor(value));
                }
            }
            updateTime();

            scope.$on('$destroy', function () {
                $timeout.cancel(timer);
            });
        }
    };
}]);
