'use strict';

angular.module('B2B').directive('categoryTree', ['mm.core.utils.RecursionHelper', function (RecursionHelper) {

    return {
        restrict: 'E',
        template: '<ul><category-tree-item ng-repeat="category in categories"></category-tree-item></ul>',
        replace: true,
        compile: function (element) {
            return RecursionHelper.compile(element);
        },
        scope: {
            categories: '='
        },
        link: function (scope) {

        }
    };
}]);

angular.module('B2B').directive('categoryTreeItem', ['$timeout', 'CategoryService', function ($timeout, CategoryService) {

    return {
        restrict: 'E',
        template: '<li ng-class="{ leaf : category.isLeaf(), toggled : category.toggled, highlighted : category.highlighted, current : category.current, hidden : !category.visible, featured: category.featured }">' +
        '<a href="{{:: getUrl(category) }}" ng-click="select(category, $event)">' +
        '{{:: category.name }} <span class="count" ng-if="category.productCount">({{ category.productCount }})</span>' +
        '</a>' +
        '<category-tree ng-if="category.toggled && category.hasChildren()" categories="category.children"></category-tree>' +
        '</li>',
        replace: true,
        link: function (scope) {

            scope.toggle = function (category) {
                // Collapse all siblings
                category.eachSibling(function (sibling) {
                    sibling.collapse();
                });

                category.toggle();
            };

            /**
             * @param {Category} category
             * @returns {string}
             */
            scope.getUrl = function (category) {

                if (typeof category === 'undefined' || category === null) {
                    return '';
                }

                if (category.url) {
                    return category.url;
                } else if(typeof category.isLeaf === 'function') {
                    if (category.isLeaf()) {
                        return '/category,' + category.id;
                    }
                } else if (typeof category.isLeaf !== 'function' || typeof category.isLeaf === 'undefined') {
                    if (category.children && category.children.length === 0) {
                        return '/category,' + category.id;
                    }
                }

                return '';
            };

            /**
             * Perform anchor request or call onCategoryFacetSelect callback
             *
             * @param {Category} category
             * @param {object} $event
             */
            scope.select = function (category, $event) {
                scope.toggle(category);
                if (category.isLeaf() && category.productCount && CategoryService.callbacks.onCategoryFacetSelect) {
                    $event.preventDefault();
                    CategoryService.callbacks.onCategoryFacetSelect(category);
                } else if (category.url) {
                    // Timeout - prevent reset category state after landing page load
                    $timeout(function () {
                        CategoryService.markAsCurrent(category);
                    });
                }
            };
        }
    };
}]);