'use strict';

angular.module('B2B', [
    'mm.core',
    'mm.socket',
    'ngAnimate',
    'ngSanitize',
    'ngTouch',
    'resource',
    'ui.router',
    'angular-md5',
    'ngTinyScrollbar',
    'datePicker',
    'ui.mask',
    'angular-google-analytics',
    'rzModule',
    'ngStorage'
]).config([
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    '$httpProvider',
    '$compileProvider',
    '$uiViewScrollProvider',
    'scrollbarProvider',
    '$qProvider',
    function ($stateProvider,
              $urlRouterProvider,
              $locationProvider,
              $httpProvider,
              $compileProvider,
              $uiViewScrollProvider,
              scrollbarProvider,
              $qProvider) {

        var productListUrlParams = '{page:[0-9]+}&sort&{onPage:[0-9]+}&producer&attrs&price&warehouse&promotional&availableOnly&innerQuery';


        // Get javascript file hash, set it to local storage.
        // We need to now if user uses actual version of app js file.
        var jsFileHash = window.performance.getEntries().filter(function (element) {
            return element.name.indexOf('app_') >= 0 && element.name.indexOf('.js') >= 0;
        }).map(function (element) {
            return element.name.match(/(?:app_)(\w+)(?=\.js)/g)[0].replace('app_', '');
        })[0];

        localStorage.setItem('jsHash', jsFileHash);


        $httpProvider.defaults.xsrfCookieName = 'b2b_xsrf';
        scrollbarProvider.defaults.autoUpdate = true;

        // Prevent URIError: malformed URI sequence
        try {
            decodeURIComponent(window.location.hash);
        } catch (e) {
            window.location.hash = '';
        }

        $urlRouterProvider.otherwise('/');

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        $uiViewScrollProvider.useAnchorScroll();

        $compileProvider.debugInfoEnabled(false);

        $qProvider.errorOnUnhandledRejections(false);

        /**
         * Allow to run controller only if user is logged and has right permissions
         * TODO move to mm.core.auth (as a constant ?)
         */
        var accessResolver = function (opts) {

            var permissionId = opts.permission;

            return ['$q', '$rootScope', '$state', '$location', 'mm.core.auth.AuthService',
                function ($q, $rootScope, $state, $location, AuthService) {
                    var deferred = $q.defer(),
                        url = $location.url();

                    if (permissionId) {
                        AuthService.getUser(['customer', 'trader']).then(function (user) {
                            if (user.hasAccess(permissionId)) {
                                deferred.resolve();
                            } else {
                                $state.go('auth', {redirect: url});
                            }
                        }, function () {
                            $state.go('auth', {redirect: url});
                        });
                    } else {
                        deferred.resolve();
                    }

                    return deferred.promise;
                }
            ];
        };

        $stateProvider
            .state('home', {
                url: '/',
                controller: 'HomeController as vm',
                templateUrl: '/views/home/page.html',
                catalogMode: true
            })
            .state('home-export', {
                url: '/home-export',
                controller: 'HomeExportController as vm',
                templateUrl: '/views/cms/pages/page.html',
                catalogMode: true
            })
            .state('maintenance', {
                url: '/503',
                controller: 'MaintenanceController',
                templateUrl: '/views/core/maintenance.html',
                catalogMode: false
            })
            .state('auth', {
                url: '/auth?passwordReset&redirect&redirectAbs',
                templateUrl: '/views/auth/page.html',
                controller: 'AuthPageController',
                catalogMode: false,
                mainTabs: false
            })
            .state('new-password', {
                url: '/auth/new-password',
                templateUrl: '/views/auth/new-password.html',
                controller: 'NewPasswordController',
                catalogMode: false,
                mainTabs: false
            })
            .state('rodo', {
                url: '/rodo',
                templateUrl: '/views/auth/rodo.html',
                controller: 'RodoController',
                catalogMode: false,
                mainTabs: false,
                resolve: {
                    user: ['$state', '$location', 'mm.core.auth.AuthService', function ($state, $location, AuthService) {
                        return AuthService.getUser().then(function (response) {
                            if (response.terms_accepted === true) {
                                $state.go('home');
                            }

                            return response;
                        }, function () {
                            $state.go('auth', {redirect: $location.url()});
                        });
                    }]
                }
            })
            .state('category', {
                url: '/category,{category:[0-9]+}?' + productListUrlParams,
                templateUrl: '/views/catalog/category/page.html',
                controller: 'CategoryController',
                catalogMode: true,
                keepCategoryTreeState: true,
                filtersEnabled: true
            })
            .state('search', {
                url: '/search?query&category&' + productListUrlParams,
                templateUrl: '/views/catalog/search/page.html',
                controller: 'SearchController as search',
                catalogMode: true,
                filtersEnabled: true,
                keepCategoryTreeState: true
            })
            .state('product', {
                url: '/product,{productId:[0-9]+}',
                templateUrl: '/views/catalog/product/page.html',
                controller: 'ProductController',
                catalogMode: true,
                keepCategoryTreeState: true
            })
            .state('password-reset', {
                url: '/password/reset/:code',
                templateUrl: '/views/auth/reset-password.html',
                controller: 'ResetPasswordController',
                mainTabs: false
            })
            .state('panel', {
                url: '/panel',
                templateUrl: '/views/panel/page.html',
                resolve: {
                    access: accessResolver({permission: 'is_customer'})
                }
            })
            .state('panel.account', {
                url: '/account?code',
                views: {
                    '@': {
                        templateUrl: '/views/panel/account/page.html',
                        controller: 'AccountController'
                    }
                }
            })
            .state('panel.invoices', {
                url: '/invoices?download',
                views: {
                    '@': {
                        templateUrl: '/views/panel/invoices/page.html',
                        controller: 'InvoicesController'
                    }
                },
                resolve: {
                    access: accessResolver({permission: 'billing'})
                }
            })
            .state('panel.orders', {
                url: '/orders?preview',
                views: {
                    '@': {
                        templateUrl: '/views/panel/orders/page.html',
                        controller: 'OrdersController as orders'
                    }
                },
                resolve: {
                    access: accessResolver({permission: 'order_preview'})
                }
            })
            .state('panel.complaint', {
                url: '/complaint',
                //views: {
                //    '@': {
                //        templateUrl: '/views/panel/complaint/view.html',
                //        controller: 'ComplaintController'
                //    }
                //},
                resolve: {
                    access: accessResolver({permission: 'complaint'})
                }
            })
            .state('panel.complaint.rma', {
                url: '/rma',
                views: {
                    '@': {
                        templateUrl: '/views/panel/complaint/rma.html',
                        controller: 'ComplaintRMAFormController as form'
                    }
                }
            })
            .state('panel.complaint.sales-return', {
                url: '/sales-return',
                views: {
                    '@': {
                        templateUrl: '/views/panel/complaint/sales-return.html',
                        controller: 'ComplaintSalesReturnFormController as form'
                    }
                }
            })
            .state('panel.complaint.shipping', {
                url: '/shipping',
                views: {
                    '@': {
                        templateUrl: '/views/panel/complaint/shipping.html',
                        controller: 'ComplaintShippingFormController as form'
                    }
                }
            })
            .state('panel.export', {
                url: '/export',
                views: {
                    '@': {
                        templateUrl: '/views/panel/export/page.html'
                    }
                }
            })
            .state('trader', {
                url: '/trader',
                abstract: true,
                resolve: {
                    access: accessResolver({permission: 'is_trader'})
                }
            })
            .state('trader.account', {
                url: '/account',
                views: {
                    '@': {
                        templateUrl: '/views/trader/account/page.html',
                        controller: 'TraderAccountController'
                    }
                }
            })
            .state('trader.customers', {
                url: '/customers',
                views: {
                    '@': {
                        templateUrl: '/views/trader/customers/page.html',
                        controller: 'TraderCustomersController as page'
                    }
                }
            })
            .state('trader.products', {
                url: '/products?query&' + productListUrlParams,
                views: {
                    '@': {
                        templateUrl: '/views/trader/products/page.html',
                        controller: 'TraderProductsController as page'
                    }
                }
            })
            .state('messages', {
                url: '/messages',
                templateUrl: '/views/messages/page.html',
                controller: 'MessagesController as page'
            })
            .state('message', {
                url: '/messages/{id}',
                templateUrl: '/views/messages/message.html',
                controller: 'MessageController as page'
            })
            .state('checkout', {
                url: '/checkout',
                abstract: true,
                templateUrl: '/views/checkout/page.html',
                controller: 'CheckoutController as checkout'
            })
            .state('checkout.cart', {
                url: '/cart',
                templateUrl: '/views/checkout/steps/cart.html',
                controller: 'CheckoutCartStepController as step',
                resolve: {
                    access: accessResolver({permission: 'cart_preview'})
                }
            })
            .state('checkout.shipping', {
                url: '/shipping',
                templateUrl: '/views/checkout/steps/shipping.html',
                controller: 'CheckoutShippingStepController as step',
                resolve: {
                    access: accessResolver({permission: 'cart_preview'}),
                    cart: ['CheckoutService', function (CheckoutService) {
                        return CheckoutService.resolveCheckoutCart(false);
                    }]
                }
            })
            .state('checkout.summary', {
                url: '/summary',
                templateUrl: '/views/checkout/steps/summary.html',
                controller: 'CheckoutSummaryStepController as step',
                resolve: {
                    access: accessResolver({permission: 'cart_preview'}),
                    cart: ['CheckoutService', function (CheckoutService) {
                        return CheckoutService.resolveCheckoutCart(true);
                    }]
                }
            })
            .state('checkout.verification', {
                url: '/verification?o&t',
                templateUrl: '/views/checkout/steps/verification.html',
                controller: 'CheckoutVerificationStepController as step'
            })
            .state('checkout.payment', {
                url: '/payment?orderId',
                templateUrl: '/views/checkout/steps/payment.html',
                controller: 'CheckoutPaymentStepController as step'
            })
            .state('checkout.confirmation', {
                url: '/confirmation',
                templateUrl: '/views/checkout/steps/confirmation.html',
                resolve: {
                    order: ['CheckoutService', function (CheckoutService) {
                        return CheckoutService.resolveCheckoutOrder();
                    }]
                }
            })
            .state('maxoff', {
                url: '/maxoff?{page:[0-9]+}',
                templateUrl: '/views/catalog/discount/page.html',
                controller: 'DiscountController',
                discountType: 'max_off',
                catalogMode: true
            })
            .state('rabatynamaxa', {
                url: '/rabatynamaxa?{page:[0-9]+}',
                templateUrl: '/views/catalog/discount/page.html',
                controller: 'DiscountController',
                discountType: 'max_discount',
                header: 'Tylko teraz wybrane produkty w obniżonych cenach, nie zwlekaj!',
                catalogMode: true
            })
            .state('promotions', {
                url: '/promotions',
                templateUrl: '/views/cms/pages/promotions.html',
                controller: 'PromotionsPageController',
                catalogMode: true
            })
            .state('address-confirmation', {
                url: '/confirm-address/{addressId}:{token}',
                templateUrl: '/views/address/confirmation.html',
                controller: 'AddressConfirmationController as confirmation'
            })
            .state('configurator', {
                url: '/configurator',
                templateUrl: '/views/configurator/page.html',
                controller: 'ConfiguratorController as configurator',
                resolve: {
                    access: accessResolver({permission: 'configurator_management'})
                }
            })
            .state('configurator.item', {
                url: '/{id}'
            })
            .state('contact', {
                url: '/contact',
                templateUrl: '/views/contact/page.html',
                controller: 'ContactController as contact'
            })
            .state('cms-page', {
                url: '/:slug',
                controller: 'CmsPageController as vm',
                templateUrl: '/views/cms/pages/page.html'
            });

    }
]).run([
    '$rootScope',
    '$state',
    '$window',
    'mm.core.auth.SessionService',
    'mm.core.auth.AuthService',
    'mm.core.dom.DialogService',
    'CustomerService',
    '$anchorScroll',
    function ($rootScope,
              $state,
              $window,
              SessionService,
              AuthService,
              DialogService,
              CustomerService,
              $anchorScroll) {

        SessionService.init({
            userable: ['customer', 'trader'], onExpire: function () {
                $state.go('home');
                DialogService.addDialog({
                    header: 'Zaloguj się',
                    content: 'Twoja sesja wygasła, zaloguj się ponownie.'
                }, true).show();
            }
        });

        $rootScope.$on('$stateChangeStart', function (event, toState) {
            if (toState.name !== 'rodo') {
                AuthService.getUser().then(function (user) {
                    if (user && user.terms_accepted !== true) {
                        $state.go('rodo');
                    }
                });
            }
        });

        $rootScope.$on('$stateChangeSuccess', function () {
            $anchorScroll();
        });

        CustomerService.init();
    }
]);
