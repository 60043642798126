'use strict';

angular.module('B2B').controller('MessagesController', [
    '$scope',
    'mm.core.api.API',
    'mm.core.dom.NoticeService',
    'mm.core.auth.AuthService',
    'mm.core.dom.ConfirmDialogService',
    'MessageService',
    function ($scope,
              API,
              NoticeService,
              Auth,
              ConfirmDialogService,
              MessageService) {

        var vm = this;

        vm.message = MessageService.data;

        /**
         * @param {string} messageId
         */
        vm.removeMessage = function (message) {
            if (message.read) {
                MessageService.removeMessage(message.id);
                return;
            }
            ConfirmDialogService.confirm('Czy na pewno chcesz usunąć nieprzeczytaną wiadomość?').then(function () {
                MessageService.removeMessage(message.id);
            });
        };

        /**
         * Load product notification section
         */
        function initProductNotifications() {
            vm.productNotifications = {
                items: null,
                remove: function (item) {
                    item.loading = true;
                    API.delete('product-notifications/' + item.id).then(function () {
                        this.items.splice(this.items.indexOf(item), 1);
                        NoticeService.success('Powiadomienie zostało usunięte.');
                    }.bind(this));
                }
            };

            API.get('product-notifications').then(function (items) {
                vm.productNotifications.items = items;
            });
        }

        MessageService.run();

        Auth.getUser().then(function (user) {
            if (user.hasAccess('is_customer')) {
                initProductNotifications();
            }
        });
        $scope.$on('mm.auth.login', function (event, user) {
            if (user.hasAccess('is_customer')) {
                initProductNotifications();
            }
        });

    }
]);
