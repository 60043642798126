/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('CheckoutService', [
    '$q',
    '$timeout',
    '$rootScope',
    '$state',
    'mm.core.api.API',
    'mm.core.auth.AuthService',
    'mm.core.dom.NoticeService',
    'CustomerService',
    'CartService',
    'CustomerSocketService',
    function ($q,
              $timeout,
              $rootScope,
              $state,
              API,
              AuthService,
              NoticeService,
              CustomerService,
              CartService,
              CustomerSocketService) {

        var data = {
                currentStep: null,
                cart: null,
                deliveryAddress: null,
                order: null,
                payment: 'transfer'
            },
            clearData = function (cart) {
                // Reset selected delivery address and payment if cart has been changed
                if (!cart || !data.cart || data.cart.id !== cart.id) {
                    data.deliveryAddress = null;
                    data.payment = 'transfer';
                }

                data.cart = null;
                data.order = null;
            },
            order = {
                loading: false,
                afterCartAction: 'clear_items',
                rememberDeliveryAddress: false,
                /**
                 * Try to create order from current cart.
                 * If it fails, redirect to cart step and show cart warnings.
                 *
                 * @param {bool} predictedGrossValue
                 * @returns {Promise}
                 */
                make: function (predictedGrossValue) {
                    if (!this.loading) {
                        this.loading = true;

                        var confirmAuto = false;

                        angular.forEach(data.cart.items, function (item) {
                            if (item.promotion !== null && item.promotion.autoConfirmed && item.promotion.active) {
                                confirmAuto = true;
                            }
                        });

                        return API.post('orders', {
                            cartId: data.cart.id,
                            deliveryAddressId: data.deliveryAddress.id,
                            afterCartAction: order.afterCartAction,
                            rememberCartDeliveryAddress: order.rememberDeliveryAddress,
                            traderNote: data.cart.traderNote,
                            payment: data.payment,
                            predictedGrossValue: predictedGrossValue,
                            confirmAuto: confirmAuto
                        }).then(function (orderDto) {
                            data.order = orderDto;
                            data.cart = null;
                            data.deliveryAddress = null;
                            CartService.loadCarts();
                            $rootScope.$broadcast('order.created', orderDto);
                            loadNextStep();
                            $timeout(function () {
                                order.loading = false;
                            }, 200);
                        }, function (response) {
                            NoticeService.error(response.errors.message);
                            CartService.loadCarts();
                            order.loading = false;
                            $state.go('checkout.cart');
                        });
                    }
                }
            },

            /**
             * Determine if current checkout state allow customer to enter into given step
             *
             * @param {string} step
             * @returns {boolean}
             */
            isStepEnabled = function (step) {
                if (order.loading && step !== 'summary') {
                    return false;
                }
                if (step === 'cart' && AuthService.data.user) {
                    return true;
                }
                if (step === 'shipping') {
                    return data.cart && data.cart.valid;
                }
                if (step === 'summary') {
                    return data.cart && data.cart.valid && data.deliveryAddress;
                }
                return false;
            },
            /**
             * Determine if given checkout step is visible for current cart/order.
             *
             * @param {string} step
             * @returns {boolean}
             */
            isStepAvailable = function (step) {
                if (step === data.currentStep) {
                    return true;
                }
                if (step === 'payment' && data.payment.indexOf('payu') !== 0) {
                    return false;
                }
                if (step === 'verification') {
                    if (data.order && data.order.status.key === 'unconfirmed') {
                        return true;
                    }
                    if (!data.deliveryAddress || ['single', 'created'].indexOf(data.deliveryAddress.status.key) === -1) {
                        return false;
                    }
                }
                return true;
            },
            /**
             * Load right checkout step due to cart/order conditions.
             */
            loadNextStep = function () {
                if (data.order) {
                    switch (data.order.status.key) {
                        case 'unconfirmed' :
                            $state.go('checkout.verification');
                            break;
                        case 'unpaid' :
                            $state.go('checkout.payment');
                            break;
                        case 'processed' :
                        case 'created' :
                            $state.go('checkout.confirmation');
                            break;
                    }
                }
            },
            /**
             * Use given cart in checkout process, try to load shipping step.
             *
             * @param {Cart} cart
             * @param {boolean} [skipReloading]
             * @param {string} [errorMsg]
             */
            proceedCart = function (cart, skipReloading, errorMsg) {

                if (!skipReloading) {
                    clearData(cart);
                    order.loading = false;
                }

                if (cart.valid && !skipReloading) {
                    CartService.refreshCart(cart).then(function (cart) {
                        proceedCart(cart, true, 'Zawartość "' + cart.name + '" uległa zmianie. Zatwierdź, aby kontynuować zakupy.');
                    });
                } else if (!cart.valid && !skipReloading) {
                    CartService.confirmCart(cart).then(function (cart) {
                        proceedCart(cart, true, 'Aby kontynuować zakupy, popraw błędną zawartość "' + cart.name + '".');
                    });
                } else if (cart.valid) {
                    data.cart = cart;
                    order.rememberDeliveryAddress = !!cart.addressId;
                    if (isStepEnabled('shipping')) {
                        $state.go('checkout.shipping');
                    }
                } else if (errorMsg) {
                    NoticeService.error(errorMsg);
                }
            },
            /**
             * Reset checkout process - redirect to cart step if user is on the path.
             */
            resetCheckout = function () {
                if ($state.current.name.indexOf('checkout') === 0) {
                    $state.go('checkout.cart');
                }
            };

        // Redirect to carts view after cart changes
        CustomerSocketService.on('cart:deleted', function (event) {
            if (data.cart && data.cart.id === event.cart.id) {
                data.cart = null;
                resetCheckout();
            }
        });
        CustomerSocketService.on('cart:updated', function (event) {
            if (
                data.cart && data.cart.id === event.cart.id &&
                AuthService.data.user.id !== event.user.id ||
                $state.current.name === 'checkout.summary'
            ) {
                data.cart = null;
                CartService.loadCarts();
                resetCheckout();
            }
        });
        CustomerSocketService.on('cart:warning:created', function (event) {
            if (data.cart && data.cart.id === event.cart.id) {
                data.cart = null;
                resetCheckout();
            }
        });

        // Reset checkout step after auth or currency change
        $rootScope.$on('mm.auth.changed', function () {
            resetCheckout();
        });
        // Carts reload after currency change
        $rootScope.$on('currency.changed', function () {
            resetCheckout();
        });

        return {
            data: data,
            order: order,
            clearData: clearData,
            setOrder: function (order) {
                data.order = order;
            },
            setStep: function (step) {
                data.currentStep = step;
            },
            isStepEnabled: isStepEnabled,
            isStepAvailable: isStepAvailable,
            loadNextStep: loadNextStep,
            proceedCart: proceedCart,
            /**
             * @param {Object} address
             */
            selectDeliveryAddress: function (address) {
                data.deliveryAddress = address;
            },
            /**
             * State resolve - redirect to carts view if no checkout cart defined
             *
             * @param {boolean} [requireAddress]
             * @returns {Promise}
             */
            resolveCheckoutCart: function (requireAddress) {
                var deferred = $q.defer();
                $timeout(function () {
                    if (data.cart && (!requireAddress || (requireAddress && data.deliveryAddress))) {
                        deferred.resolve(data.cart);
                    } else {
                        $state.go('checkout.cart');
                    }
                });
                return deferred.promise;
            },
            /**
             * State resolve - redirect to carts view if no checkout order defined
             *
             * @returns {Promise}
             */
            resolveCheckoutOrder: function () {
                var deferred = $q.defer();
                $timeout(function () {
                    if (data.order) {
                        deferred.resolve(data.order);
                    } else {
                        $state.go('checkout.cart');
                    }
                });
                return deferred.promise;
            }
        };

    }

])
;
