angular.module('B2B').directive('gridRangeFilter', ['$document', '$timeout', function ($document, $timeout) {
    return {
        restrict: 'E',
        templateUrl: '/views/scaffolding/grid/range-filter.html',
        replace: true,
        scope: {},
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            scope.min = null;
            scope.max = null;

            scope.onValueChange = function () {
                scope.min = isNaN(parseFloat(scope.min)) ? null : parseFloat(scope.min);
                scope.max = isNaN(parseFloat(scope.max)) ? null : parseFloat(scope.max);
                if (scope.min !== null || scope.max !== null) {
                    ngModel.$setViewValue([scope.min, scope.max]);
                } else {
                    ngModel.$setViewValue(null);
                }
            };
        }
    };
}]);