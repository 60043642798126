'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('CheckoutVerificationStepController', [
    '$state', 'mm.core.api.API', 'CheckoutService',
    function ($state, API, CheckoutService) {

        var vm = this,
            orderId = $state.params.o,
            token = $state.params.t;

        if (!CheckoutService.data.order && (!orderId || !token)) {
            vm.error = 'Podano błędny adres URL.'
        }

        if (orderId && token) {
            vm.loading = true;
            CheckoutService.clearData();
            API.post('orders/confirmation', {
                orderId: orderId,
                token: token
            }).then(function (order) {
                CheckoutService.setOrder(order);
                CheckoutService.loadNextStep();
            }, function (data) {
                vm.error = data.errors.message;
                vm.loading = false;
            });
        }

    }
]);
