'use strict';
angular.module('B2B').factory('ConfiguratorProductPicker', [
    'mm.core.auth.AuthService', 'mm.core.api.API', 'ProductGrid', 'AttributeFilterService', 'ProducerFilterService',
    function (AuthService, API, ProductGrid, AttributeFilterService, ProducerFilterService) {

        /**
         * @param {Object} options
         * @constructor
         */
        var ConfiguratorProductPicker = function (opts) {

            this.grid = new ProductGrid({
                stateParamsEnabled: false,
                stickyHeaderEnabled: false,
                sessionDataEnabled: false
            });
            this.grid.setResource('configurator/products');

            this.requestedComponent = null;
            this.filterLoading = false;
            this.attributeFilters = [];
            this.producerFilterData = ProducerFilterService.data;

            this.onSelect = opts.onSelect;
            this.onPreview = opts.onPreview;
            this.initColumns();
        };

        /**
         * Set product grid columns.
         */
        ConfiguratorProductPicker.prototype.initColumns = function () {
            var picker = this;
            this.grid.setColumns([
                {
                    name: 'PN',
                    key: 'partNumber',
                    sortable: true
                },
                {
                    name: 'Prod.',
                    key: 'producer.name',
                    sortable: true
                },
                {
                    name: 'Nazwa',
                    key: 'name',
                    searchKey: 'name.polish',
                    sortable: true
                },
                {
                    name: 'Opis',
                    class: 'preview',
                    value: function (item) {
                        return '<a ng-click="column.onClick(item)">&nbsp;</a>';
                    },
                    onClick: function (item) {
                        if (picker.onPreview) {
                            picker.onPreview(item);
                        }
                    }
                },
                {
                    name: 'Cena',
                    key: 'price',
                    valueKey: 'price.net',
                    class: 'price',
                    type: 'price',
                    sortable: true,
                    options: {
                        currencyKey: 'price.currency'
                    },
                    isVisible: function () {
                        return AuthService.data.isLogged && AuthService.data.user.hasAccess('offer_preview');
                    }
                },
                {
                    name: '',
                    value: function (item) {
                        return '<button ng-click="column.onClick(item)">wybierz</button>';
                    },
                    onClick: function (item) {
                        if (picker.onSelect) {
                            picker.onSelect(item);
                        }
                    }
                }
            ]);
        };

        /**
         * @param {Object} component
         * @return {ConfiguratorProductPicker}
         */
        ConfiguratorProductPicker.prototype.requestComponent = function (component) {
            this.requestedComponent = component;
            this.grid.setCategoryIds([this.requestedComponent.categoryId]);

            return this;
        };

        /**
         * @param {Array} components
         * @return {ConfiguratorProductPicker}
         */
        ConfiguratorProductPicker.prototype.setComponents = function (components) {
            this.grid.setParam('products', components.filter(function (component) {
                return !component.system && component.product
            }).map(function (component) {
                return component.product.id + ':' + component.quantity;
            }).join(';'));

            return this;
        };

        /**
         * @param {Object} caseModel
         * @return {ConfiguratorProductPicker}
         */
        ConfiguratorProductPicker.prototype.setCase = function (caseModel) {
            if (caseModel) {
                this.grid.setParam('caseId', caseModel.id);
            } else {
                this.grid.clearParam('caseId');
            }

            return this;
        };

        /**
         * Select product attribute filter.
         *
         * @param {number} attributeId
         * @param {array} values
         */
        ConfiguratorProductPicker.prototype.selectAttribute = function (attributeId, values) {
            this.grid.setAttribute(attributeId, values);
            this.search();
        };

        /**
         * Load product attribute filters.
         */
        ConfiguratorProductPicker.prototype.loadAttributeFilters = function () {
            this.grid.attributes = [];
            this.attributeFilters = null;
            if (this.requestedComponent && this.requestedComponent.filtersEnabled) {
                API.get('configurator/productFilters', {
                    categoryId: this.requestedComponent.categoryId
                }).then(function (filters) {
                    this.attributeFilters = [];
                    angular.forEach(filters, function (filterItem) {
                        var filter = AttributeFilterService.buildFilter(filterItem, function (attributeId, values) {
                            this.selectAttribute(attributeId, values);
                        }.bind(this));
                        AttributeFilterService.bindFacetData(filter, this.grid.facet.attributes);
                        this.attributeFilters.push(filter);
                    }.bind(this));
                    this.filterLoading = false;
                }.bind(this));
            }
        };

        /**
         * Load attribute filters and grid first page.
         */
        ConfiguratorProductPicker.prototype.load = function () {
            this.grid.query = null;
            this.filterLoading = true;
            ProducerFilterService.removeFilter();
            this.grid.setProducers([]);
            this.loadAttributeFilters();
            this.search(true);
        };

        /**
         * Perform search and load filter facets
         */
        ConfiguratorProductPicker.prototype.search = function (initialSearch) {
            this.grid.loadPage(1);
            this.grid.loadFacets(true, false).then(function () {
                ProducerFilterService.loadFilter(
                    this.grid.getProducers(),
                    this.grid.facet.producers,
                    function (values) {
                        this.grid.setProducers(values);
                        this.search();
                    }.bind(this)
                );
                if (this.attributeFilters && this.attributeFilters.length) {
                    this.attributeFilters.forEach(function (filter) {
                        AttributeFilterService.bindFacetData(filter, this.grid.facet.attributes);
                    }.bind(this));


                    if (initialSearch) {
                        this.attributeFilters = AttributeFilterService.spliceEmptyFilters(this.attributeFilters);
                        this.filterLoading = false;
                    }
                }
            }.bind(this));
        };

        return ConfiguratorProductPicker;
    }
]);