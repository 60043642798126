'use strict';

angular.module('B2B').controller('HomeController', [
    '$scope',
    '$state',
    'ProducerFilterService',
    'AttributeFilterService',
    'PriceFilterService',
    'SearchService',
    'BasicFilterService',
    'CustomerService',
    function ($scope,
              $state,
              ProducerFilterService,
              AttributeFilterService,
              PriceFilterService,
              SearchService,
              BasicFilterService,
              CustomerService) {

        var vm = this;

        ProducerFilterService.loadFilter();
        AttributeFilterService.removeFilters();
        PriceFilterService.removeFilter();
        SearchService.clearQuery();
        BasicFilterService.clearSelection();

        // Redirect to export home page for export customers
        CustomerService.getCustomer().then(function (customer) {
            if (customer && customer.export) {
                $state.go('home-export');
            } else {
                vm.showBanners = true;
            }
        });
        $scope.$on('customer.changed', function (event, customer) {
            if (customer && customer.export) {
                $state.go('home-export');
            }
        });

    }
]);
