'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('TraderCustomersController', [
    'Grid', 'mm.core.api.CommandFactory', 'CategoryService',
    function (Grid, CommandFactory, CategoryService) {

        var vm = this;

        vm.advancedSearch = {
            enabled: false,
            toggle: function () {
                this.enabled = !this.enabled;
            },
            columns: [
                {
                    name: 'Nazwa produktu',
                    key: 'orderProductName',
                    isVisible: false,
                    searchable: true,
                    queryMatchType: 'like'
                },
                {
                    name: 'PN',
                    key: 'orderPartNumber',
                    isVisible: false,
                    searchable: true
                }
            ]
        };

        vm.grid = (new Grid()).setResource('customers')
            .setItemsOnPage(24).setSort('createdAt', 'desc').setColumns([
                {
                    name: 'id',
                    key: 'id'
                },
                {
                    name: 'Nazwisko',
                    key: 'lastName',
                    sortable: true,
                    searchable: true
                },
                {
                    name: 'Imię',
                    key: 'firstName',
                    sortable: true,
                    searchable: true
                },
                {
                    name: 'E-mail',
                    key: 'email',
                    searchable: true
                },
                {
                    name: 'Numer klienta',
                    key: 'clientNumber',
                    searchable: true
                },
                {
                    name: 'Firma',
                    key: 'company',
                    searchable: true
                },
                {
                    name: 'Aktywność',
                    key: 'lastActiveAt'
                },
                {
                    name: 'Data rej.',
                    key: 'createdAt',
                    sortable: true
                },
                {
                    name: 'Online',
                    key: 'status'
                },
                {
                    name: 'Status'
                },
                {
                    name: 'Grupa',
                    key: 'group.name'
                },
                {
                    name: 'Opiekun DRK',
                },
                {}
            ].concat(vm.advancedSearch.columns));

        (function (vm) {

            var categoryIdsOptions = [];

            CategoryService.getCategoryTree().then(function (categories) {
                angular.forEach(categories, function (category) {
                    categoryIdsOptions.push({
                        key: category.id,
                        label: category.name
                    });
                });
            });

            var fields = [
                {
                    type: 'check',
                    name: 'activityForm',
                    label: 'Klient posiada',
                    options: [
                        {
                            key: 'internet',
                            label: 'sklep internetowy'
                        },
                        {
                            key: 'stationary',
                            label: 'sklep stacjonarny'
                        },
                        {
                            key: 'office',
                            label: 'biuro/usługi'
                        },
                        {
                            key: 'service',
                            label: 'serwis'
                        },
                        {
                            key: 'allegro',
                            label: 'allegro'
                        },
                        {
                            key: 'tenders',
                            label: 'przetargi'
                        }
                    ]
                },
                {
                    type: 'check',
                    name: 'orderTarget',
                    label: 'Klient zamawia',
                    options: [
                        {
                            key: 'shelf',
                            label: 'na półkę'
                        },
                        {
                            key: 'order',
                            label: 'pod zamówienie'
                        },
                        {
                            key: 'tenders',
                            label: 'przetargi'
                        },
                        {
                            key: 'own-needs',
                            label: 'na własne potrzeby'
                        }
                    ]
                },
                {
                    type: 'contact',
                    name: 'contact',
                    label: 'Jaki model współpracy preferują',
                    options: [
                        {
                            key: 'phone',
                            label: 'tel'
                        },
                        {
                            key: 'gg',
                            label: 'GG'
                        },
                        {
                            key: 'email',
                            label: 'mail'
                        },
                        {
                            key: 'skype',
                            label: 'skype'
                        }
                    ]
                },
                {
                    type: 'limits',
                    name: 'suppliers',
                    label: 'Z kim współpracują (limity)',
                    options: [
                        {
                            key: 'ab',
                            label: 'AB'
                        },
                        {
                            key: 'abcData',
                            label: 'ABC Data'
                        },
                        {
                            key: 'action',
                            label: 'Action'
                        },
                        {
                            key: 'incom',
                            label: 'Incom'
                        },
                        {
                            key: 'komputronik',
                            label: 'Komputronik'
                        }
                    ],
                    customOptionChanged: function (option) {
                        if (!option.label) {
                            this.options.splice(this.options.indexOf(option), 1);
                        }
                        this.addNewCustomField();
                    },
                    addNewCustomField: function () {
                        var option = this.options[this.options.length - 1];
                        if (option.label) {
                            this.options.push({key: 'custom', noAmount: false});
                        }
                    }
                },
                {
                    type: 'check',
                    name: 'categoryIds',
                    label: 'Co najczęsciej zamawia',
                    options: categoryIdsOptions
                },
                {
                    type: 'check',
                    name: 'brandInfo',
                    label: 'B-brandy',
                    options: [
                        {
                            key: 'assembling',
                            label: 'składają komputery'
                        }
                    ]
                },
                {
                    type: 'brands',
                    name: 'brands',
                    label: 'Jakie brandy sprzedają',
                    options: [
                        {
                            key: 'adax',
                            label: 'Adax'
                        },
                        {
                            key: 'optimus',
                            label: 'Optimus'
                        },
                        {
                            key: 'ntt',
                            label: 'NTT'
                        }
                    ],
                    customOptionChanged: function (option) {
                        if (!option.label) {
                            this.options.splice(this.options.indexOf(option), 1);
                        }
                        this.addNewCustomField();
                    },
                    addNewCustomField: function () {
                        var option = this.options[this.options.length - 1];
                        if (option.label) {
                            this.options.push({key: 'custom'});
                        }
                    }
                },
                {
                    type: 'check',
                    name: 'brandInfo',
                    options: [
                        {
                            key: 'uninterested',
                            label: 'nie są zainteresowani brandami'
                        }
                    ]
                }
            ];

            function buildCommand(customer) {
                var data = customer.additionalData ? customer.additionalData : {};

                vm.customerDialog.command = CommandFactory.create('customers/' + customer.id + '/additional-data', 'post');

                if (customer.additionalData) {
                    vm.customerDialog.command.fields.drkGuardian = customer.additionalData.drkGuardian;
                    vm.customerDialog.command.fields.note = customer.additionalData.note;
                } else {
                    vm.customerDialog.command.fields.drkGuardian = '';
                    vm.customerDialog.command.fields.note = '';
                }

                angular.forEach(fields, function (field) {
                    if (field.type === 'check') {
                        angular.forEach(field.options, function (option) {
                            if (data[field.name] && data[field.name].indexOf(option.key) !== -1) {
                                option.checked = true;
                            } else {
                                option.checked = false;
                            }
                        });
                    } else if (field.type === 'contact') {
                        angular.forEach(field.options, function (option) {
                            var optionData;
                            if (data[field.name] && (optionData = _.find(data[field.name], {key: option.key}))) {
                                option.value = optionData.value;
                                option.preferred = optionData.preferred;
                            } else {
                                option.value = null;
                                option.preferred = false;
                            }
                        });
                    } else if (field.type === 'limits') {
                        _.remove(field.options, function (option) {
                            return option.key === 'custom';
                        });
                        angular.forEach(field.options, function (option) {
                            var optionData;
                            if (data[field.name] && (optionData = _.find(data[field.name], {key: option.key}))
                            ) {
                                option.checked = true;
                                option.limit = optionData.limit;
                                option.noAmount = optionData.noAmount;
                            } else {
                                option.checked = false;
                                option.limit = null;
                                option.noAmount = false;
                            }
                        });
                        if (data[field.name]) {
                            data[field.name].filter(function (option) {
                                return option.key === 'custom';
                            }).forEach(function (option) {
                                field.options.push({
                                    key: 'custom',
                                    label: option.label,
                                    limit: option.limit,
                                    noAmount: option.noAmount
                                });
                            });
                        }
                        field.addNewCustomField();
                    } else if (field.type === 'brands') {
                        _.remove(field.options, function (option) {
                            return option.key === 'custom';
                        });
                        angular.forEach(field.options, function (option) {
                            var optionData;
                            if (data[field.name] && (optionData = _.find(data[field.name], {key: option.key}))
                            ) {
                                option.checked = true;
                            } else {
                                option.checked = false;
                            }
                        });
                        if (data[field.name]) {
                            data[field.name].filter(function (option) {
                                return option.key === 'custom';
                            }).forEach(function (option) {
                                field.options.push({
                                    key: 'custom',
                                    label: option.label
                                });
                            });
                        }
                        field.addNewCustomField();
                    }
                });
            }

            function buildCommandFields() {
                var command = vm.customerDialog.command;
                //reset
                angular.forEach(fields, function (field) {
                    if (field.type === 'check') {
                        command.fields[field.name] = [];
                    }
                });

                angular.forEach(fields, function (field) {
                    if (field.type === 'check') {
                        command.fields[field.name] = command.fields[field.name].concat(field.options.filter(function (option) {
                            return option.checked;
                        }).map(function (option) {
                            return option.key;
                        }));
                    } else if (field.type === 'contact') {
                        command.fields[field.name] = field.options.filter(function (option) {
                            return option.value;
                        }).map(function (option) {
                            return {
                                key: option.key,
                                value: option.value,
                                preferred: option.preferred
                            };
                        });
                    } else if (field.type === 'limits') {
                        command.fields[field.name] = field.options.filter(function (option) {
                            return option.checked || (option.key === 'custom' && option.label);
                        }).map(function (option) {
                            var amount = option.limit ? parseFloat(('' + option.limit).replace(',', '.')) : null,
                                data = {
                                    key: option.key,
                                    limit: amount,
                                    noAmount: option.noAmount
                                };

                            if (data.key === 'custom') {
                                data.label = option.label;
                            }

                            return data;
                        });
                    } else if (field.type === 'brands') {
                        command.fields[field.name] = field.options.filter(function (option) {
                            return option.checked || (option.key === 'custom' && option.label);
                        }).map(function (option) {
                            var data = {key: option.key};
                            if (data.key === 'custom') {
                                data.label = option.label;
                            }
                            return data;
                        });
                    }
                });
            }

            vm.customerDialog = {
                shown: false,
                show: function (customer) {
                    this.customer = customer;
                    buildCommand(customer);
                    this.shown = true;
                },
                customer: null,
                fields: fields,
                command: null,
                submit: function () {
                    buildCommandFields();
                    vm.customerDialog.command.execute().then(function (result) {
                        this.customer.additionalData = result.additionalData;
                        this.shown = false;
                    }.bind(this));
                }
            };
        })(this);


        vm.grid.loadPage(1);

    }
]);