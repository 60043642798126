'use strict';
angular.module('B2B').directive('bankAccountChangedDialog', ['$localStorage', function ($localStorage) {

    return {
        restrict: 'E',
        templateUrl: '/views/customer/bank-account-changed-dialog.html',
        replace: false,
        scope: {},
        link: function (scope) {
            scope.$on('customer.changed', function (e, customer) {
                if (customer && customer.bankAccount) {
                    scope.customerData = customer;
                    if (customer.bankAccountChanged && $localStorage.customerBankAccount !== customer.bankAccount) {
                        $localStorage.customerBankAccount = customer.bankAccount;
                        scope.show = true;
                    }
                }
            });
        }
    };

}]);