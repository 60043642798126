/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('gridItemsOnPage', function () {

    return {
        restrict: 'E',
        templateUrl: '/views/scaffolding/grid/items-on-page.html',
        replace: true,
        scope: {
            grid: '='
        },
        link: function (scope) {
            scope.pageSizes = [
                24,
                48,
                100
            ];
        }
    };

});