/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('AddressService', [
    '$q', 'mm.core.api.API', 'mm.core.dom.NoticeService', 'CustomerSocketService', 'mm.core.api.CommandFactory',
    function ($q, API, NoticeService, CustomerSocketService, CommandFactory) {

        var data = {
                deliveryAddresses: [],
                loading: false,
                defaultAddress: null,
                defaultCartAddress: null
            },
            deferred = null,
            currentCart = null,
            /**
             * Load user's delivery addresses
             *
             * @param {Cart} [cart] Optional cart filtering
             * @returns {Promise}
             */
            loadData = function (cart) {
                var params = {};

                if (!deferred || (cart && currentCart && currentCart.id !== cart.id)) {
                    data.loading = true;
                    data.deliveryAddresses.splice(0, data.deliveryAddresses.length);
                    data.defaultAddress = null;
                    data.defaultCartAddress = null;

                    deferred = $q.defer();
                    currentCart = cart;

                    if (cart) {
                        params.cartId = cart.id;
                    }

                    API.get('customer/delivery-addresses', params).then(function (items) {
                        data.deliveryAddresses = items;
                        loadDefaultAddress(cart);
                        deferred.resolve(data);
                        deferred = null;
                        data.loading = false;
                    }, function () {
                        deferred.reject();
                        deferred = null;
                        data.loading = false;
                    });
                }
                return deferred.promise;
            },
            /**
             * Get default cart address and set as selected.
             *
             * @param {Cart} [cart]
             */
            loadDefaultAddress = function (cart) {
                var availableAddresses = data.deliveryAddresses,
                    address = null;

                data.defaultAddress = availableAddresses.filter(function (address) {
                    return address.default;
                })[0];

                if (cart) {
                    if (cart.addressId || cart.addressId === 0) {
                        address = availableAddresses.filter(function (address) {
                            return address && ('' + address.id === '' + cart.addressId);
                        })[0];
                    }
                    if (!address) {
                        address = data.defaultAddress;
                    }
                }
                data.defaultCartAddress = address;
            },
            setDefaultAddress = function (address) {
                return API.post('customer/delivery-addresses/default', {
                    addressId: address.id
                }).then(function () {
                    data.defaultAddress.default = false;
                    address.default = true;
                    data.defaultAddress = address;
                    NoticeService.success('Zmieniono domyślny adres dostawy.');
                }, function () {
                    NoticeService.error('Nie można zmienić domyślnego adresu dostawy.');
                });
            };


        // Update address status
        CustomerSocketService.on('address:status:changed', function (event) {
            var index;
            if (event.address) {
                index = _.findIndex(data.deliveryAddresses, {id: event.address.id});
                if (index !== -1) {
                    data.deliveryAddresses[index].status = event.address.status;
                }
                if (event.address.status.key === 'approved') {
                    NoticeService.success('Twój adres dostawy został zatwierdzony.');
                }
            }
        });

        return {
            data: data,
            loadData: loadData,
            setDefaultAddress: setDefaultAddress,
            /**
             * Make address creating command.
             *
             * @returns {Command}
             */
            getCreatingCommand: function () {
                return CommandFactory.create('customer/delivery-addresses', 'post', {}, {
                    successMessage: 'Adres został utworzony.'
                }).onSuccess(function () {
                    loadData(currentCart);
                });
            }
        };

    }
]);