/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('fieldRow', function () {

    return {
        restrict: 'E',
        template: '<div class="field-row">' +
        '<label for="{{:: id }}">{{:: label }}:<small ng-if="comment">{{:: comment }}</small></label>' +
        '<div class="field" ng-class="{error : command.errors[bind]}" ng-switch="type">' +
        '<textarea ng-switch-when="textarea" id="{{:: id }}" ng-model="value" ' +
        'maxlength="{{:: maxlength }}" ng-model-options="{ getterSetter: true }" ></textarea>' +
        '<input ng-switch-when="date" id="{{:: id }}" type="text" date-time min-view="date" ' +
        'ng-model="value" ng-model-options="{ getterSetter: true }" />' +
        '<input  ng-switch-default id="{{:: id }}" type="{{:: type }}" ui-mask="{{:: mask }}"' +
        'ng-model="value" maxlength="{{:: maxlength }}" ' +
        'model-view-value="{{:: modelViewValue }}" ng-model-options="{ getterSetter: true }" />' +
        '</div>' +
        '<span class="error" ng-repeat="errorMsg in command.errors[bind]">{{:: errorMsg }}</span>' +
        '</div>',
        replace: true,
        scope: {
            bind: '@',
            type: '@',
            label: '@',
            comment: '@'
        },
        require: '^mmCommand',
        link: function (scope, element, attrs, commandCtrl) {
            scope.id = commandCtrl.getId() + '-' + attrs.bind;
            scope.type = scope.type ? scope.type : 'text';
            scope.command = commandCtrl.getCommand();
            scope.maxlength = attrs.maxlength;
            scope.mask = attrs.mask;
            scope.modelViewValue = attrs.modelViewValue;

            /**
             * Model getterSetter.
             *
             * @param nevValue
             * @returns {*}
             */
            scope.value = function (nevValue) {
                if (arguments.length) {
                    _.set(scope.command.fields, scope.bind, nevValue);
                } else {
                    return _.get(scope.command.fields, scope.bind);
                }
            };
        }
    };

});