/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('deliveryAddresses', [
    'mm.core.auth.AuthService',
    'AddressService',
    'CheckoutService',
    function (AuthService, AddressService, CheckoutService) {

        return {
            restrict: 'E',
            templateUrl: '/views/address/delivery-addresses.html',
            replace: true,
            scope: {
                header: '@',
                cart: '='
            },
            link: function (scope, element, attrs) {
                var createAddress = {
                    enabled: false,
                    dialog: {
                        shown: false,
                        open: function () {
                            this.shown = true;
                        }
                    },
                    command: AddressService.getCreatingCommand().onSuccess(function (deliveryAddress) {
                        if (scope.cart) {
                            AddressService.loadData(scope.cart).then(function () {
                                scope.selection.address = _.find(
                                    AddressService.data.deliveryAddresses,
                                    {id: deliveryAddress.id}
                                );
                                scope.selection.changed();
                            });
                        }
                        createAddress.dialog.shown = false;
                        this.clear();
                    })
                };

                scope.data = AddressService.data;
                scope.selection = {
                    address: '',
                    changed: function () {
                        if (scope.cart) {
                            CheckoutService.selectDeliveryAddress(this.address);
                        }
                    }
                };

                scope.setAsDefault = function (address) {
                    address.loading = true;
                    AddressService.setDefaultAddress(address).finally(function () {
                        address.loading = false;
                    });
                };

                if (scope.cart) {
                    createAddress.command.fields.persistence = 'single';
                    createAddress.command.fields.cartId = scope.cart.id;
                }

                AddressService.loadData(scope.cart ? scope.cart : null).then(function () {
                    if (scope.cart) {
                        if (CheckoutService.data.deliveryAddress) {
                            scope.selection.address = _.find(
                                AddressService.data.deliveryAddresses,
                                {id: CheckoutService.data.deliveryAddress.id}
                            );
                        } else {
                            scope.selection.address = AddressService.data.defaultCartAddress ?
                                AddressService.data.defaultCartAddress : AddressService.data.defaultAddress;
                            CheckoutService.selectDeliveryAddress(scope.selection.address);
                        }
                    } else {
                        scope.selection.address = AddressService.data.defaultAddress;
                    }
                });

                AuthService.getUser().then(function (user) {
                    createAddress.enabled = user.hasAccess('addresses_management');
                });

                scope.create = createAddress;
            }
        };

    }])
;