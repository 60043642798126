/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('RangeFilter', function () {

    var RangeFilter = function (data) {
        this.id = data.id;
        this.type = 'range';
        this.name = data.name;
        this.unit = data.unit;
        this.disabled = !!data.disabled;

        this.range = {
            start: null,
            end: null
        };

        this.callbacks = {
            onChange: []
        };

        this.sliderOptions = {
            floor: null,
            ceil: null,
            keyboardSupport: false,
            translate: function (value) {
                return value + (this.unit ? ' ' + this.unit : '');
            }.bind(this),
            onEnd: function () {
                this.triggerChange();
            }.bind(this),
            hideLimitLabels: true
        };

        if (typeof data.onChange === 'function') {
            this.callbacks.onChange.push(data.onChange);
        }
    };

    /**
     * Clear filter (values and callback by default)
     *
     * @returns {RangeFilter}
     */
    RangeFilter.prototype.clearData = function () {
        this.clearSelection();
        this.sliderOptions.floor = null;
        this.sliderOptions.ceil = null;
        this.callbacks = {
            onChange: []
        };
        return this;
    };

    /**
     * Clear filter selections
     *
     * @param {boolean} triggerChange
     * @returns {RangeFilter}
     */
    RangeFilter.prototype.clearSelection = function (triggerChange) {
        this.range.start = null;
        this.range.end = null;
        if (triggerChange) {
            this.triggerChange();
        }
        return this;
    };

    /**
     * @param {number} [minValue]
     * @param {number} [maxValue]
     */
    RangeFilter.prototype.setRange = function (minValue, maxValue) {
        this.range.start = typeof minValue === 'number' ? minValue : this.sliderOptions.floor;
        this.range.end = typeof maxValue === 'number' ? maxValue : this.sliderOptions.ceil;
    };

    /**
     * @param {number} [minValue]
     * @param {number} [maxValue]
     */
    RangeFilter.prototype.setMaxRange = function (minValue, maxValue) {
        this.sliderOptions.disabled = minValue === maxValue;
        this.sliderOptions.floor = this.range.start = minValue;
        this.sliderOptions.ceil = this.range.end = maxValue;
    };

    /**
     * Add on values change callback
     *
     * @param {function} callback
     */
    RangeFilter.prototype.onChange = function (callback) {
        this.callbacks.onChange.push(callback);
    };

    /**
     * Call filter listener will all selected values
     */
    RangeFilter.prototype.triggerChange = function () {
        //TODO use broadcast instead
        angular.forEach(this.callbacks.onChange, function (callback) {
            callback(this.getSelectedValues());
        }.bind(this));
    };

    /**
     * Get filter selected range values (min and max)
     * Returns empty array if no value selected
     *
     * @returns {Array}
     */
    RangeFilter.prototype.getSelectedValues = function () {
        var rangeStart = this.range.start !== this.sliderOptions.floor ? this.range.start : null,
            rangeEnd = this.range.end !== this.sliderOptions.ceil ? this.range.end : null;
        if (typeof rangeStart === 'number' || typeof rangeEnd === 'number') {
            return [rangeStart, rangeEnd];
        } else {
            return [];
        }
    };

    return RangeFilter;
});

angular.module('B2B').directive('rangeFilter', ['$timeout', function ($timeout) {
    return {
        restrict: 'E',
        templateUrl: '/views/catalog/filter/range-filter.html',
        replace: true,
        scope: {
            filter: '='
        },
        link: function (scope) {
            /**
             * Toggle filter visibility
             */
            scope.toggleFilter = function () {
                scope.toggled = !scope.toggled;
                $timeout(function () {
                    scope.$broadcast('rzSliderForceRender');
                }, 5);
                $timeout(function () {
                    scope.$broadcast('rzSliderForceRender');
                }, 100);
            };
        }
    };
}]);