/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('SelectionListHeaderFilter', ['HeaderFilter',
    function (HeaderFilter) {

        /**
         * @constructor
         */
        var SelectionListHeaderFilter = function (filter, scope) {
            HeaderFilter.call(this, filter.name, scope);
            this.filter = filter;
        };

        SelectionListHeaderFilter.prototype = Object.create(HeaderFilter.prototype);
        SelectionListHeaderFilter.prototype.constructor = SelectionListHeaderFilter;

        /**
         * Bind selected values list
         */
        SelectionListHeaderFilter.prototype.bindValues = function () {
            if (this.filter.selected && this.filter.selected.value) {
                this.values.push({
                    name: this.filter.selected.label,
                    remove: function () {
                        this.filter.clearSelection();
                    }.bind(this)
                });
            }
        };

        /**
         * Clear all attribute filter values
         */
        SelectionListHeaderFilter.prototype.remove = function () {
            this.filter.clearSelection();
        };

        SelectionListHeaderFilter.prototype.init = function () {
            this.scope.$on(this.filter.id + '.filter.changed', function () {
                this.refreshValues();
            }.bind(this));
            this.refreshValues();
        };

        return SelectionListHeaderFilter;
    }
]);