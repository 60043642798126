'use strict';
angular.module('B2B').directive('companyStatementDialog',
    ['$localStorage', 'mm.core.auth.AuthService', 'mm.core.api.API',
        function ($localStorage, Auth, API) {
            return {
                restrict: 'E',
                templateUrl: '/views/company/company-statement-dialog.html',
                replace: false,
                scope: {},
                link: function (scope) {
                    $localStorage.promotionStatementShown = null;
                    // promotion date range
                    var start = new Date(2021, 9, 18, 0, 0, 0);
                    var end = new Date(2021, 9, 22, 24, 59, 59);
                    var now = formatDate(new Date());
                    var savedDay = $localStorage.promotionStatementShown;

                    function boot(savedDay) {
                        API.get('auth/user').then(function (user) {
                            if (user) {
                                scope.isUserLogged = true;
                                $localStorage.promotionStatementShown = null;

                                if (window.location.pathname === '/') {
                                    scope.show = true;
                                }
                            }
                        }).catch(function (error) {
                            scope.show = false;
                            scope.isUserLogged = false;
                        });
                    }

                    /**
                     * Checks if saved day is in a proper format, exists and meet date conditions.
                     *
                     * @param {Date|null} savedDay
                     *
                     * @returns {null|*}
                     */
                    function clearPromotionStatementShown(savedDay) {
                        if (!savedDay || !testDate(savedDay) ||
                            (Date.parse(savedDay) < Date.parse(start) || Date.parse(savedDay) > Date.parse(end))) {
                            savedDay = $localStorage.promotionStatementShown = null;
                        }

                        return savedDay;
                    }

                    function handleIfSavedDay() {
                        if (Date.parse(savedDay) < Date.parse(start)) {
                            scope.show = true;
                            $localStorage.promotionStatementShown = now;
                        } else if (Date.parse(savedDay) > Date.parse(end)) {
                            scope.show = false;
                            $localStorage.promotionStatementShown = null;
                        }
                    }

                    function showStatement() {
                        savedDay = clearPromotionStatementShown(savedDay);

                        if ((now >= start && now <= end)) {
                            // if current date meet date conditions
                            if (!savedDay || window.location.pathname === '/') {
                                // if now savedDay is null
                                scope.show = true;
                                $localStorage.promotionStatementShown = now;
                                return;
                            }

                            if (savedDay) {
                                handleIfSavedDay();
                                return;
                            }
                        }
                        // if does not meet any date conditions
                        scope.show = false;
                        $localStorage.promotionStatementShown = null;
                    }

                    scope.click = function () {
                        $localStorage.promotionStatementShown = now;
                        scope.show = false;
                    };

                    function formatDate(date) {
                        return new Date(date.setHours(1, 0, 0, 0));
                    }

                    /**
                     * Checks if date has specified format and exists.
                     *
                     * @param {Date}  date
                     *
                     * @returns {boolean}
                     */
                    function testDate(date) {
                        var result = date.match(/Z/g);

                        return result && result.length;
                    }

                    boot(savedDay);

                    scope.$on('mm.auth.logout', function () {
                        boot(savedDay);
                    });

                    scope.$on('mm.auth.changed', function () {
                        boot(savedDay);
                    });

                    // old code for normal popup
                    // var date = (new Date()).toLocaleDateString();
                    // if ($localStorage.promotionStatementShown !== date) {
                    //     $localStorage.promotionStatementShown = date;
                    //     scope.show = true;
                    // }
                }
            };

        }]);
