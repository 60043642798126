/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('SelectionListFilter', ['$rootScope', function ($rootScope) {

    var SelectionListFilter = function (data) {
        this.id = data.id;
        this.type = 'selection-list';
        this.name = data.name;
        this.options = data.options; // array of { label, value }
        this.defaultOption = this.options[0];
        this.selected = this.defaultOption;
    };

    /**
     * Set selected option value.
     *
     * @param {string} value
     * @returns {SelectionListFilter}
     */
    SelectionListFilter.prototype.setValue = function (value) {
        this.selected = _.find(this.options, {value: value});
        this.triggerChange();
        return this;
    };

    /**
     * Clear filter selections
     *
     * @returns {SelectionListFilter}
     */
    SelectionListFilter.prototype.clearSelection = function () {
        this.selected = this.defaultOption;
        this.triggerChange();
        return this;
    };

    /**
     * Call filter listener with selected option
     */
    SelectionListFilter.prototype.triggerChange = function () {
        $rootScope.$broadcast(this.id + '.filter.changed', this.selected);
    };

    return SelectionListFilter;
}]);

angular.module('B2B').directive('selectionListFilter', function () {
    return {
        restrict: 'E',
        templateUrl: '/views/catalog/filter/selection-list-filter.html',
        replace: true,
        scope: {
            filter: '='
        },
        link: function (scope) {
            scope.onChange = function () {
                scope.filter.triggerChange();
            };
        }
    };
});