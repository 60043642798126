/**
 * Creates product availability notification.
 *
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productNotification', [
    'mm.core.api.CommandFactory',
    function (CommandFactory) {

        return {
            restrict: 'E',
            templateUrl: '/views/catalog/product/product-notification.html',
            replace: true,
            link: function (scope) {

                scope.notificationCommand = CommandFactory.create(
                    'products/' + scope.product.id + '/notification',
                    'post',
                    null,
                    {
                        successMessage: 'Utworzono nowe powiadomienie.'
                    }
                ).onSuccess(function () {
                    scope.notificationCreated = true;
                });
            }
        };

    }
]);