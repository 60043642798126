'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('TraderAccountController', [
    '$scope', 'mm.core.api.CommandFactory', 'mm.core.auth.AuthService',
    function ($scope, CommandFactory, AuthService) {

        $scope.passwordData = {
            dialogShown : false,
            openDialog : function() {
                this.dialogShown = true;
            }
        };

        // Bind change password command
        $scope.passwordData.command = CommandFactory.create('user', 'put');
        $scope.passwordData.command.onSuccess(function(user){
            AuthService.bindUser(user);
            $scope.passwordData.dialogShown = false;
        });

    }
]);