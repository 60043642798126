/**
 * Main Tabs (right panel) manager
 *
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('mainTabs', ['ScreenService', 'ScaffoldingService', '$window', 'MainTabsService', '$timeout',
    function (ScreenService, ScaffoldingService, $window, MainTabsService, $timeout) {

        return {
            restrict: 'E',
            template: '<nav id="main-tabs">' +
            '<ul>' +
            '<li ng-repeat="tab in data.tabs" ng-class="{current : tab.current}">' +
            '<a ng-click="toggleTab(tab)">' +
            '<i class="{{tab.icon}}">' +
            '<em ng-if="tab.loading" class="loader"></em>' +
            '<em ng-if="tab.iconLabel">{{tab.iconLabel}}</em>' +
            '</i></a>' +
            '</li>' +
            '</ul>' +
            '<div ng-transclude></div>' +
            '</nav>',
            replace: true,
            transclude: true,
            scope: {},
            controller: function ($scope, $element) {

                var clickOut = function (event) {
                    if ($element[0].contains(event.target)) return;
                    $timeout(function () {
                        MainTabsService.hideTabs(null, true);
                    });
                };

                $scope.data = MainTabsService.data;
                $scope.toggleTab = MainTabsService.toggleTab;

                angular.element($window).on('click', clickOut);

                $scope.$on('$destroy', function () {
                    angular.element($window).off('click', clickOut);
                });
            }
        };

    }
]);

/**
 * Single tab directive
 */
angular.module('B2B').directive('mainTab', ['MainTabsService', function (MainTabsService) {

    return {
        restrict: 'E',
        template: '<aside class="main-tab" ng-class="{toggled : current}">' +
        '<header>' +
        '<i class="{{icon}}" ng-if="!isHeaderCustomized"></i>' +
        '<h3 class="caption" ng-if="!isHeaderCustomized">{{caption}}</h3>' +
        '<a class="close" ng-click="hideTab()"></a>' +
        '</header>' +
        '<div class="content" scrollbar><div ng-transclude></div></div>' +
        '</aside>',
        replace: true,
        transclude: true,
        require: '^mainTabs',
        scope: {
            caption: '=',
            icon: '=',
            default: '=',
            iconLabel: '=',
            loading: '='
        },
        link: function (scope) {
            scope.hideTab = MainTabsService.hideTabs;
            MainTabsService.registerTab(scope);
            scope.$on('$destroy', function () {
                MainTabsService.unregisterTab(scope);
            });
        },
        controller: function ($scope, $element) {
            /**
             * Override mainTab header with custom DOM element
             *
             * @param {object} headerElement
             */
            this.overrideHeader = function (headerElement) {
                $scope.isHeaderCustomized = true;
                $element.find('header').append(headerElement);
            };
        }
    };

}]);

/**
 * Optional mainTab header overriding
 */
angular.module('B2B').directive('mainTabHeader', function () {
    return {
        restrict: 'E',
        template: '<div ng-transclude></div>',
        replace: true,
        transclude: true,
        require: '^mainTab',
        link: function (scope, element, attrs, mainTabCtrl) {
            mainTabCtrl.overrideHeader(element);
        }
    };
});