'use strict';

angular.module('B2B').controller('InvoicesController', [
    '$scope', '$state', '$window', '$timeout', 'mm.core.api.API', 'mm.core.auth.AuthService', 'Grid', 'ShippingService',
    function ($scope, $state, $window, $timeout, API, AuthService, Grid, ShippingService) {

        var vm = $scope,
            conventionalInvoiceDownloadPermission = false;

        if ($state.params.download) {
            $window.open('/api/invoice/pdf?id=' + $state.params.download, '_blank');
            $timeout(function () {
                vm.grid.loadPage();
            }, 1000);
        }

        vm.snPreview = {
            visible: false,
            open: function (invoice) {
                this.invoice = invoice;
                this.visible = true;
            }
        };

        vm.preview = {
            visible: false,
            open: function (invoice) {
                this.invoice = invoice;
                this.visible = true;
            }
        };

        vm.advancedSearch = {
            enabled: false,
            toggle: function () {
                this.enabled = !this.enabled;
            },
            columns: [
                {
                    name: 'Numer seryjny',
                    key: 'serialNumber',
                    isVisible: false,
                    searchable: true
                },
                {
                    name: 'Nazwa produktu',
                    key: 'productName',
                    isVisible: false,
                    searchable: true,
                    queryMatchType: 'like'
                },
                {
                    name: 'PN',
                    key: 'partNumber',
                    isVisible: false,
                    searchable: true
                }
            ]
        };

        vm.grid = (new Grid()).setResource('invoices')
            .setItemsOnPage(24).setSort('docDate', 'desc').setColumns([
                {
                    name: 'Faktura FA',
                    key: 'erpId',
                    sortable: true,
                    searchable: true,
                    value: function (item) {
                        return '<a ng-click="column.preview(item)">' + item.erpId + '</a>';
                    },
                    preview: function (item) {
                        vm.preview.open(item);
                    }
                },
                {
                    name: 'Rodzaj faktury',
                    key: 'type',
                    sortable: false,
                    type: 'select',
                    filter: '=',
                    values: {
                        'Invoice': 'Faktura',
                        'FiscalDocument': 'Paragon',
                        'CreditNote': 'Korekta',
                        'eInvoice': 'Faktura elektroniczna',
                        'eCreditNote': 'Korekta elektroniczna'
                    }
                },
                {
                    name: 'Data FV',
                    key: 'docDate',
                    type: 'date',
                    sortable: true,
                    filter: 'range'
                },
                {
                    name: 'Termin FV',
                    key: 'docDueDate', // TODO. its not correct value
                    type: 'date',
                    sortable: true,
                    filter: 'range'
                },
                {
                    name: 'Terminowość',
                    key: 'docDueDate',
                    sortable: true,
                    searchable: false,
                    value: function (item) {
                        if (item.paymentStatus === 'underpayment') {
                            var millisBetween = (new Date(item.docDueDate)).getTime() - (new Date()).getTime(),
                                days = Math.ceil(millisBetween / (1000 * 60 * 60 * 24)),
                                unit = Math.abs(days) === 1 ? 'dzień' : 'dni';

                            if (days < 0) {
                                return '<span class="warning">po terminie<br/>' + Math.abs(days) + ' ' + unit + '</span>';
                            }
                            return '<span>pozostało<br/>' + days + ' ' + unit + '</span>';
                        }
                        return '';
                    }
                },
                {
                    name: 'Kwota Brutto',
                    value: function (item) {
                        return item.grossValue + ' ' + item.currency;
                    },
                    key: 'grossValue',
                    sortable: true,
                    class: 'price',
                    filter: 'range',
                    type: 'double'
                },
                {
                    name: 'Zapłacone Brutto',
                    value: function (item) {
                        return item.payedValue + ' ' + item.currency;
                    },
                    key: 'payedValue',
                    sortable: true,
                    class: 'price',
                    filter: 'range',
                    type: 'double'
                },
                {
                    name: 'Spedycja',
                    sortable: true,
                    searchable: true,
                    key: 'waybills',
                    value: function (item) {
                        return item.waybills && item.waybills.length ? item.waybills.map(function (value) {
                            return ShippingService.formatWaybillTrackingUrl(value);
                        }).join('<br/>') : '-';
                    }
                },
                {
                    name: 'Status',
                    type: 'select',
                    key: 'paymentStatus',
                    filter: '=',
                    values: {
                        'payed': 'Zapłacone',
                        'underpayment': 'Niezapłacone',
                        'overpayment': 'Nadpłata'
                    }
                },
                {
                    name: 'SN',
                    class: 'actions',
                    value: function (item) {
                        if (item.hasSerialNumbers) {
                            return '<a class="preview" ng-click="column.preview(item)"></a>';
                        }
                        return '-';
                    },
                    preview: function (item) {
                        vm.snPreview.open(item);
                    }
                },
                {
                    name: 'Drukuj / pobierz',
                    class: 'actions',
                    value: function (item) {
                        var s = '<a class="' + (item.firstDownloadInfo ? 'mm-tooltip-container ' : '') +
                            'print" ng-if="column.hasDownloadAccess(item)" ng-click="column.request(\'/api/invoice/pdf?id=' + item.erpId + '\')">';
                        if (item.firstDownloadInfo) {
                            var date = new Date(item.firstDownloadInfo.date);
                            s += '<mm-tooltip class="user-info">' +
                                '<div class="user">' +
                                '<img ng-src="/api/user/' + item.firstDownloadInfo.user.id + '/image?size=small" alt="" />' +
                                'Pobrał:' +
                                '<strong>' + item.firstDownloadInfo.user.name + '</strong>' +
                                '<i>' + date.toLocaleDateString() + ' ' + date.toLocaleTimeString() + '</i>' +
                                '</div>' +
                                '</mm-tooltip>';
                        }
                        s += '</a>';

                        s += '<a class="xml" ng-if="column.hasDownloadAccess(item)" ng-click="column.request(\'/api/invoice/xml?id=' + item.erpId + '\')">&nbsp;</a>';

                        return s;
                    },
                    hasDownloadAccess: function (invoice) {
                        return invoice.type === 'eInvoice' || invoice.type === 'eCreditNote' || conventionalInvoiceDownloadPermission;
                    },
                    request: function (url) {
                        $window.open(url, '_blank');
                        $timeout(function () {
                            vm.grid.loadPage();
                        }, 1000);
                    }
                }
            ].concat(vm.advancedSearch.columns));

        AuthService.getUser().then(function (user) {
            conventionalInvoiceDownloadPermission = user.hasAccess('conventional_invoice_download');
        });

        vm.grid.loadPage(1);
    }
]);
