/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('marginCalc', ['$timeout', function ($timeout) {

    return {
        restrict: 'E',
        templateUrl: '/views/catalog/product/margin-calc.html',
        replace: true,
        scope: {
            price: '=',
            output: '='
        },
        link: function (scope) {

            /**
             * Calculate sum, margin value by margin percent input.
             */
            scope.calcByMarginPercent = function () {
                var price = parseFloat(scope.price.net),
                    marginPercent = scope.marginPercent ? parseFloat(scope.marginPercent) / 100 : 0,
                    marginValue = marginPercent * price,
                    netPrice = price + marginValue,
                    grossPrice = netPrice + netPrice * parseFloat(scope.price.vatRate);

                scope.marginValue = marginValue ? marginValue.toFixed(2) : null;
                scope.output = {
                    net: netPrice.toFixed(2),
                    gross: grossPrice.toFixed(2)
                };
            };

            /**
             * Calculate sum, margin percent by margin value input.
             */
            scope.calcByMarginValue = function () {
                var price = parseFloat(scope.price.net),
                    marginValue = scope.marginValue ? parseFloat(scope.marginValue) : 0,
                    netPrice = price + marginValue,
                    grossPrice = netPrice + netPrice * parseFloat(scope.price.vatRate);

                scope.marginPercent = marginValue ? (100 * marginValue / price).toFixed(2) : null;
                scope.output = {
                    net: netPrice.toFixed(2),
                    gross: grossPrice.toFixed(2)
                };
            };

            scope.$watch('price', function (price) {
                if (price) {
                    scope.calcByMarginPercent();
                    scope.currency = price.currency;
                }
            });
        }
    };

}]);