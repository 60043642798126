'use strict';
angular.module('B2B').directive('maxOnInCatalogNavBlock', [
    '$timeout',
    '$rootScope',
    'mm.core.api.API',
    'mm.core.auth.AuthService',
    'CartService',
    function ($timeout, $rootScope, API, Auth, CartService) {
        return {
            restrict: 'E',
            templateUrl: '/views/cms/blocks/promotion/max_on_in_catalog_nav.html',
            replace: false,
            scope: {
                block: '='
            },
            link: function (scope) {
                var timer;

                /**
                 * Lodaer
                 *
                 *
                 * @type {boolean}
                 */
                scope.loading = false;

                /**
                 * Label for add to cart button.
                 *
                 * @type {string}
                 */
                scope.label = 'do koszyka';

                /**
                 * Percentage bar max width (in pixels);
                 *
                 * @type {number}
                 */
                var meterWidth = 100;

                /**
                 * Set product name font size.
                 *
                 * @param product
                 * @returns {string}
                 */
                scope.setFontSize = function (product) {
                    return product.name.length > 80 ? '10px' : '13px';
                };


                /**
                 * Add item to cart.
                 *
                 * @returns {void}
                 */
                scope.addToCart = function () {
                    scope.loadButton = true;
                    CartService
                        .addToCart(scope.products[0], 1)
                        .finally(function () {
                            scope.loadButton = false;
                        });
                };

                /**
                 * Determine if addToCart button is enabled
                 *
                 * @returns {boolean}
                 */
                scope.isEnabled = function () {
                    return scope.products[0] && scope.products[0].quantity > 0 && !scope.products[0].buyingDisabled;
                };

                /**
                 * Determine if addToCart box is visible
                 *
                 * @returns {boolean}
                 */
                scope.isVisible = function () {
                    return Auth.access('add_to_cart');
                };

                /**
                 * Sets percentage bar proportionally.
                 *
                 * @param product
                 *
                 * @returns {string}
                 */
                function setMeterQuotient(productIndex) {
                    var product = scope.products[productIndex];

                    if (product.quantity === 0) {
                        return 0;
                    }

                    return ((product.quantity / product.discount.amount) * meterWidth).toString() + 'px';
                }

                function buildPromotion() {
                    scope.block.offerPreviewEnabled =
                        Auth.data.isLogged &&
                        Auth.data.user.hasAccess('offer_preview');

                    scope.block.addToCartEnabled = Auth.data.isLogged && Auth.data.user.hasAccess('add_to_cart');

                    scope.selectProduct(0);

                    scope.products.forEach(function (value) {
                        value.meterQuotient = setMeterQuotient(0);
                    });

                    if (scope.productCount) {
                        $rootScope.$broadcast('banner.view', {
                            block: scope.block.key,
                            type: 'promotion',
                            id: 'max-on-in-catalog-nav-block',
                            name: 'Max on'
                        });
                    }
                }

                /**
                 * Fetch promoted products and display.
                 */
                function loadPromotionsBlock() {
                    scope.loading = true;
                    API.get('products', {
                        itemsOnPage: 1,
                        filters: 'discount:max_on'
                    }).then(function (result) {
                        scope.products = result.items;
                        scope.productCount = result.total;

                        buildPromotion(scope.products, scope.productCount);
                    }, function () {
                        scope.productCount = 0;
                    }).finally(function () {
                        scope.loading = false;
                    });
                }

                /**
                 * Handle products carousel
                 */
                scope.selectProduct = function (num) {
                    $timeout.cancel(timer);
                    if (num < 0) {
                        num = scope.products.length - 1;
                    }
                    if (num > (scope.products.length - 1)) {
                        num = 0;
                    }
                    scope.current = num;
                    timer = $timeout(function () {
                        scope.showNext();
                    }, 3000);
                };

                scope.showNext = function () {
                    scope.selectProduct(scope.current + 1);
                };
                scope.showPrev = function () {
                    scope.selectProduct(scope.current - 1);
                };

                scope.$on('mm.auth.logout', function () {
                    loadPromotionsBlock();
                });

                scope.$on('mm.auth.changed', function () {
                    loadPromotionsBlock();
                });

                scope.$on('currency.changed', function () {
                    loadPromotionsBlock();
                });

                scope.$on('$destroy', function () {
                    $timeout.cancel(timer);
                });

                $rootScope.$on('$stateChangeSuccess', function () {
                    if (scope.products && (window.location.pathname === '/product,' + scope.products[0].id  ||
                        window.location.pathname === '/')) {
                        loadPromotionsBlock();
                    }
                });

                loadPromotionsBlock();
            }
        };
    }

]);
