/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productsEmbed', [
    'mm.core.auth.AuthService',
    'ProductListFactory',
    function (AuthService, ProductListFactory) {

        return {
            restrict: 'E',
            templateUrl: '/views/catalog/product/products-embed.html',
            replace: false,
            scope: {},
            link: function (scope, element, attrs) {

                var supplierIds = [],
                    /**
                     * Attach product availability warnings for trader accounts
                     *
                     * @param {Object} item Product
                     */
                    traderItemParser = function (item) {
                        if (item.supplier && item.supplier.id && supplierIds.indexOf(item.supplier.id) === -1) {
                            item.errorMsg = 'Produkt niedostępny u wskazanego dostawcy.';
                        }
                    };

                scope.list = ProductListFactory.create(scope, {
                    viewType: attrs.view,
                    facetingEnabled: false,
                    stickyHeaderEnabled: false,
                    stateParamsEnabled: false
                });

                if (attrs.supplier) {
                    supplierIds = attrs.supplier.split(',').map(function (id) {
                        return parseInt(id);
                    });
                    // Attach supplier filters only for no-trader account
                    if (!AuthService.data.user || !AuthService.data.user.hasAccess('is_trader')) {
                        scope.list.grid.setFilter('supplier.id', supplierIds, 'in');
                    } else {
                        scope.list.grid.itemParser = traderItemParser;
                    }
                }
                if (attrs.category) {
                    if (attrs.attrs) { // Only one category is supported with attributes filter
                        scope.list.grid.setFilter('category.id', attrs.category.split(',')[0]);
                        (function (grid) {
                            var attributes = attrs.attrs.split(',');
                            angular.forEach(attributes, function (item) {
                                var attributeData = item.split(':');
                                grid.setAttribute(parseInt(attributeData[0]), attributeData[1].split(';').map(function (value) {
                                    return value === '' ? null : parseFloat(value);
                                }));
                            });
                        })(scope.list.grid);
                    } else {
                        scope.list.grid.setFilter('category.id', attrs.category.split(','), 'in');
                    }
                }
                if (attrs.producer) {
                    scope.list.grid.setFilter('producer.id', attrs.producer.split(','), 'in');
                }
                if (attrs.product) {
                    scope.list.grid.setFilter('id', attrs.product.split(','), 'in');
                }

                if (typeof attrs.single !== 'undefined') {
                    scope.list.grid.setItemsOnPage(1);
                    scope.singleProduct = true;
                } else if (attrs.onPage && !isNaN(attrs.onPage)) {
                    scope.list.grid.setItemsOnPage(parseInt(attrs.onPage));
                }

                if (typeof attrs.autoWidth !== 'undefined') {
                    scope.autoWidth = true;
                }

                scope.list.init();

                // Attach supplier filters only for no-trader account
                scope.$on('mm.auth.login', function (event, user) {
                    if (user && user.hasAccess('is_trader') && attrs.supplier) {
                        scope.list.grid.clearFilter('supplier.id');
                        scope.list.grid.itemParser = traderItemParser;
                    }
                });
            }
        };

    }]);