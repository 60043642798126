/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('PriceFilterService', [
    '$state', '$rootScope', 'RangeFilter',
    function ($state, $rootScope, RangeFilter) {

        var data = {
            filter: null
        };

        /**
         * Create new filter or update filter data
         *
         * @param {Array} selectedRange
         * @param {Object} priceRangeValues Min, Max price values
         * @param {function} onChange
         * @returns {RangeFilter}
         */
        function saveFilter(selectedRange, priceRangeValues, onChange) {

            if (!data.filter) {
                data.filter = new RangeFilter({
                    id: 'price-f',
                    name: 'Cena',
                    unit: 'PLN',
                    onChange: onChange
                });
                data.filter.setMaxRange(Math.floor(priceRangeValues.min), Math.ceil(priceRangeValues.max));

                if (selectedRange) {
                    data.filter.setRange(
                        selectedRange[0] ? parseFloat(selectedRange[0]) : null,
                        selectedRange[1] ? parseFloat(selectedRange[1]) : null
                    );
                }
                $rootScope.$broadcast('price.filter.created', data.filter);
            } else {
                data.filter.setMaxRange(Math.floor(priceRangeValues.min), Math.ceil(priceRangeValues.max));

                if (selectedRange) {
                    data.filter.setRange(
                        selectedRange[0] ? parseFloat(selectedRange[0]) : null,
                        selectedRange[1] ? parseFloat(selectedRange[1]) : null
                    );
                }
            }

            return data.filter;
        }

        return {
            data: data,
            clearSelection: function () {
                if (data.filter) {
                    data.filter.clearSelection(true);
                }
            },
            removeFilter: function () {
                data.filter = null;
            },
            /**
             * Reload price filter
             *
             * @param {Array} selectedRange
             * @param {Object} priceRangeValues Min, Max price values
             * @param {function} onChange
             * @returns {Promise}
             */
            loadFilter: function (selectedRange, priceRangeValues, onChange) {
                if (priceRangeValues && priceRangeValues.min && priceRangeValues.max) {
                    saveFilter(selectedRange, priceRangeValues, onChange);
                } else {
                    data.filter = null;
                }

                return data.filter;
            }
        };
    }
]);