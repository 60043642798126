'use strict';

angular.module('B2B').controller('SearchController', [
    '$scope',
    'CategoryService',
    'ProductListFactory',
    'Analytics',
    function ($scope,
              CategoryService,
              ProductListFactory,
              Analytics
    ) {

        var productList = ProductListFactory.create($scope, {
            categoryTreeFacetingEnabled: true,
            sessionDataEnabled: true,
            userSettingsEnabled: true
        });

        // Track 0 results
        productList.grid.onPageLoad(function (data) {
            if (data.pagination.page === 1 && data.items.length === 0 && productList.grid.query) {
                Analytics.trackEvent('Search', 'no results', productList.grid.query);
            }
        });

        $scope.list = productList;

        $scope.$on('search.query.changed', function (event, query) {
            this.query = query;
        }.bind(this));

        // Init productList, delay tree rendering if category facets are no loaded yet.
        if (CategoryService.data.hasFacets) {
            productList.init();
        } else {
            CategoryService.delayTreeRendering(function () {
                return productList.init();
            });
        }
    }
]);
