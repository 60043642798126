/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('RangeHeaderFilter', ['HeaderFilter', function (HeaderFilter) {

    /**
     * @param {RangeFilter} filter
     * @constructor
     */
    var RangeHeaderFilter = function (filter, scope) {
        HeaderFilter.call(this, filter.name, scope);

        this.filter = filter;

        filter.onChange(function () {
            this.refreshValues();
        }.bind(this));
    };

    RangeHeaderFilter.prototype = Object.create(HeaderFilter.prototype);
    RangeHeaderFilter.prototype.constructor = RangeHeaderFilter;

    /**
     * Bind selected range values list
     */
    RangeHeaderFilter.prototype.bindValues = function () {
        var rangeValues = this.filter.getSelectedValues(),
            bindRangeValue = function(max) {
                if (typeof rangeValues[max?1:0] === 'number') {
                    this.values.push({
                        name: (max?'Do ':'Od ') + rangeValues[max?1:0] + (this.filter.unit?' ' + this.filter.unit:''),
                        remove: function(){
                            this.filter.range[max?'end':'start'] = null;
                            this.filter.triggerChange();
                        }.bind(this)
                    });
                }
            }.bind(this);

        // Min range value
        bindRangeValue(false);
        // Max range value
        bindRangeValue(true);
    };

    /**
     * Clear all attribute filter values
     */
    RangeHeaderFilter.prototype.remove = function() {
        this.filter.clearSelection().triggerChange();
    };

    return RangeHeaderFilter;
}]);