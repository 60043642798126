/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productItem', ['$timeout', 'Analytics',
    function ($timeout, Analytics) {

    return {
        restrict: 'E',
        templateUrl: '/views/catalog/product/product-item.html',
        replace: true,
        link: function (scope) {

            //Preload image src
            function preload(src, callback) {
                var img = new Image();
                img.onload = callback;
                img.onerror = callback;
                img.src = src;
            }

            if (scope.product.mainImage && scope.product.mainImage.src.thumbnail) {

                Analytics.trackEvent('product-image', 'thumbnail', scope.product.id);
                preload(scope.product.mainImage.src.thumbnail, function () {
                    $timeout(function(){
                        scope.imageLoaded = true;
                    });
                });
            }
        }
    };

}]);
