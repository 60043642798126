'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('MaintenanceController', [
    '$scope',
    '$window',
    '$timeout',
    '$state',
    'mm.core.api.API',
    function ($scope, $window, $timeout, $state, API) {

        // Hard reloading location (script) when state changing from 503
        $timeout(function () {
            $scope.$on('$stateChangeStart', function (event, toState) {
                $window.location.reload(true);
            });
        });

        // If request returns 200, reload app
        API.get('auth/token').then(function () {
            $window.location.href = '/';
        });
    }
]);
