/**
 * Sends question about the product to customer's trader.
 *
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productQuestion', [
    'mm.core.api.CommandFactory',
    function (CommandFactory) {

        return {
            restrict: 'E',
            templateUrl: '/views/catalog/product/product-question.html',
            replace: true,
            link: function (scope) {

                scope.questionDialog = {
                    shown: false,
                    open: function () {
                        this.shown = true;
                    }
                };

                scope.questionCommand = CommandFactory.create(
                    'products/' + scope.product.id + '/question',
                    'post',
                    null,
                    {
                        successMessage: 'Pytanie zostało przesłane.'
                    }
                ).onSuccess(function () {
                    scope.questionDialog.shown = false;
                    scope.questionCommand.clear();
                });

            }
        };

    }
]);