'use strict';
angular.module('B2B').directive('messageNav', ['MessageService', function (MessageService) {

    return {
        restrict: 'E',
        templateUrl: '/views/messages/message-nav.html',
        replace: false,
        link: function (scope) {
            scope.data = MessageService.data;
            MessageService.run();
        }
    };

}]);