/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('SearchQueryHeaderFilter', ['HeaderFilter', 'SearchService',
    function (HeaderFilter, SearchService) {

        /**
         * @constructor
         */
        var SearchQueryHeaderFilter = function (scope) {
            HeaderFilter.call(this, 'Fraza', scope);
        };

        SearchQueryHeaderFilter.prototype = Object.create(HeaderFilter.prototype);
        SearchQueryHeaderFilter.prototype.constructor = SearchQueryHeaderFilter;

        /**
         * Bind selected values list
         */
        SearchQueryHeaderFilter.prototype.bindValues = function () {
            var query = SearchService.getQuery();
            if (query) {
                this.values.push({
                    name: query,
                    remove: function () {
                        SearchService.clearQuery();
                    }
                });
            }
        };

        /**
         * Clear all attribute filter values
         */
        SearchQueryHeaderFilter.prototype.remove = function() {
            SearchService.clearQuery();
        };

        SearchQueryHeaderFilter.prototype.init = function() {
            this.scope.$on('search.query.changed', function(event, query){
                this.refreshValues();
            }.bind(this));
            this.refreshValues();
        };

        return SearchQueryHeaderFilter;
    }
]);