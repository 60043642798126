/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('CartViewHelper', [
    'mm.core.auth.AuthService',
    function (AuthService) {

        var CartViewHelper = function () {
            this.toggledCartIds = [];
            this.ownerCartsFiltering = false;
            /**
             * Determine if cart should be visible on list
             * @param {Cart} cart
             */
            this.filterVisibility = function (cart) {
                return !this.ownerCartsFiltering || cart.owner.id === AuthService.data.user.id;
            }.bind(this);
        };

        /**
         * Toggle cart
         *
         * @param {Cart} cart
         */
        CartViewHelper.prototype.toggle = function (cart) {
            var index = this.toggledCartIds.indexOf(cart.id);
            if (index !== -1) {
                this.toggledCartIds.splice(index, 1);
            } else {
                this.toggledCartIds.push(cart.id);
            }
        };

        /**
         * @param {Cart} cart
         * @returns {boolean}
         */
        CartViewHelper.prototype.isToggled = function (cart) {
            return this.toggledCartIds.indexOf(cart.id) !== -1;
        };

        return {
            instance: function () {
                return new CartViewHelper();
            }
        };
    }

])
;