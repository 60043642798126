/**
 * @author Modest Machnicki
 */
'use strict';

angular.module('resource', [
    'ngResource'
]);
/**
 * @author Modest Machnicki
 */
'use strict';

angular.module('resource').factory('Cart', ['$resource', function ($resource) {

    var Cart = $resource('/api/carts/:cartId', {cartId: '@id'});

    angular.extend(Cart.prototype, {
        /**
         * Toggle cart visibility
         */
        toggle: function() {
            this.toggled = !this.toggled;
        }
    });

    return Cart;
}]);