'use strict';

angular.module('B2B').controller('PromotionsPageController', [
    'ProducerFilterService', 'AttributeFilterService', 'PriceFilterService', 'SearchService',
    function (
        ProducerFilterService,
        AttributeFilterService,
        PriceFilterService,
        SearchService
    ) {
        ProducerFilterService.loadFilter();
        AttributeFilterService.removeFilters();
        PriceFilterService.removeFilter();
        SearchService.clearQuery();
    }
]);
