/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ExportUrlFactory', [
    'mm.core.api.API',
    'mm.core.dom.ConfirmDialogService',
    function (API,
              ConfirmDialogService) {

        var ExportUrl = function (service) {
            this.service = service;
            this.url = null;
            this.timeLimit = 0;
            this.loading = false;
            this.getServiceInfo();
        };

        /**
         * Create new (and override current) user's URL to generating products xml
         */
        ExportUrl.prototype.generateUrl = function() {
            this.loading = true;
            API.post('export/' + this.service + '/url').then(function (url) {
                this.url = url;
            }.bind(this)).finally(function () {
                this.loading = false;
            }.bind(this));
        };

        /**
         * Fetch export service info (time limit and URL)
         */
        ExportUrl.prototype.getServiceInfo = function() {
            this.loading = true;
            API.get('export/' + this.service + '/info').then(function (data) {
                this.url = data.url;
                this.timeLimit = data.timeLimit;
            }.bind(this)).finally(function () {
                this.loading = false;
            }.bind(this));
        };

        /**
         * Handle user's request for new URL
         */
        ExportUrl.prototype.requestNewUrl = function() {
            if (this.url) {
                ConfirmDialogService.confirm('Wygenerowanie nowego linku unieważni obecny. Kontunować?')
                    .then(function(){
                        this.generateUrl();
                    }.bind(this));
            } else {
                this.generateUrl();
            }
        };

        return {
            build : function (service) {
                return new ExportUrl(service);
            }
        };

    }
]);