'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('TraderProductsController', [
    '$scope', '$stateParams', 'Grid',
    function ($scope, $stateParams, Grid) {

        var vm = this;

        vm.query = '';
        vm.grid = (new Grid()).setResource('products').setItemsOnPage(24).setParam('traderSearch', true);
        vm.search = function () {
            if (vm.query.length > 1) {
                vm.grid.setQuery(vm.query).loadPage(1);
            }
        };

        if ($stateParams.query) {
            vm.query = $stateParams.query;
            vm.grid.loadState().setQuery(vm.query).loadPage();
        }

        $scope.$on('customer.changed', function (event, customer) {
            if (customer) {
                vm.grid.loadPage();
            }
        });

    }
]);