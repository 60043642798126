'use strict';

angular.module('B2B').controller('CategoryController', [
    '$scope',
    '$state',
    'CategoryService',
    'CatalogBreadcrumbService',
    'ProductListFactory',
    'SearchService',
    function ($scope,
              $state,
              CategoryService,
              CatalogBreadcrumbService,
              ProductListFactory,
              SearchService) {
        var categoryId = parseInt($state.params.category),
            productList = ProductListFactory.create($scope, {
                categoryId: categoryId,
                sessionDataEnabled: true,
                userSettingsEnabled: true
            }),
            markCurrentCategory = function () {
                return CategoryService.find(categoryId).then(function (category) {
                    $scope.breadcrumbs = CatalogBreadcrumbService.generate(category);
                    CategoryService.markAsCurrent(category);
                });
            };

        SearchService.clearQuery();

        $scope.list = productList;

        // Load category and set as current
        CategoryService.delayTreeRendering(function () {
            if (CategoryService.data.hasFacets) {
                return CategoryService.resetCategoryTreeState().then(function () {
                    return markCurrentCategory();
                })
            }
            return markCurrentCategory();
        });

        productList.init();

    }
]);
