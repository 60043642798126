'use strict';

angular.module('B2B').controller('ComplaintSalesReturnFormController', [
    '$scope', 'mm.core.api.CommandFactory', 'mm.core.auth.AuthService', 'ComplaintInvoiceSuggester',
    function ($scope, CommandFactory, AuthService, ComplaintInvoiceSuggester) {

        this.command = CommandFactory.create('complaints/sales-return', 'post', {
            reportingPerson : AuthService.data.user.name
        }, {
            successMessage: 'Zgłoszenie zostało przesłane.'
        });
        this.command.onSuccess(function () {
            this.command.clear();
        }.bind(this));

        this.suggester = ComplaintInvoiceSuggester;
        ComplaintInvoiceSuggester.disable(ComplaintInvoiceSuggester.fields.WAYBILL);
        ComplaintInvoiceSuggester.setCommand(this.command);
        ComplaintInvoiceSuggester.initScopeWatcher($scope);
    }
]);
