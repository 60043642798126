/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('TraderService', [
    '$rootScope',
    'mm.core.api.API',
    'mm.core.auth.AuthService',
    'mm.socket.SocketService',
    function ($rootScope,
              API,
              AuthService,
              SocketService) {

        var data = {
            loading: false,
            customers: null,
            current: null,
            onlineCustomers: {}
        };

        function reloadCustomerData(customer) {
            var currentUser = AuthService.data.user;
            if (customer && currentUser && currentUser.hasAccess('is_trader')) {
                API.get('trader/customers/' + customer.id).then(function (response) {
                    var index = _.findIndex(data.customers, {id: response.id});
                    if (index !== -1) {
                        angular.extend(data.customers[index], response);
                    }
                });
            }
        }

        SocketService.on('cart:updated', function (event) {
            reloadCustomerData(event.customer);
        });
        SocketService.on('cart:deleted', function (event) {
            reloadCustomerData(event.customer);
        });
        SocketService.on('customer:connected', function (customer) {
            data.onlineCustomers[customer.id] = true;
            reloadCustomerData(customer);
        });
        SocketService.on('customer:disconnected', function (customer) {
            data.onlineCustomers[customer.id] = false;
        });
        SocketService.on('customer:connections', function (event) {
            if (event.ids) {
                angular.forEach(event.ids, function (id) {
                    data.onlineCustomers[id] = true;
                });
            }
        });


        return {
            data: data,
            fetchCustomers: function () {
                API.get('trader/customers').then(function (response) {
                    data.loading = false;
                    data.customers = response.customers;
                    data.current = response.current;
                });
            },
            signAsCustomer: function (customer) {
                data.loading = true;
                API.post('trader/customers/current', {
                    clientNumber: customer.clientNumber
                }).then(function () {
                    data.current = customer.clientNumber;
                    $rootScope.$broadcast('mm.auth.changed');
                }).finally(function () {
                    data.loading = false;
                });
            },
            signOutCustomer: function () {
                data.loading = true;
                API.delete('trader/customers/current').then(function () {
                    data.current = null;
                    $rootScope.$broadcast('mm.auth.changed');
                }).finally(function () {
                    data.loading = false;
                });
            }
        };

    }
]);