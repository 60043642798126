/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('targetTable', [
    'mm.core.api.API',
    function (API) {

        return {
            restrict: 'E',
            templateUrl: '/views/cms/blocks/target-table.html',
            replace: true,
            scope: {},
            link: function (scope, element, attrs) {

                /**
                 * Fetches customer report target from server
                 */
                function loadTargets() {
                    scope.target = null;
                    scope.message = null;
                    scope.loading = true;

                    API.get('campaign/sales-target/reports/' + attrs.promoId + '/target').then(function (data) {
                        scope.loading = false;
                        scope.target = data;
                    }, function (response) {
                        if (response.errors && response.errors.message) {
                            scope.message = response.errors.message;
                        }
                        scope.loading = false;
                    });
                }

                scope.$on('mm.auth.login', function () {
                    loadTargets();
                });

                loadTargets();

            }
        };

    }]);