/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productTabs', function () {

    return {
        restrict: 'E',
        templateUrl: '/views/catalog/product/product-tabs.html',
        replace: false,
        link : function (scope) {
            scope.showTab = function (tab) {
                if (!tab) {
                    if (scope.product.attributes && scope.product.attributes.length > 0) {
                        scope.currentTab = 'attributes';
                    } else {
                        scope.currentTab = 'description';
                    }
                } else {
                    scope.currentTab = tab;
                }
            };

            scope.showTab();
        }
    };

});