'use strict';

angular.module('B2B').controller('MessageController', [
    '$state', 'MessageService',
    function ($state, MessageService) {

        var vm = this,
            itemId = $state.params.id;

        if (itemId) {
            vm.loading = true;
            MessageService.getMessage(itemId).then(function (message) {
                vm.message = message;
            }).finally(function () {
                vm.loading = false;
            });
        }

    }
]);
