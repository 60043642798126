'use strict';
angular.module('B2B').directive('addToCart', [
    'mm.core.auth.AuthService', 'CartService',
    function (Auth, CartService) {

        return {
            restrict: 'E',
            templateUrl: '/views/catalog/product/add-to-cart.html',
            replace: true,
            scope: {
                product: '='
            },
            bindToController: true,
            controllerAs: 'vm',
            controller: function () {

                var vm = this;

                vm.quantity = 1;

                vm.label = Auth.access('export_customer') ? 'do oferty' : 'do koszyka';

                vm.addToCart = function () {
                    if (vm.quantity > 0) {
                        vm.loading = true;
                        CartService.addToCart(vm.product, vm.quantity).finally(function () {
                            vm.loading = false;
                        });
                    }
                };

                /**
                 * Determine if addToCart button is enabled
                 *
                 * @returns {boolean}
                 */
                vm.isEnabled = function () {
                    return vm.product && vm.product.quantity > 0 && !vm.product.buyingDisabled;
                };

                /**
                 * Determine if addToCart box is visible
                 *
                 * @returns {boolean}
                 */
                vm.isVisible = function () {
                    return Auth.access('add_to_cart');
                };
            }
        };

    }
]);