'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('AddressConfirmationController', [
    'mm.core.api.API', '$state',
    function (API, $state) {

        var vm = this,
            addressId = $state.params.addressId,
            token = $state.params.token;

        vm.loading = true;

        API.post('customer/delivery-addresses/confirmation', {
            addressId: addressId,
            token: token
        }).then(function () {
            vm.passed = true;
            vm.loading = false;
        }, function (data) {
            vm.failed = true;
            vm.error = data.errors.message;
            vm.loading = false;
        });

    }
]);