'use strict';

angular.module('B2B').directive('filtersHeader', [
    'ProducerFilterService',
    'AttributeFilterService',
    'PriceFilterService',
    'SearchService',
    'SelectionFilter',
    'RangeFilter',
    'SelectionListFilter',
    'BooleanFilter',
    'SelectionHeaderFilter',
    'RangeHeaderFilter',
    'SelectionListHeaderFilter',
    'BooleanHeaderFilter',
    'ProducerHeaderFilter',
    'SearchQueryHeaderFilter',
    'BasicFilterService',
    function (ProducerFilterService,
              AttributeFilterService,
              PriceFilterService,
              SearchService,
              SelectionFilter,
              RangeFilter,
              SelectionListFilter,
              BooleanFilter,
              SelectionHeaderFilter,
              RangeHeaderFilter,
              SelectionListHeaderFilter,
              BooleanHeaderFilter,
              ProducerHeaderFilter,
              SearchQueryHeaderFilter,
              BasicFilterService) {

        return {
            restrict: 'E',
            templateUrl: '/views/catalog/product/list/filters-header.html',
            replace: true,
            scope: {},
            link: function (scope) {

                /**
                 * @param {HeaderFilter} headerFilter
                 */
                function initHeaderFilter(headerFilter) {
                    headerFilter.init();
                }

                /**
                 * @param {Array} filters
                 */
                function buildHeaderFilterCollection(filters) {
                    angular.forEach(filters, function (filter) {
                        if (filter instanceof SelectionFilter) {
                            initHeaderFilter(new SelectionHeaderFilter(filter, scope));
                        } else if (filter instanceof RangeFilter) {
                            initHeaderFilter(new RangeHeaderFilter(filter, scope));
                        } else if (filter instanceof SelectionListFilter) {
                            initHeaderFilter(new SelectionListHeaderFilter(filter, scope));
                        } else if (filter instanceof BooleanFilter) {
                            initHeaderFilter(new BooleanHeaderFilter(filter, scope));
                        }
                    });
                }

                /**
                 * Current selected filter list
                 * @type {Array}
                 */
                scope.headerFilters = [];

                /**
                 * Remove all filter selections
                 */
                scope.clearAll = function () {
                    ProducerFilterService.clearSelection();
                    AttributeFilterService.clearSelection();
                    SearchService.clearQuery();
                    PriceFilterService.clearSelection();
                    BasicFilterService.clearSelection();
                };

                // Observe events to keep correct list of visible filter selections
                scope.$on('headerFilter.values.appeared', function(event, headerFilter){
                    if (scope.headerFilters.indexOf(headerFilter) === -1) {
                        scope.headerFilters.push(headerFilter);
                    }
                });
                scope.$on('headerFilter.values.gone', function(event, headerFilter){
                    scope.headerFilters.splice(scope.headerFilters.indexOf(headerFilter), 1);
                });

                // Add attribute filters
                scope.$on('attribute.filters.loaded', function(event, filters){
                    buildHeaderFilterCollection(filters);
                });
                if (AttributeFilterService.data.filters.length) {
                    buildHeaderFilterCollection(AttributeFilterService.data.filters);
                }

                // Add price filters
                scope.$on('price.filter.created', function(event, filter){
                    initHeaderFilter(new RangeHeaderFilter(filter, scope));
                });
                if (PriceFilterService.data.filter) {
                    initHeaderFilter(new RangeHeaderFilter(PriceFilterService.data.filter, scope));
                }

                // Add basic filters
                buildHeaderFilterCollection(BasicFilterService.data.filters);

                // Add producer filter
                initHeaderFilter(new ProducerHeaderFilter(scope));

                // Add search query filter
                initHeaderFilter(new SearchQueryHeaderFilter(scope));
            }
        };

    }]);