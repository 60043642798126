'use strict';
angular.module('B2B').factory('CurrencyService', [
    '$rootScope',
    'mm.core.api.API',
    'mm.core.auth.AuthService',
    function ($rootScope, API, AuthService) {

        var currentCurrency = 'PLN';

        return {
            /**
             * @returns {string}
             */
            getCurrency: function () {
                return AuthService.getUser().then(function (user) {
                    if (user && user.settings['currency']) {
                        currentCurrency = user.settings['currency'];
                    }
                    return currentCurrency;
                });
            },
            /**
             * @param {string} currency
             * @returns {Promise}
             */
            setCurrency: function (currency) {
                return API.post('currency', {currency: currency}).then(function () {
                    currentCurrency = currency;
                    $rootScope.$broadcast('currency.changed');
                    return currentCurrency;
                }, function () {
                    return currentCurrency;
                });
            },
            /**
             * @returns {string[]}
             */
            getAvailableCurrencies: function () {
                return ['PLN', 'EUR', 'USD'];
            }
        };

    }
]);