/**
 * Observes scroll position and mark proper page in pagination
 *
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productPage', ['$window', '$timeout','$document', function ($window, $timeout, $document) {

    return {
        restrict: 'A',
        scope: {
            pageNo : '=productPage'
        },
        link : function (scope, elem) {
            var timer;

            /**
             * Calculate if page (elem) should be marked as current page
             */
            function scrollHandler() {
                var topOffset = elem[0].offsetParent.offsetTop + elem[0].offsetTop,
                    bottomOffset = topOffset + elem[0].clientHeight,
                    currentOffset = ($window.innerHeight/2) + $window.pageYOffset;

                if (currentOffset > topOffset && currentOffset < bottomOffset) {
                    scope.$parent.list.grid.setCurrentPage(scope.pageNo);
                }
            }

            function scrollHandlerTimeout() {
                $timeout.cancel(timer);
                timer = $timeout(scrollHandler, 40);
            }

            $document.on('scroll', scrollHandlerTimeout);

            scope.$on('$destroy', function() {
                $document.off('scroll', scrollHandlerTimeout);
            });

        }
    };

}]);
