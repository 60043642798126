'use strict';

angular.module('B2B').controller('RodoController', [
    '$rootScope', '$scope', '$state', 'mm.core.api.API', 'mm.core.auth.AuthService', 'user',
    function ($rootScope, $scope, $state, API, AuthService, user) {

        $scope.form = {
            terms_accepted: user.terms_accepted,
            newsletter_accepted: false,
            privacy_accepted: false
        };

        $scope.submitAcceptations = function () {
            angular.extend(AuthService.data.user, $scope.form);

            API.put('user/acceptance', $scope.form).then(function () {
                $state.go('home');
            });
        };

    }
]);
