/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('MainTabsService', [
    '$window', 'ScaffoldingService', '$filter', 'ScreenService',
    function ($window, ScaffoldingService, $filter, ScreenService) {

        var data = {
                tabs: []
            },
            minWindowWidth = 1890,
            recentWindowWidth = 0,
            defaultTab = null;

        /**
         * Hides or locks main tab box to the tabs panel
         */
        function windowWidthEventHandler(windowWidth) {
            if (windowWidth !== recentWindowWidth) {
                recentWindowWidth = windowWidth;
                if (windowWidth >= minWindowWidth) {
                    ScaffoldingService.data.mainTabLocked = true;
                    // Show default tabs if no tab is shown
                    if ($filter('filter')(data.tabs, {current: false}).length === data.tabs.length && defaultTab) {
                        defaultTab.current = true;
                    }
                } else {
                    ScaffoldingService.data.mainTabLocked = false;
                    hideTabs();
                }
            }
        }

        /**
         * Default tab assignment
         */
        function assignDefaultTab(tab) {
            if ($window.innerWidth >= minWindowWidth) {
                defaultTab = tab;
                tab.current = true;
            }
        }

        /**
         * Hide all tabs
         * @param {object} [ignoredTab] tab to ignore
         * @param {boolean} [unlockedOnly] If true, tabs will be hidden only for no-locked view (small resolutions).
         */
        function hideTabs(ignoredTab, unlockedOnly) {
            if (unlockedOnly && ScaffoldingService.data.mainTabLocked) {
                return;
            }
            angular.forEach(data.tabs, function (tab) {
                if (tab !== ignoredTab) {
                    tab.current = false;
                }
            });
        };

        ScreenService.onResize(function (width) {
            windowWidthEventHandler(width);
        });
        windowWidthEventHandler($window.innerWidth);


        return {
            data: data,
            /**
             * Adds mainTab to tab list
             *
             * @param {object} tab mainTab scope
             */
            registerTab: function (tab) {
                data.tabs.push(tab);
                tab.current = false;
                if (tab.default) {
                    assignDefaultTab(tab);
                }
            },
            /**
             * Remove mainTab from tab list
             *
             * @param {object} tab mainTab scope
             */
            unregisterTab: function (tab) {
                var tabIndex = data.tabs.indexOf(tab);
                if (tabIndex !== -1) {
                    data.tabs.splice(tabIndex, 1);
                }
            },
            hideTabs: hideTabs,
            /**
             * Toggle given tab
             * @param {object} tab mainTab scope
             */
            toggleTab: function (tab) {
                hideTabs(tab);
                if (tab) {
                    tab.current = !tab.current;
                }
            },
            toggleDefaultTab: function () {
                this.toggleTab(defaultTab);
            }
        };

    }

])
;