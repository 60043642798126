'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('CheckoutPaymentStepController', [
    '$state', '$scope', '$window', '$timeout', 'mm.core.api.API', 'CheckoutService', 'mm.core.auth.AuthService',
    function ($state, $scope, $window, $timeout, API, CheckoutService, AuthService) {

        var vm = this,
            orderId = $state.params.orderId,
            verifyTimer,
            loadFromOrderId = function () {
                vm.loading = true;
                API.get('orders/' + orderId).then(function (order) {
                    vm.loading = false;
                    vm.order = order;
                    handleOrderStatus();
                }, function () {
                    vm.loading = false;
                    vm.error = 'Nie odnaleziono żądanego zamówienia.';
                });
            },
            handleOrderStatus = function () {
                vm.error = null;
                vm.payment.verification = false;
                switch (vm.order.status.key) {
                    case 'payment_verification':
                        verifyPayment();
                        break;
                    case 'payment_rejected':
                        vm.error = 'Płatność została odrzucona.';
                        break;
                    case 'processed':
                    case 'created':
                        CheckoutService.setOrder(vm.order);
                        CheckoutService.loadNextStep();
                        break;
                    default:
                        initPayment();
                }
            },
            initPayment = function () {
                if (!AuthService.data.user.hasAccess('pay_u_payment')) {
                    vm.error = 'Nie posiadasz uprawnień do płatności online.';
                    return;
                }
                if (vm.order.status.key !== 'unpaid' || vm.order.paymentMethod.key.indexOf('payu') !== 0) {
                    vm.error = 'Zamówienie nie może zostać opłacone.';
                    return;
                }
                vm.payment.enabled = true;
                vm.payment.methods = null;

                if (vm.order.paymentMethod.key === 'payu_express') {
                    vm.payment.loading = true;
                    API.get('payu/methods').then(function (methods) {
                        vm.payment.methods = methods;
                    }, function () {
                        vm.error = 'Wystąpił błąd podczas pobierania dostępnych metod płatności.'
                    }).finally(function () {
                        vm.payment.loading = false;
                    });
                }
            },
            verifyPayment = function () {
                $timeout.cancel(verifyTimer);
                vm.payment.verification = true;
                API.get('payu/notifications/' + vm.order.orderId).then(function (order) {
                    if (order.status.key !== 'payment_verification') {
                        vm.order = order;
                        handleOrderStatus();
                    } else {
                        $timeout(function () {
                            verifyPayment();
                        }, 5000);
                    }
                }, function () {
                    verifyTimer = $timeout(function () {
                        verifyPayment();
                    }, 5000);
                });
            };

        vm.payment = {
            rulesAccepted: true,
            enabled: false,
            loading: false,
            verification: false,
            methods: null,
            make: function (method) {
                if (!method && vm.order.paymentMethod.key !== 'payu_credit_card') {
                    vm.error = 'Błędna metoda płatności.';
                    return;
                }
                if (!vm.payment.rulesAccepted) {
                    vm.error = 'Należy zaakceptować "Regulamin pojedynczej transakcji płatniczej PayU".';
                    return;
                }
                vm.payment.enabled = false;
                vm.loading = true;
                vm.error = null;
                API.post('payu/payment', {
                    orderId: vm.order.orderId,
                    method: method ? method.value : null
                }).then(function (redirectUrl) {
                    $window.location.href = redirectUrl;
                }, function (response) {
                    if (response.errors && response.errors.message) {
                        vm.error = response.errors.message;
                    } else {
                        vm.error = 'Wystąpił błąd podczas tworzenia płatności.'
                    }
                    vm.payment.enabled = true;
                    vm.loading = false;
                });
            }
        };

        if (!orderId && !CheckoutService.data.order) {
            $state.go('home');
        }
        if (CheckoutService.data.order) {
            vm.order = CheckoutService.data.order;
        }


        //
        // Load order and payment after auth check.
        //
        AuthService.getUser().then(function (user) {
            if (orderId) {
                CheckoutService.clearData();
                loadFromOrderId();
            } else {
                vm.order = CheckoutService.data.order;
                handleOrderStatus();
            }
        }, function () {
            vm.loading = false;
            vm.error = 'Zaloguj się, aby móc opłacić zamówienie.';

            $scope.$on('mm.auth.login', function () {
                vm.error = null;
                if (orderId) {
                    loadFromOrderId();
                } else {
                    vm.order = CheckoutService.data.order;
                    handleOrderStatus();
                }
            });
        });

        $scope.$on('$destroy', function () {
            $timeout.cancel(verifyTimer);
        });

    }
]);
