'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('SubCustomersController', [
    '$scope', 'mm.core.api.CommandFactory', 'mm.core.api.API', 'mm.core.auth.AccessService', 'CustomerService', 'mm.core.dom.NoticeService',
    function ($scope, CommandFactory, API, AccessService, CustomerService, NoticeService) {

        $scope.sendEmailNotification = '';

        /**
         * Set additional data to SubCustomer object
         *
         * @param {object} subCustomer
         * @return {object} subCustomer
         */
        function parseSubCustomerDto(subCustomer) {
            //Set permissions string
            AccessService.getPermissions().then(function (permissions) {
                var subCustomerPermissions = [];

                angular.forEach(permissions, function (permission) {
                    if (subCustomer.access[permission.id]) {
                        subCustomerPermissions.push(permission.name);
                    }
                });

                subCustomer.permissions = subCustomerPermissions.join(', ');
            });

            return subCustomer;
        }

        // Add SubCustomers
        $scope.addSubCustomer = {
            dialogShown: false,
            openDialog: function () {
                this.command.clear();
                this.dialogShown = true;
                $scope.sendEmailNotification = '';
            },
            command: CommandFactory.create('customer/subcustomers', 'post')
        };

        $scope.addSubCustomer.command.onSuccess(function (subCustomer) {
            // $scope.addSubCustomer.dialogShown = false;
            $scope.sendEmailNotification = 'Uwaga! Subkonto jest ZABLOKOWANE! Link aktywacyjny został wysłany na adres email konta głównego.';
            $scope.subcustomers.push(parseSubCustomerDto(subCustomer));
        });

        // Edit SubCustomers
        $scope.editSubCustomer = {
            dialogShown: false,
            subCustomerId: null,
            openDialog: function (subCustomer) {
                this.subCustomerId = subCustomer.id;
                this.isCurrentlyActive = subCustomer.active;
                // Build command for each subCustomer separately
                this.command = CommandFactory.create('customer/subcustomers/' + subCustomer.userable.id, 'put', {
                    firstName: subCustomer.userable.firstName,
                    lastName: subCustomer.userable.lastName,
                    email: subCustomer.email,
                    newsletter: subCustomer.userable.newsletter,
                    blocked: subCustomer.userable.blocked,
                    access: subCustomer.access
                });
                this.command.onSuccess(function (subCustomerResponse) {
                    angular.copy(subCustomerResponse, subCustomer);
                    subCustomer = parseSubCustomerDto(subCustomer);
                    $scope.editSubCustomer.dialogShown = false;
                    $scope.editSubCustomer.isCurrentlyActive = subCustomer.active;
                });
                this.dialogShown = true;
            },
            command: null,
            activateUserLoading: false,
            isCurrentlyActive: false,
            activateUser: function () {
                this.activateUserLoading = true;
                API.get('user/activate/' + this.subCustomerId).then(() => {
                    NoticeService.success('Link aktywacyjny został wysłany!');
                }).catch((error) => {
                    if (error.code === '401') {
                        NoticeService.error('Dana operacja jest niedozwolona!');
                    } else {
                        NoticeService.error('Wystąpił błąd!');
                    }
                }).finally(() => {
                    this.activateUserLoading = false;
                });
            },
        };

        // Get all SubCustomers
        CustomerService.getSubCustomers().then(function (subcustomers) {
            angular.forEach(subcustomers, function (subcustomer) {
                parseSubCustomerDto(subcustomer);
            });
            $scope.subcustomers = subcustomers;
        });

        AccessService.getPermissions().then(function (permissions) {
            $scope.permissions = permissions;
        });

    }
]);