'use strict';
angular.module('B2B').factory('TranslationService', [
    '$window',
    '$timeout',
    'Analytics',
    function ($window, $timeout, Analytics) {

        var googleTranslateLoaded = false,
            googleTranslateElement;

        return {
            /**
             * Load google translate script
             * @param {string} widgetId
             * @param {Object} element DOM element
             */
            initGoogleTranslate: function (widgetId, element) {
                var gaId = 'UA-69450373-1';
                if (Analytics.configuration.accounts && Analytics.configuration.accounts.length) {
                    gaId = Analytics.configuration.accounts[0].tracker;
                }

                if (!googleTranslateLoaded) {

                    $window.googleTranslateElementInit = function () {
                        new google.translate.TranslateElement({
                            pageLanguage: 'pl',
                            layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
                            autoDisplay: false,
                            gaTrack: false,
                            gaId: gaId
                        }, widgetId);

                        googleTranslateElement = element;
                    }

                    $timeout(function () {
                        var script = document.createElement('script');
                        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
                        document.body.appendChild(script);
                    });

                    googleTranslateLoaded = true;
                } else if (googleTranslateElement) {
                    element.replaceWith(googleTranslateElement);
                }
            }
        };

    }
]);
