'use strict';

angular.module('B2B').controller('OrdersController', [
    '$state', 'mm.core.api.API', 'Grid', 'CustomerService', 'ShippingService',
    function ($state, API, Grid, CustomerService, ShippingService) {

        var vm = this;

        if ($state.params.preview) {
            API.get('orders/' + $state.params.preview).then(function (order) {
                vm.preview.open(order);
            });
        }

        /**
         * @returns {{}} Select options obj (value => label)
         */
        function getCreatedByFilterOptions() {
            var createdByList = {};
            createdByList['0'] = '* handlowiec MAX';
            CustomerService.getCustomer().then(function (customer) {
                createdByList[customer.userId] = '* ' + customer.firstName + ' ' + customer.lastName;
            });
            CustomerService.getSubCustomers().then(function (subCustomers) {
                angular.forEach(subCustomers, function (subCustomer) {
                    createdByList[subCustomer.id] = subCustomer.name;
                });
            });
            return createdByList;
        }

        /**
         * @returns {Array} Get unique order status values
         */
        function getStatusFilterOptions() {
            var options = [];
            API.get('order-statuses').then(function (statuses) {
                angular.forEach(statuses, function (status) {
                    options.push(status);
                });
            });
            return options;
        }


        vm.preview = {
            order: null,
            visible: false,
            open: function (order) {
                this.order = order;
                this.visible = true;
            }
        };

        vm.grid = (new Grid()).setResource('orders')
            .setItemsOnPage(24).setSort('createdAt', 'desc').setColumns([
                {
                    name: 'Numer zamówienia',
                    key: 'orderId',
                    sortable: true,
                    searchable: true
                },
                {
                    name: 'Data utworzenia',
                    key: 'createdAt',
                    type: 'date',
                    sortable: true,
                    filter: 'range'
                },
                {
                    name: 'Konto zamawiającego',
                    key: 'createdBy.id',
                    type: 'select',
                    filter: '=',
                    values: getCreatedByFilterOptions(),
                    value: function (item) {
                        return item.createdBy.id ? item.createdBy.name : 'handlowiec MAX';
                    }
                },
                {
                    name: 'Suma',
                    key: 'netValue',
                    sortable: true,
                    value: function (item) {
                        return item.netValue.toFixed(2) + ' ' + item.currencyCode;
                    },
                    class: 'price'
                },
                {
                    name: 'Status',
                    key: 'status.label',
                    type: 'select',
                    filter: '=',
                    values: getStatusFilterOptions(),
                    sortable: true,
                    value: function (item) {
                        var result = item.status.label;

                        if (item.status.key === 'unpaid') {
                            result += ' <a ng-click="column.payForOrder(item)" class="button small">zapłać</a>';
                        }

                        return result;
                    },
                    payForOrder: function (item) {
                        $state.go('checkout.payment', {orderId: item.orderId});
                    }
                },
                {
                    name: 'Spedycja',
                    sortable: true,
                    searchable: true,
                    key: 'waybills',
                    value: function (item) {
                        return item.waybills && item.waybills.length ? item.waybills.map(function (value) {
                            return ShippingService.formatWaybillTrackingUrl(value);
                        }).join('<br/>') : '-';
                    }
                },
                {
                    name: 'Szczegóły',
                    class: 'actions',
                    value: function (item) {
                        return '<a class="preview" ng-click="column.previewOrder(item)" title="szczegóły"></a>';
                    },
                    previewOrder: function (item) {
                        vm.preview.open(item);
                    }
                }
            ]);

        vm.grid.loadPage(1);
    }
]);
