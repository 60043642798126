'use strict';

angular.module('B2B').factory('Category', function () {

        var Category = function (data) {
                this.id = parseInt(data.id);
                this.depth = data.depth;
                this.name = data.name;
                this.url = data.url;
                this.featured = data.featured;
                this.parent = null;
                this.children = [];
                this.resetState();
            },
            categoryTree = null;

        Category.prototype.setChildren = function (children) {
            if (angular.isArray(children) && children.length) {
                this.children = children;
                angular.forEach(this.children, function (child) {
                    child.parent = this;
                }.bind(this));
            }
            return this;
        };

        Category.prototype.hasChildren = function () {
            return this.children.length > 0;
        };

        Category.prototype.isLeaf = function () {
            return !this.hasChildren();
        };

        /**
         * Toggle category visibility
         */
        Category.prototype.toggle = function () {
            if (this.hasChildren()) {
                if (this.toggled) {
                    this.collapse();
                } else {
                    this.expand();
                }
            }
        };

        /**
         * Collapse category and all of its children
         */
        Category.prototype.collapse = function () {
            this.toggled = false;
            this.eachChild(function (child) {
                child.collapse();
            });
        };

        /**
         * Expand category and all of its ancestor
         */
        Category.prototype.expand = function () {
            if (this.hasChildren()) {
                this.toggled = true;
            }
            if (this.parent) {
                this.parent.expand();
            }
        };

        Category.prototype.highlight = function () {
            this.highlighted = true;
        };

        Category.prototype.unhighlight = function () {
            this.highlighted = false;
        };

        /**
         * Mark facet - show categories with products count
         *
         * @param {number} productCount
         */
        Category.prototype.bindFacet = function (productCount) {
            this.productCount = productCount;
            this.visible = true;
            this.eachAncestor(function (ancestor) {
                ancestor.productCount = ancestor.productCount ? ancestor.productCount + productCount : productCount;
                ancestor.visible = true;
            });
        };

        /**
         * Set state/flags to default
         */
        Category.prototype.resetState = function () {
            this.toggled = false;
            this.highlighted = false;
            this.current = false;
            this.visible = true;
            this.productCount = null;
        };

        /**
         * Iterate over each category child
         *
         * @param {function} callback
         */
        Category.prototype.eachChild = function (callback) {
            angular.forEach(this.children, function (child) {
                callback(child);
            });
        };

        /**
         * Iterate over each category siblings
         *
         * @param {function} callback
         */
        Category.prototype.eachSibling = function (callback) {
            angular.forEach(this.parent ? this.parent.children : categoryTree, function (sibling) {
                if (sibling !== this) {
                    callback(sibling);
                }
            }.bind(this));
        };

        /**
         * Iterate over each category ancestor
         *
         * @param {function} callback
         */
        Category.prototype.eachAncestor = function (callback) {
            if (this.parent) {
                callback(this.parent);
                this.parent.eachAncestor(callback);
            }
        };

        /**
         * Get root path to this category
         *
         * @param {string} separator
         * @returns {string}
         */
        Category.prototype.getPath = function (separator) {
            var pathNames = [this.name];
            this.eachAncestor(function (category) {
                pathNames.push(category.name);
            });
            return pathNames.reverse().join(separator ? separator : ' / ');
        };

        Category.each = function (tree, callback) {
            angular.forEach(tree, function (category) {
                callback(category);
                if (category.hasChildren()) {
                    Category.each(category.children, callback);
                }
            });
        };

        Category.find = function (tree, id) {
            var result = null;
            angular.forEach(tree, function (category) {
                if (category.id === id) {
                    result = category;
                } else if (category.hasChildren() && !result) {
                    result = this.find(category.children, id);
                }
            }.bind(this));
            return result;
        };

        Category.build = function (data) {
            var treeItem = new Category(data);
            if (data.children) {
                treeItem.setChildren(data.children.map(Category.build));
            }
            return treeItem;
        };

        Category.apiResposneTransformer = function (responseData) {
            if (angular.isArray(responseData)) {
                categoryTree = responseData.map(Category.build).filter(Boolean);
                return categoryTree;
            }
            return Category.build(responseData);
        };

        return Category;
    }
);
