'use strict';
angular.module('B2B').directive('maxDiscountBlock', [
    '$timeout',
    'mm.core.api.API',
    'CartService',
    'mm.core.auth.AuthService',
    function ($timeout,
              API,
              CartService,
              Auth) {

        return {
            restrict: 'E',
            templateUrl: '/views/cms/blocks/promotion/max_discount.html',
            replace: false,
            scope: {
                block: '='
            },
            link: function (scope) {

                var timer;

                /**
                 * Fetch promoted products and display.
                 */
                function loadPromotionsBlock() {
                    scope.loading = true;
                    API.get('products', {
                        itemsOnPage: 24,
                        filters: 'discount:max_discount'
                    }).then(function (result) {
                        scope.products = result.items;
                        if (scope.products.length) {
                            scope.selectProduct(0);
                            scope.block.addToCartEnabled = Auth.data.isLogged &&
                                Auth.data.user.hasAccess('add_to_cart');
                        }
                    }, function () {
                        scope.products = [];
                    }).finally(function () {
                        scope.loading = false;
                    });
                }

                scope.blockTitle = 'Rabaty na maxa';
                var now = new Date();
                var promotion_start = new Date(2018, 4, 22, 9, 0, 0, 0);
                var promotion_end = new Date(2018, 4, 28, 12, 30, 59, 0);

                function switchBlockTitle() {
                    if ((now - promotion_start) >= 0 && (now - promotion_end) <= 0) {
                        scope.blockTitle = 'Tydzień Samsonite';
                    } else {
                        scope.blockTitle = 'Rabaty na maxa';
                    }
                }

                /**
                 * Handle products carousel
                 */
                scope.selectProduct = function (num) {
                    $timeout.cancel(timer);
                    if (num < 0) {
                        num = scope.products.length - 1;
                    }
                    if (num > (scope.products.length - 1)) {
                        num = 0;
                    }
                    scope.current = num;
                    timer = $timeout(function () {
                        scope.showNext();
                    }, 3000);
                };

                scope.showNext = function () {
                    scope.selectProduct(scope.current + 1);
                };
                scope.showPrev = function () {
                    scope.selectProduct(scope.current - 1);
                };

                scope.addToCart = function () {
                    scope.cartLoading = true;
                    CartService.addToCart(scope.products[scope.current]).finally(function () {
                        scope.cartLoading = false;
                    });
                };

                scope.$on('mm.auth.logout', function () {
                    loadPromotionsBlock();
                });

                scope.$on('mm.auth.changed', function () {
                    loadPromotionsBlock();
                });

                scope.$on('currency.changed', function () {
                    loadPromotionsBlock();
                });

                scope.$on('$destroy', function () {
                    $timeout.cancel(timer);
                });

                loadPromotionsBlock();
                switchBlockTitle();
            }
        };
    }
]);
