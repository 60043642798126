/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ProducerService', [
    'mm.core.api.API',
    function (API) {

        /**
         * Fetch all producers data (ordered)
         *
         * @returns {Promise}
         */
        function getProducers() {
            return API.get('producers/all', null, { cache: true });
        };

        return {
            getProducers : getProducers
        };
    }
]);