'use strict';

angular.module('B2B').controller('ProductController', [
    '$scope',
    '$rootScope',
    '$state',
    'mm.core.api.API',
    'CategoryService',
    'CatalogBreadcrumbService',
    'ProducerFilterService',
    'AttributeFilterService',
    'PriceFilterService',
    'SearchService',
    'BasicFilterService',
    function ($scope,
              $rootScope,
              $state,
              API,
              CategoryService,
              CatalogBreadcrumbService,
              ProducerFilterService,
              AttributeFilterService,
              PriceFilterService,
              SearchService,
              BasicFilterService) {

        var productId = $state.params.productId,
            requestPromise;

        function loadProduct() {

            if (requestPromise) {
                requestPromise.abort();
            }

            $scope.loading = true;
            if (productId) {
                requestPromise = API.get('products/' + productId);
                requestPromise.then(function (product) {
                    $scope.product = product;
                    // Load category and set as current
                    CategoryService.find(product.category.id).then(function (category) {
                        $scope.breadcrumbs = CatalogBreadcrumbService.generate(category);
                        CategoryService.markAsCurrent(category);
                        $scope.loading = false;
                    });
                    $rootScope.$broadcast('product.visited', product);
                }, function (response) {
                    if (response.code === 404) {
                        $scope.notFound = true;
                    }
                    $scope.product = null;
                    $scope.loading = false;
                });
            }
        }

        $scope.$on('mm.auth.changed', loadProduct);
        $scope.$on('currency.changed', loadProduct);

        ProducerFilterService.loadFilter();
        AttributeFilterService.removeFilters();
        PriceFilterService.removeFilter();
        SearchService.clearQuery();
        BasicFilterService.clearSelection();
        loadProduct();

    }
]);