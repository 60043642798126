/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('HeaderFilter', function () {

    /**
     * @param {string} name
     * @constructor
     */
    var HeaderFilter = function (name, scope) {
        this.name = name;
        this.scope = scope;
        this.values = [];
    };

    /**
     * Refresh filter selected values list
     */
    HeaderFilter.prototype.refreshValues = function () {
        var hadValues = !!this.values.length;

        this.values.splice(0, this.values.length);

        this.bindValues();

        // Broadcast right event when values visibility changes
        if (!hadValues && this.values.length) {
            this.scope.$broadcast('headerFilter.values.appeared', this);
        } else if (hadValues && !this.values.length) {
            this.scope.$broadcast('headerFilter.values.gone', this);
        }
    };

    /**
     * All HeaderFilters should bind values array property with this method
     */
    HeaderFilter.prototype.bindValues = function () {};

    HeaderFilter.prototype.init = function () {
        this.refreshValues();
    };

    return HeaderFilter;
});