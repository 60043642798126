'use strict';

angular.module('B2B').component('catalogBreadcrumb', {
    bindings: {
        categories: '<'
    },
    template: '<nav>' +
    '<span>Jesteś w:</span>' +
    '<catalog-breadcrumb-list categories="$ctrl.categories"></catalog-breadcrumb-list>' +
    '</nav>'
});

angular.module('B2B').component('catalogBreadcrumbList', {
    bindings: {
        categories: '<'
    },
    template: '<ul>' +
    '   <li ng-repeat="category in ::$ctrl.categories" ng-class="{ toggle : category.hasChildren()}" ' +
    '       ng-style="{\'z-index\' : categories.length-$index}">' +
    '       <a ng-if="!category.hasChildren()" ui-sref="category({ category: category.id })">' +
    '           {{:: category.name}}' +
    '       </a>' +
    '       <span ng-if="category.hasChildren()">{{:: category.name}}</span>' +
    '       <catalog-breadcrumb-list ng-if="category.hasChildren()" categories="category.children">' +
    '       </catalog-breadcrumb-list>' +
    '   </li>' +
    '</ul>'
});