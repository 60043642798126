'use strict';

angular.module('B2B').controller('CatalogNavController', [
    '$scope', '$timeout', 'CategoryService', 'mm.core.auth.AuthService',
    function ($scope, $timeout, CategoryService, AuthService) {

        var vm = this,
            timer = null,
            preventQueryResetting = false;

        /**
         * Reload catalog category tree
         */
        function loadCategoryTree() {
            CategoryService.getCategoryTree(true).then(function (categories) {
                vm.categories = categories;
            });
        }

        /**
         * Mark and toggle tree items as search results
         *
         * @param {object} item Category tree item
         * @param {array} parents
         * @param {boolean} preventHiding Prevent hiding no matching categories
         * @param {string} query Search query
         */
        function matchCategory(item, query, preventHiding) {

            preventHiding = preventHiding || false;

            if (item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) { //Mark as result
                if (item.parent) {
                    item.parent.expand();
                }
                item.highlight();
                item.visible = true;
                preventHiding = true;
                item.eachAncestor(function (ancestorItem) {
                    ancestorItem.visible = true;
                });
            } else if (!preventHiding) {
                item.visible = false;
            }
            // Recursively children searching
            if (item.hasChildren()) {
                item.eachChild(function (childItem) {
                    matchCategory(childItem, query, preventHiding);
                });
            }
        }

        /**
         * Perform category searching
         */
        function search() {
            $timeout.cancel(timer);
            preventQueryResetting = true;
            CategoryService.data.treeLoading = true;
            timer = $timeout(function () {
                var query = vm.searching.model;
                CategoryService.resetCategoryTreeState().then(function () {
                    CategoryService.keepCategoryTreeState();
                    if (query.length) {
                        angular.forEach(vm.categories, function (item) {
                            matchCategory(item, query, false);
                        });
                    }
                    else {
                        CategoryService.resetCategoryTreeState();
                    }
                    CategoryService.data.treeLoading = false;
                    preventQueryResetting = false;
                });
            }, 1000);
        }

        vm.searching = {
            model: '',
            search: search,
            reset: function () {
                this.model = '';
                $timeout.cancel(timer);
                CategoryService.resetCategoryTreeState();
            }
        };

        vm.data = CategoryService.data;


        AuthService.getUser().finally(function () {
            loadCategoryTree();

            $scope.$on('mm.auth.changed', loadCategoryTree);
            $scope.$on('mm.auth.logout', loadCategoryTree);
        });

        // Clear query model after category tree reset
        $scope.$on('categoryTree.stateReset', function () {
            if (!preventQueryResetting) {
                vm.searching.model = '';
            }
        });

    }
]);