'use strict';

angular.module('B2B').directive('searchBar', [
    '$document', '$state', '$timeout', 'mm.core.api.API', 'CategoryService', 'ProducerService', 'SearchService',
    function ($document, $state, $timeout, API, CategoryService, ProducerService, SearchService) {
        return {
            restrict: 'E',
            templateUrl: '/views/catalog/search/search-bar.html',
            replace: true,
            scope: {},
            link: function (scope, element, attrs) {

                var suggestions = (function () {

                    var timer,
                        promise,
                        suggestions = {
                            data: {
                                loading: false,
                                shown: false,
                                query: null,
                                hasResults: false,
                                popularProducts: [],
                                popularCategories: []
                            },
                            load: loadResults,
                            reset: function () {
                                this.data.loading = false;
                                this.data.shown = false;
                                this.data.query = null;
                                this.data.hasResults = false;
                                this.data.popularProducts = [];
                                this.data.popularCategories = [];
                            }
                        };


                    function parseCategories(categoryIds, categories) {
                        angular.forEach(categoryIds, function (categoryId) {
                            CategoryService.find(categoryId).then(function (category) {
                                categories.push(category);
                            });
                        });
                    }

                    /**
                     * Fetch suggestions and bind to model
                     *
                     * @param {string} query
                     */
                    function loadResults(query) {
                        $timeout.cancel(timer);
                        if (promise) {
                            promise.abort();
                        }
                        timer = $timeout(function () {
                            suggestions.reset();
                            suggestions.data.shown = true;
                            suggestions.data.loading = true;
                            suggestions.data.query = query;
                            promise = API.get('products/suggestions/' + query);
                            promise.then(function (results) {
                                if (results.popularProducts.length) {
                                    suggestions.data.hasResults = true;
                                }
                                suggestions.data.popularProducts = results.popularProducts;
                                parseCategories(results.popularCategoryIds, suggestions.data.popularCategories);

                                suggestions.data.loading = false;
                            }, function () {
                                suggestions.data.loading = false;
                            });
                        }, 400);
                    }

                    return suggestions;
                })();

                scope.query = {
                    model: SearchService.getQuery(),
                    error: null,
                    /**
                     * Perform suggestions search based on query model
                     */
                    onChange: function () {
                        if (this.model && this.model.length > 1) {
                            this.error = null;
                            suggestions.load(this.model);
                        } else {
                            suggestions.reset();
                        }
                    }
                };

                scope.element = {
                    expanded: false,
                    expand: function () {
                        this.expanded = true;
                    },
                    fold: function () {
                        this.expanded = false;
                    }
                };
                scope.suggestions = suggestions.data;


                /**
                 * Perform search (redirect to search state)
                 *
                 * @param {boolean} [reset]
                 */
                scope.search = function (reset) {
                    // Reset all state params
                    var params = {
                        query: null,
                        category: null,
                        page: null,
                        sort: null,
                        producer: null,
                        attrs: null,
                        price: null,
                        warehouse: null,
                        promotional: null
                    };
                    if (reset) {
                        scope.query.model = null;
                        scope.producerId = null;
                        scope.filterPromotions = null;

                        $state.go('search', params);
                    } else if (scope.query.model && scope.query.model.length >= 2) {
                        params.query = scope.query.model;
                        if (scope.producerId) {
                            params.producer = scope.producerId;
                        }
                        if (scope.filterPromotions) {
                            params.promotional = true;
                        }
                        $state.go('search', params);
                    } else {
                        scope.query.error = 'Szukana fraza musi mieć przynajmniej 2 znaki.';
                    }
                };

                // Load all producers
                ProducerService.getProducers().then(function (producers) {
                    scope.producers = producers;
                });

                // Fold search bar after click outside the bar
                $document.bind('click', function (event) {
                    if (!element[0].contains(event.target)) {
                        $timeout(function () {
                            scope.element.fold();
                        });
                    }
                });

                // Remove focus on search bar after states change
                scope.$on('$stateChangeSuccess', function () {
                    scope.element.fold();
                    scope.searchField[0].blur();
                });

                scope.$on('search.query.changed', function (event, query) {
                    scope.query.model = query;
                });
            },
            controller: function ($scope) {
                this.bindSearchFiled = function (searchField) {
                    $scope.searchField = searchField;
                }
            }
        };

    }
]);

angular.module('B2B').directive('searchBarField', function () {
    return {
        restrict: 'A',
        require: '^searchBar',
        link: function (scope, element, attrs, searchBarCtrl) {
            searchBarCtrl.bindSearchFiled(element);
        }
    };
});