'use strict';

angular.module('B2B').controller('CheckoutCartStepController', [
    '$timeout',
    '$state',
    'mm.core.auth.AuthService',
    'CheckoutService',
    'CartService',
    'CartViewHelper',
    'CartOfferService',
    function ($timeout,
              $state,
              Auth,
              CheckoutService,
              CartService,
              CartViewHelper,
              CartOfferService) {

        this.data = CartService.data;
        this.removeCartProduct = CartService.removeCartProduct;
        this.removeCart = CartService.removeCart;
        this.helper = CartViewHelper.instance();
        this.offer = CartOfferService;

        /**
         * Return cart name without phrase duplication
         *
         * @param {Cart} cart
         * @returns {string}
         */
        this.getCartTitle = function (cart) {
            var title = (Auth.access('export_customer') ? 'OFERTA ' : 'KOSZYK ') + cart.label;
            if (cart.name !== title) {
                title += ' "' + cart.name + '"';
            }
            return title;
        };

        /**
         * Trigger quantity updating with 600ms delay
         */
        this.onItemQuantityChanged = (function () {

            var timer,
                changedItem;

            return function (cart, cartItem) {
                if (cartItem === changedItem) {
                    $timeout.cancel(timer);
                }
                changedItem = cartItem;
                timer = $timeout(function () {
                    CartService.updateCartProductQuantity(cart, cartItem, cartItem.requestedQuantity);
                }, 600);
            };
        })();

        this.isAutoConfirmedPromotion = CartService.isAutoConfirmedPromotion;
        this.isPromotionActive = CartService.isPromotionActive;
        this.toggleProductPromotions = CartService.toggleProductPromotions;

        this.updateProductQuantity = CartService.updateCartProductQuantity;

        /**
         * Set given cart as checkout cart and redirect to shipping state
         *
         * @param {Cart} cart
         */
        this.selectCart = function (cart) {
            CheckoutService.proceedCart(cart);
        };

        // Toggle right cart on init
        if (CheckoutService.data.cart) {
            this.helper.toggle(CheckoutService.data.cart);
        } else if (CartService.data.currentCart) {
            this.helper.toggle(CartService.data.currentCart);
        }

    }
]);
