/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ProductGrid', [
    '$q',
    '$location',
    '$state',
    '$timeout',
    '$sessionStorage',
    'mm.core.api.API',
    'Grid',
    function ($q,
              $location,
              $state,
              $timeout,
              $sessionStorage,
              API,
              Grid) {

        /**
         * @param {object} grid
         * @constructor
         */
        var ProductGrid = function (opts) {

            var defaultOpts = {
                itemsOnPage: 24,
                categoryId: null,
                sort: {
                    column: null,
                    direction: null
                },
                facetingEnabled: true,
                stickyHeaderEnabled: true,
                categoryTreeFacetingEnabled: false,
                sessionDataEnabled: false
            };
            angular.extend(defaultOpts, opts);

            Grid.call(this, defaultOpts);
            this.pages = [];

            this.setResource('products').setItemsOnPage(this.opts.itemsOnPage)
                .setSort(this.opts.sort.column, this.opts.sort.direction);

            if (this.opts.sessionDataEnabled) {
                this.loadSessionData();
            }

            this.onPageChangeCallback = null;
            this.loading = false;
            this.itemParser = null;
            this.innerQuery = null;

            this.attributes = [];

            this.suggestions = null;
            this.facet = {
                categories: null,
                producers: null,
                attributes: null,
                price: null
            };
            this.facetRequest = null;
            this.delayTimer = null;
        };

        ProductGrid.prototype = Object.create(Grid.prototype);
        ProductGrid.prototype.constructor = ProductGrid;
        ProductGrid.prototype.parent = Grid.prototype;


        /**
         * Save grid data settings in SessionStorage
         */
        ProductGrid.prototype.saveSessionData = function () {
            $sessionStorage.productGridData = {
                sorting: this.data.sorting,
                pagination: {
                    itemsOnPage: this.data.pagination.itemsOnPage
                }
            };
        };

        /**
         * Load grid data settings from SessionStorage
         */
        ProductGrid.prototype.loadSessionData = function () {
            var data = $sessionStorage.productGridData,
                itemsOnPage;

            if (!data) {
                return;
            }
            if (itemsOnPage = _.get(data, 'pagination.itemsOnPage')) {
                this.setItemsOnPage(itemsOnPage);
            }
            if (data.sorting) {
                this.setSort(data.sorting.column, data.sorting.direction);
            }
        };


        /**
         * Set category and clear all dependent filters
         *
         * @param {array} categoryIds
         * @returns {ProductGrid}
         */
        ProductGrid.prototype.setCategoryIds = function (categoryIds) {
            this.setFilter('category.id', categoryIds, 'in');
            return this;
        };

        /**
         * Returns category id there's only one defined category filter.
         *
         * @returns {number|null}
         */
        ProductGrid.prototype.getCategoryId = function () {
            var categoryIds = this.getCategoryIds();
            if (categoryIds && categoryIds.length === 1) {
                return categoryIds[0];
            }
            return null;
        };

        /**
         * @returns {array|null}
         */
        ProductGrid.prototype.getCategoryIds = function () {
            return this.filters['category.id'] ? this.filters['category.id'].value : null;
        };

        /**
         * Set producers as a grid filter
         *
         * @param {array} values Producers ids
         * @returns {ProductGrid}
         */
        ProductGrid.prototype.setProducers = function (values) {
            this.setFilter('producer.id', values, 'in');
            return this;
        };

        /**
         * Fetch producers id from grid filter
         *
         * @returns {Array} Producers ids
         */
        ProductGrid.prototype.getProducers = function () {
            return this.filters['producer.id'] ? this.filters['producer.id'].value.map(function (value) {
                return parseInt(value);
            }) : [];
        };

        /**
         * Set price grid filter
         *
         * @param {Array} range Min and max price range
         * @returns {ProductGrid}
         */
        ProductGrid.prototype.setPriceFilter = function (range) {
            this.setFilter('price', range, 'range');
            return this;
        };

        /**
         * Fetch price grid filter
         *
         * @returns {Array} Selected price range
         */
        ProductGrid.prototype.getPriceFilter = function () {
            return this.filters.price ? this.filters.price.value : null;
        };

        /**
         * @param {number} attributeId
         * @param {array} values Attribue values ids
         * @returns {ProductGrid}
         */
        ProductGrid.prototype.setAttribute = function (attributeId, values) {
            var attribute;
            if (values && values.length) {
                if (attribute = this.getAttribute(attributeId)) {
                    attribute.values = values;
                    return this;
                }
                this.attributes.push({id: attributeId, values: values});
                return this;
            }
            return this.removeAttribute(attributeId);
        };

        /**
         * @param {number} attributeId
         */
        ProductGrid.prototype.removeAttribute = function (attributeId) {
            var index = this.attributes.indexOf(this.getAttribute(attributeId));
            if (index !== -1) {
                this.attributes.splice(index, 1);
            }
        };

        /**
         * @returns {object}
         */
        ProductGrid.prototype.getAttribute = function (attributeId) {
            return this.attributes.filter(function (attribute) {
                return attribute.id === attributeId;
            })[0];
        };

        /**
         * @returns {Array}
         */
        ProductGrid.prototype.getAttributes = function () {
            return this.attributes;
        };

        /**
         * Register onPageChange callback
         *
         * @param {function} callback
         * @returns {ProductGrid}
         */
        ProductGrid.prototype.onPageChange = function (callback) {
            if (typeof callback === 'function') {
                this.onPageChangeCallback = callback;
            }
            return this;
        };

        /**
         * Determine if given page can be loaded
         *
         * @param {number} pageNo
         * @returns {boolean}
         */
        ProductGrid.prototype.canLoadPage = function (pageNo) {
            return pageNo > 0 && (!this.data.pagination.pages || this.data.pagination.pages >= pageNo);
        };

        /**
         * Search page from downloaded pages
         *
         * @param {number} pageNo
         * @return {object|null}
         */
        ProductGrid.prototype.findPage = function (pageNo) {
            for (var i in this.pages) {
                if (this.pages[i].number === pageNo) {
                    return this.pages[i];
                }
            }
            return null;
        };

        /**
         * Load given page from core grid resource and stores in pages array
         *
         * @param {Object} opts
         * @param {number} pageNo
         * @return {Promise}
         */
        ProductGrid.prototype.loadPage = function (pageNo, opts) {
            pageNo = parseInt(pageNo);
            var page = {loading: true, number: pageNo},
                currentPage = this.data.pagination.page,
                defer = $q.defer(),
                opts = angular.extend({
                    keepPages: false
                }, opts || {});

            if (this.opts.sessionDataEnabled) {
                this.saveSessionData();
            }

            if (this.canLoadPage(pageNo)) {
                if (!opts.keepPages) {
                    this.resetPages();
                }
                if (!this.findPage(pageNo)) {
                    this.loading = true;
                    this.pages.push(page);

                    this.setParam('attributes', this.attributes.map(function (attribute) {
                        return attribute.id + ':' + attribute.values.join(';');
                    }).join());

                    if (this.innerQuery && this.innerQuery.length > 1) {
                        this.setParam('innerQuery', this.innerQuery);
                    } else {
                        this.clearParam('innerQuery');
                    }

                    return this.parent.loadPage.call(this, pageNo).then(function (result) {
                        // Prevent overriding current page by core grid
                        if (currentPage && opts.keepPages) {
                            this.data.pagination.page = currentPage;
                        }
                        page.items = this.data.items;
                        if (typeof this.itemParser === 'function') {
                            angular.forEach(page.items, this.itemParser);
                        }
                        page.loading = false;
                        this.loading = false;
                        this.suggestions = result.suggestions;
                    }.bind(this), function () {
                        return $q.reject();
                    });
                }
            }
            defer.reject();
            return defer.promise;
        };

        /**
         * Load next page
         */
        ProductGrid.prototype.loadNextPage = function () {
            if (this.data.pagination.pages) {
                this.loadPage(this.data.pagination.page + 1, {
                    keepPages: true
                });
            }
        };

        /**
         * Reset pages
         */
        ProductGrid.prototype.resetPages = function () {
            this.pages.splice(0, this.pages.length);
        };

        /**
         * Set given page number as current
         * @returns {ProductGrid}
         */
        ProductGrid.prototype.setCurrentPage = function (pageNo) {
            pageNo = parseInt(pageNo);
            if (this.data.pagination.page !== pageNo) {
                this.data.pagination.page = pageNo;
                if (this.onPageChangeCallback) {
                    this.onPageChangeCallback(this.data);
                }
                if (this.opts.stateParamsEnabled) {
                    this.updateState();
                }
            }
            return this;
        };

        /**
         * Change sorting and reload
         *
         * @param {string} column
         * @param {string} direction
         * @returns {*}
         */
        ProductGrid.prototype.changeSort = function (column, direction) {
            return this.setSort(column, direction).loadPage(1);
        };

        /**
         * @param {number} [page]
         * @returns {Promise}
         */
        ProductGrid.prototype.search = function (page) {
            return this.loadPage(page ? page : 1);
        };

        /**
         * Perform search after small delay (allows to break execution with another call).
         *
         * @param {number} [page]
         * @returns {Promise}
         */
        ProductGrid.prototype.delaySearch = function (page) {
            $timeout.cancel(this.delayTimer);

            this.delayTimer = $timeout(function () {
                return this.loadPage(page ? page : 1);
            }.bind(this), 300);

            return this.delayTimer;
        };

        /**
         * @param {boolean} attachFacets
         * @param {boolean} attachCategoryFacets
         * @returns {Promise}
         */
        ProductGrid.prototype.loadFacets = function (attachFacets, attachCategoryFacets) {
            if (this.facetRequest) {
                this.facetRequest.abort();
            }

            this.facetRequest = API.get(
                this.resource,
                angular.extend({
                    attachFacets: attachFacets,
                    attachCategoryFacets: attachCategoryFacets
                }, this.buildRequestParams())
            );

            return this.facetRequest.then(function (result) {
                this.facet.producers = result.producers;
                this.facet.attributes = result.attributes;
                this.facet.price = result.price;
                this.facet.categories = result.categories;
            }.bind(this));
        }

        /**
         * Load grid data from state (query) params.
         *
         * @returns {ProductGrid}
         */
        ProductGrid.prototype.loadState = function () {
            var sortParams = null;

            if ($state.params.sort) {
                sortParams = $state.params.sort.split(':');
                this.setSort(sortParams[0], sortParams[1]);
            }
            if ($state.params.onPage) {
                this.setItemsOnPage(parseInt($state.params.onPage));
            }
            if ($state.params.category) {
                this.setCategoryIds($state.params.category.split(','));
            }
            if ($state.params.producer) {
                this.setProducers($state.params.producer.split(',').map(function (value) {
                    return parseInt(value);
                }));
            }
            if ($state.params.attrs) {
                (function (grid) {
                    var attributes = $state.params.attrs.split(',');
                    angular.forEach(attributes, function (item) {
                        var attributeData = item.split(':');
                        grid.setAttribute(parseInt(attributeData[0]), attributeData[1].split(';').map(function (value) {
                            return value === '' ? null : parseFloat(value);
                        }));
                    });
                })(this);
            }
            if ($state.params.price) {
                this.setPriceFilter($state.params.price.split(','));
            }
            if ($state.params.query) {
                this.setQuery(decodeURIComponent($state.params.query));
            }
            if ($state.params.innerQuery) {
                this.innerQuery = decodeURIComponent($state.params.innerQuery);
            }
            if ($state.params.warehouse) {
                this.setFilter('warehouse', $state.params.warehouse);
            }
            if ($state.params.promotional) {
                this.setFilter('promotional', true);
            }
            if ($state.params.availableOnly) {
                this.setFilter('availableOnly', true);
            }
            return this;
        };

        /**
         * Build state params object.
         *
         * @param {Object} [defaults] Default properties to override state params.
         * @returns {Object}
         */
        ProductGrid.prototype.getStateParams = function (defaults) {
            var params = {},
                producers = this.getProducers(),
                categoryIds = this.getCategoryIds(),
                attributes = this.getAttributes(),
                priceRange = this.getPriceFilter();

            if (categoryIds && categoryIds.length === 1 && parseInt(categoryIds[0]) !== this.opts.categoryId) {
                params.category = categoryIds[0];
            } else if (categoryIds && categoryIds.length > 1) {
                params.category = categoryIds.join();
            }
            if (this.data.pagination.page > 1) {
                params.page = this.data.pagination.page;
            }
            if (this.data.pagination.itemsOnPage !== this.opts.itemsOnPage) {
                params.onPage = this.data.pagination.itemsOnPage;
            }
            if (this.data.sorting && (
                    this.data.sorting.column !== this.opts.sort.column ||
                    this.data.sorting.direction !== this.opts.sort.direction
                )) {
                params.sort = this.data.sorting.column + ':' + this.data.sorting.direction;
            } else if (!this.data.sorting && this.opts.sort.column) {
                params.sort = '';
            }
            if (producers.length) {
                params.producer = producers.join();
            }
            if (attributes.length) {
                params.attrs = attributes.map(function (attribute) {
                    return attribute.id + ':' + attribute.values.join(';');
                }).join();
            }
            if (priceRange && priceRange.length) {
                params.price = priceRange.join();
            }
            params.query = this.query;
            if (this.innerQuery) {
                params.innerQuery = this.innerQuery;
            }
            if (this.filters['warehouse']) {
                params.warehouse = this.filters['warehouse'].value;
            }
            if (this.filters['promotional']) {
                params.promotional = this.filters['promotional'].value;
            }
            if (this.filters['availableOnly']) {
                params.availableOnly = this.filters['availableOnly'].value;
            }

            if (defaults) {
                return _.merge(params, defaults);
            }

            return params;
        };

        return ProductGrid;
    }
]);
