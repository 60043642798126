/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('mmFieldCheckbox', function () {

    return {
        restrict: 'E',
        template: '<div class="field checkbox">'+
                '<input id="{{:: id }}" type="checkbox" ng-disabled="isDisabled" ng-model="command.fields[bind]" />'+
                '<label for="{{:: id }}">{{:: label }}</label>'+
            '</div>',
        replace: true,
        scope: {
            bind: '@',
            label: '@',
            disabled: '@'
        },
        require: '^mmCommand',
        link: function (scope, element, attrs, commandCtrl) {
            scope.id = commandCtrl.getId() + '-' + attrs.bind;
            scope.command = commandCtrl.getCommand();
            scope.isDisabled = attrs.disabled === 'true';
        }
    };

});
