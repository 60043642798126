/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ShippingService', [function () {

    return {
        /**
         * Format string as html link to waybill tracking site.
         *
         * @param {string} waybill
         * @param {string} [deliveryMethod]
         * @returns {string}
         */
        formatWaybillTrackingUrl: function (waybill) {
            switch (waybill.length) {
                case 11 :
                case 9 :
                    return '<a href="http://www.dhl.com.pl/sledzenieprzesylkikrajowej/szukaj.aspx?m=0&sn=' +
                        waybill + '" target="_blank">' + waybill + '</a>';
                case 18 :
                    return '<a href="http://wwwapps.ups.com/WebTracking/track?HTMLVersion=5.0&loc=pl_PL&' +
                        'trackNums=' + waybill + '&track.y=10&Requester=TRK_MOD&showMultipiece=N&' +
                        'detailNumber=undefined&WBPM_lid=homepage%2Fct1.html_pnl_trk" target="_blank">' +
                        waybill + '</a>';
                case 12 :
                    return '<a href="https://gls-group.eu/PL/pl/sledzenie-paczek?match=' + waybill + '" target="_blank">' +
                        waybill + '</a>';
                case 10 :
                case 17 :
                    return '<a href="https://was.schenker.nu/ctts-a/com.dcs.servicebroker.http.HttpXSLTServlet?request.service=CTTSTYPEA&request.method=search&clientid=&language=pl&country=PL&reference_type=*DWB&reference_number=' + waybill.slice(0,10) + '" target="_blank">' +
                        waybill + '</a>';
                case 14 :
                    return '<a href="https://tracktrace.dpd.com.pl/parcelDetails?p1=' + waybill + '" target="_blank">' +
                        waybill + '</a>';
                case 7 :
                    return '<a href="http://tba.pl/tracking/index/?numer=' + waybill + '" target="_blank">' +
                        waybill + '</a>';
                default :
                    return waybill;
            }
        }
    };

}]);