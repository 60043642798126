'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('CatalogNavFiltersController', [
    '$scope',
    'CatalogFiltersService',
    'ProducerFilterService',
    'AttributeFilterService',
    'PriceFilterService',
    'BasicFilterService',
    function (
        $scope,
        CatalogFiltersService,
        ProducerFilterService,
        AttributeFilterService,
        PriceFilterService,
        BasicFilterService
    ) {
        this.element = CatalogFiltersService.bar;
        this.producerFilterData = ProducerFilterService.data;
        this.attributeFilterData = AttributeFilterService.data;
        this.priceFilterData = PriceFilterService.data;
        this.basicFilterData = BasicFilterService.data;

        this.clearFilters = function () {
            ProducerFilterService.clearSelection();
            AttributeFilterService.clearSelection();
            PriceFilterService.clearSelection();
            BasicFilterService.clearSelection();
        };

        // Fold filter on no-filter states
        $scope.$on('$stateChangeSuccess', function (event, toState) {
            if (toState.catalogMode && !toState.filtersEnabled) {
                CatalogFiltersService.bar.toggled = false;
            }
        });
    }
]);