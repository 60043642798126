/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('trimWithTip', function () {

    var MAX_LENGTH = 130;

    return {
        restrict: 'E',
        template: '<span class="trim-with-tip" ng-mouseover="showExtended()" ng-mouseleave="hideExtended()">' +
        '{{ trimmed }}<span ng-if="extended" ng-show="extendedShown" class="extended">{{ extended }}</span>' +
        '</span>',
        replace: true,
        scope: {
            string: '=',
            length: '@'
        },
        link: function (scope, element, attrs) {

            var maxLength = scope.length ? scope.length : MAX_LENGTH;

            function refreshTrimming() {
                if (scope.string) {
                    if (scope.string.length > maxLength) {
                        scope.trimmed = scope.string.substring(0, maxLength) + '...';
                        scope.extended = scope.string;
                    } else {
                        scope.trimmed = scope.string;
                        scope.extended = null;
                    }
                } else {
                    scope.trimmed = '';
                    scope.extended = null;
                }
            }

            scope.$watch('string', function () {
                refreshTrimming();
            });

            scope.showExtended = function () {
                scope.extendedShown = true;
            };

            scope.hideExtended = function () {
                scope.extendedShown = false;
            };
        }
    };

});