/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('googleTranslate', [
    'TranslationService',
    function (TranslationService) {

        return {
            restrict: 'E',
            templateUrl: '/views/scaffolding/google-translate.html',
            replace: false,
            scope: {},
            link: function (scope, element) {
                TranslationService.initGoogleTranslate('google-translate', element);
            }
        };

    }
]);