'use strict';

angular.module('B2B').controller('CmsPageController', [
    '$scope', '$state', '$sce', 'mm.core.api.API',
    function ($scope, $state, $sce, API) {

        var vm = this;

        if (!$state.params.slug) {
            $state.go('home');
        }

        vm.loading = true;

        API.get('cms/static-pages/s,' + $state.params.slug).then(function (page) {
            vm.page = page;
            vm.loading = false
            vm.page.content = $sce.trustAsHtml(page.content);
        }, function(){
            $state.go('home'); // 404
        });
    }
]);
