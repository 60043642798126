'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('CheckoutSummaryStepController', [
    '$state', 'mm.core.api.API', 'mm.core.dom.NoticeService', 'CheckoutService',
    function ($state, API, NoticeService, CheckoutService) {

        var vm = this;

        vm.orderBuilder = CheckoutService.order;
        vm.loading = true;

        API.post('carts/' + CheckoutService.data.cart.id + '/order-preview', {
            deliveryAddressId: CheckoutService.data.deliveryAddress.id,
            payment: CheckoutService.data.payment
        }).then(function (order) {
            vm.order = order;
            vm.loading = false;
        }, function (response) {
            if (response.errors && response.errors.message) {
                NoticeService.error(response.errors.message);
            } else {
                NoticeService.error('Wystąpił błąd podczas przetwarzania zamówienia.');
            }
            $state.go('checkout.cart');
        });

    }
]);
