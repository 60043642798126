'use strict';

angular.module('B2B').controller('ContactController', [
    'mm.core.api.API',
    function (API) {

        var vm = this;

        /**
         * Select given department and load extended data.
         *
         * @param {object} department
         */
        vm.select = function (department) {
            vm.currentDepartmentId = department.id;
            vm.currentDepartments = [department];
            vm.departmentLoading = true;

            API.get('max/departments/' + department.id).then(function (departments) {
                vm.currentDepartments = departments;
                vm.departmentLoading = false;
            });
        };

        // Fetch available departments
        vm.loading = true;
        API.get('max/departments').then(function(data){
            vm.departments = data;
            vm.loading = false;

            if (vm.departments.length) {
                vm.select(vm.departments[0]);
            }
        });

    }
]);
