/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('BooleanFilter', ['$rootScope', function ($rootScope) {

    var BooleanFilter = function (data) {
        this.id = data.id;
        this.type = 'boolean';
        this.name = data.name;
        this.checked = false;
        this.hidden = !!data.hidden;
    };

    /**
     * Change filter "checked" flag.
     *
     * @param {boolean} checked
     * @returns {BooleanFilter}
     */
    BooleanFilter.prototype.setChecked = function (checked) {
        this.checked = checked;
        this.triggerChange();
        return this;
    };

    /**
     * Clear filter selections
     *
     * @returns {BooleanFilter}
     */
    BooleanFilter.prototype.clearSelection = function () {
        this.setChecked(false);
    };

    /**
     * Call filter listener with "checked" flag
     */
    BooleanFilter.prototype.triggerChange = function () {
        $rootScope.$broadcast(this.id + '.filter.changed', this.checked);
    };

    return BooleanFilter;
}]);

angular.module('B2B').directive('booleanFilter', function () {
    return {
        restrict: 'E',
        templateUrl: '/views/catalog/filter/boolean-filter.html',
        replace: true,
        scope: {
            filter: '='
        },
        link: function (scope) {
            scope.onChange = function(){
                scope.filter.triggerChange();
            };
        }
    };
});