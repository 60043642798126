/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('gridPagination', function () {

    return {
        restrict: 'E',
        templateUrl: '/views/scaffolding/grid/pagination.html',
        replace: false,
        scope: {
            grid: '='
        }
    };

});