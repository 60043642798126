'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('B2B').directive('toggledContent', function () {
    return {
        restrict: 'E',
        templateUrl: '/views/core/toggled-content.html',
        replace: true,
        transclude: true,
        scope: {
            header: '@'
        },
        link: function (scope) {

            /**
             * Toggles visibility of content data.
             */
            scope.toggle = function () {
                scope.toggled = !scope.toggled;
            };
        }
    };
});