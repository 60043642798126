/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('accessFieldset', ['mm.core.auth.AccessService', function (AccessService) {

    return {
        restrict: 'E',
        template: '<fieldset class="access">' +
            '<legend>Uprawnienia</legend>' +
            '<div class="field checkbox" ng-repeat="permission in permissions">' +
                '<input id="{{:: commandId }}-access-{{:: permission.id }}" type="checkbox" ' +
                    'ng-change="toggleDependentPermissions(permission)" ' +
                    'ng-model="command.fields.access[permission.id]" ' +
                    'ng-disabled="disabledPermissions[permission.id].length" />' +
                '<label for="{{:: commandId }}-access-{{:: permission.id }}">{{:: permission.name }}</label>' +
                '<mm-tooltip ng-if="permission.description">{{:: permission.description }}</mm-tooltip>' +
            '</div>' +
            '<span class="error" ng-repeat="errorMsg in command.errors.access">{{:: errorMsg }}</span>' +
        '</fieldset>',
        replace: true,
        scope: true,
        require: '^mmCommand',
        link: function (scope, element, attrs, commandCtrl) {
            scope.commandId = commandCtrl.getId();
            scope.command = commandCtrl.getCommand();
            scope.disabledPermissions = {};

            /**
             * @param {string} permissionId
             * @param {string} overidingPermissionId
             */
            function addDisabledPermission(permissionId, overidingPermissionId) {
                if (! scope.disabledPermissions[permissionId]) {
                    scope.disabledPermissions[permissionId] = [];
                }
                if (scope.disabledPermissions[permissionId].indexOf(overidingPermissionId) === -1) {
                    scope.disabledPermissions[permissionId].push(overidingPermissionId);
                }
            }

            /**
             * @param {string} permissionId
             * @param {string} overidingPermissionId
             */
            function removeDisabledPermission(permissionId, overidingPermissionId) {
                if (! scope.disabledPermissions[permissionId]) {
                    return false;
                }
                if (scope.disabledPermissions[permissionId].indexOf(overidingPermissionId) !== -1) {
                    scope.disabledPermissions[permissionId].splice(
                        scope.disabledPermissions[permissionId].indexOf(overidingPermissionId),
                        1
                    );
                }
            }

            /**
             * @var {object} Stores all original permission values
             */
            var originalAccess = angular.copy(scope.command.fields.access),
                overriddenPermissions = {};

            /**
             * Iterate overs each dependent permissions
             *
             * @param {object} permission
             * @param {function} callback
             */
            function eachOverridenPermissions(permission, callback) {
                angular.forEach(overriddenPermissions, function (overriddenPermission) {
                    angular.forEach(overriddenPermission.grantOverrides, function (permissionId) {
                        if (permissionId === permission.id) {
                            callback(overriddenPermission);
                        }
                    });
                });
            }

            // Load app permissions and assign dependent permissions object as key=id
            angular.forEach(scope.permissions, function(permission){
                if (permission.grantOverrides && permission.grantOverrides.length) {
                    overriddenPermissions[permission.id] = permission;
                }
            });

            /**
             * Auto toggling checkboxes (and disabling) on depepended permissions
             *
             * @param {object} permission
             */
            scope.toggleDependentPermissions = function (permission) {

                if (scope.command.fields.access[permission.id]) { //set to true
                    eachOverridenPermissions(permission, function(overriddenPermission){
                        addDisabledPermission(overriddenPermission.id, permission.id);
                        scope.command.fields.access[overriddenPermission.id] = true;
                        scope.toggleDependentPermissions(overriddenPermission);
                    });
                } else {
                    eachOverridenPermissions(permission, function(overriddenPermission){
                        // Uncheck permissions if they were not original checked
                        if (!originalAccess || !originalAccess[overriddenPermission.id]) {
                            scope.command.fields.access[overriddenPermission.id] = false;
                            scope.toggleDependentPermissions(overriddenPermission);
                        }
                        removeDisabledPermission(overriddenPermission.id, permission.id);
                    });
                }
            };

            // Bind default access data
            scope.command.fields.access = scope.command.fields.access || {};
            angular.forEach(scope.permissions, function(permission){
                if (typeof scope.command.fields.access[permission.id] === 'undefined') {
                    scope.command.fields.access[permission.id] = false;
                }
                scope.toggleDependentPermissions(permission);
            });
        }
    };

}]);