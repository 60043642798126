/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('SelectionFilter', function () {

    var SelectionFilter = function (data) {
        this.id = data.id;
        this.type = 'selection';
        this.name = data.name;
        this.values = data.values; // array of { id, name, count, boost }
        this.callbacks = {
            onChange: []
        };
        this.showAllValues = false;
        this.toggled = false;
        this.searchable = data.searchable || false;
        this.searchPhrase = '';
        if (typeof data.onChange === 'function') {
            this.callbacks.onChange.push(data.onChange);
        }
        this.topValues = [];
    };

    /**
     * Clear filter (values and callback by default)
     *
     * @returns {SelectionFilter}
     */
    SelectionFilter.prototype.clearData = function () {
        this.values = undefined;
        this.callbacks = {
            onChange: []
        };
        return this;
    };

    /**
     * Clear filter selections
     *
     * @param {boolean} triggerChange
     * @returns {SelectionFilter}
     */
    SelectionFilter.prototype.clearSelection = function (triggerChange) {
        angular.forEach(this.values, function (value) {
            value.selected = false;
        });
        if (triggerChange) {
            this.triggerChange();
        }
        return this;
    };

    /**
     * Add on values change callback
     *
     * @param {function} callback
     */
    SelectionFilter.prototype.onChange = function (callback) {
        this.callbacks.onChange.push(callback);
    };

    /**
     * Call filter listener will all selected values
     */
    SelectionFilter.prototype.triggerChange = function () {
        //TODO use broadcast instead
        angular.forEach(this.callbacks.onChange, function (callback) {
            callback(this.getSelectedValues().map(function (value) {
                return value.id;
            }));
        }.bind(this));
    };

    /**
     * Get filter selected value ids
     *
     * @returns {Array}
     */
    SelectionFilter.prototype.getSelectedValues = function () {
        return this.values.filter(function (value) {
            return value.selected;
        });
    };

    /**
     * Toggle filter visibility
     */
    SelectionFilter.prototype.toggle = function () {
        this.toggled = !this.toggled;
    };

    /**
     * Toggle filter all values visibility (default visibile 5 values)
     */
    SelectionFilter.prototype.toggleAllValues = function () {
        this.showAllValues = !this.showAllValues;
        this.applyValuesVisibility();
    };

    /**
     * Calculate top 5 filter values basing on <boost> property and results <count>
     */
    SelectionFilter.prototype.calcTopFilterValues = function () {
        this.topValues = this.values.slice();
        this.topValues.sort(function (a, b) {
            if (a.boost === b.boost) {
                return a.count < b.count;
            }
            return a.boost < b.boost;
        });
        this.topValues = this.topValues.slice(0, 5);
        this.applyValuesVisibility();
    }

    /**
     * Applies filter values visibility on list
     */
    SelectionFilter.prototype.applyValuesVisibility = function () {
        angular.forEach(this.values, function (value) {
            if (this.searchable && this.searchPhrase && this.searchPhrase.length > 1) {
                value.visible = value.name.toLowerCase().indexOf(this.searchPhrase.toLowerCase()) !== -1;
                return;
            }
            value.visible = this.showAllValues || this.topValues.indexOf(value) !== -1;
        }.bind(this));
    };

    return SelectionFilter;
});

angular.module('B2B').directive('selectionFilter', function () {
    return {
        restrict: 'E',
        templateUrl: '/views/catalog/filter/selection-filter.html',
        replace: true,
        scope: {
            filter: '='
        },
        link: function (scope) {

            /**
             * Determine filter value visibility
             *
             * @param {object} value Filter value
             * @returns {boolean}
             */
            scope.filterVisibility = function (value) {
                return value.visible;
            };

            scope.onChange = function () {
                scope.filter.triggerChange();
            };

            // Calculate top visible values when filter values change
            scope.$watch('filter.values', function (filterValues) {
                if (filterValues && filterValues.length) {
                    scope.filter.calcTopFilterValues();
                }
            });
        }
    }
        ;
});