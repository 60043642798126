'use strict';

angular.module('B2B').directive('exportUrl', [
    '$filter',
    'ExportUrlFactory',
    function ($filter,
              ExportUrlFactory) {
        return {
            restrict: 'E',
            templateUrl: '/views/panel/export/url.html',
            replace: true,
            scope: {
                service: '@',
                header: '@',
                parameters: '=',
                formats: '=',
                formatLabels: '='
            },
            link: function (scope) {
                scope.exportUrl = ExportUrlFactory.build(scope.service);

                /**
                 * Get user's export url including additional parameters
                 *
                 * @param {string} format
                 * @returns {string}
                 */
                scope.getUrl = function (format) {
                    var url = scope.exportUrl.url,
                        parameterValues = [];

                    if (url) {
                        if (format) {
                            url += '/' + format;
                        }
                        if (scope.parameters) {

                            angular.forEach(scope.parameters, function (parameter) {
                                var parameterValue;
                                if (parameter.value) {
                                    parameterValue = parameter.value;
                                    if (parameterValue instanceof Date) {
                                        parameterValue = $filter('date')(parameterValue, 'yyyy-MM-dd');
                                    }
                                    parameterValues.push(parameter.name + '=' + parameterValue);
                                }
                            });
                        }

                        if (parameterValues.length) {
                            url += '?' + parameterValues.join('&');
                        }
                    }

                    return url;
                };

                /**
                 * @param {string} format
                 * @returns {string}
                 */
                scope.getFormatLabel = function (format) {
                    if (scope.formatLabels && scope.formatLabels[format]) {
                        return scope.formatLabels[format];
                    }
                    return format;
                }
            }
        };
    }
]);