'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('SignInController', [
    '$rootScope', 'mm.core.auth.AuthService', '$scope', '$state',
    function ($rootScope, AuthService, $scope, $state) {
        $scope.command = this.command = AuthService.signInCommand(['customer', 'trader']);

        $scope.$watchCollection('command.errors', function (newErrors) {
            if (newErrors && newErrors.global && newErrors.global === "Twoje hasło wygasło! Skorzystaj z opcji przypominania hasła, aby utworzyć nowe.") {
                $state.go('new-password');
            }
        });
    }
]);