/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('CustomerService', [
    '$q',
    '$state',
    '$rootScope',
    '$timeout',
    'mm.core.api.API',
    'mm.core.api.CommandFactory',
    'mm.core.auth.AuthService',
    function ($q,
              $state,
              $rootScope,
              $timeout,
              API,
              CommandFactory,
              AuthService) {

        var customer = {
                data: null
            },
            /**
             * Get current logged customer.
             * @param {boolean} [force]
             * @returns {*}
             */
            getCustomer = (function () {
                var customerBinded = false,
                    promise;

                return function getCustomer(force) {
                    if (promise) {
                        return promise;
                    }

                    return promise = $q(function (resolve) {
                        if (!customerBinded || force) {
                            API.get('customer').then(function (customerDto) {
                                bindCustomer(customerDto);
                            }, function () {
                                bindCustomer(null);
                            }).finally(function () {
                                resolve(customer.data);
                                customerBinded = true;
                                promise = null;
                            });
                        } else {
                            resolve(customer.data);
                            $timeout(function(){
                                promise = null;
                            });
                        }
                    });
                }
            })();


        /**
         * Determines if bank account has been changed for last 5 days.
         *
         * @param date
         * @returns {boolean}
         */
        function setBankAccountChangeFlag(changeDate) {
            var offsetDate;
            if (changeDate) {
                offsetDate = new Date();
                offsetDate.setDate(offsetDate.getDate() - 5);
                changeDate = new Date(changeDate);
                return changeDate > offsetDate;
            }
            return false;
        }

        function bindCustomer(data) {
            customer.data = data;

            // Check bank account change
            if (data && data.bankAccountChangedAt) {
                customer.data.bankAccountChanged = setBankAccountChangeFlag(data.bankAccountChangedAt);
            }

            $rootScope.$broadcast('customer.changed', customer.data);
        }

        function init() {
            // Refresh Customer data on each Auth user binding
            $rootScope.$on('mm.auth.changed', function () {
                if (AuthService.data.user.hasAccess('is_customer') || AuthService.data.user.hasAccess('is_trader')) {
                    getCustomer(true);
                } else {
                    bindCustomer(null);
                }
            });
            $rootScope.$on('mm.auth.logout', function () {
                bindCustomer(null);
            });
        }

        return {
            /**
             * Init CustomerService
             */
            init: init,
            customer: customer,
            getCustomer: getCustomer,
            /**
             * Returns all Customer sub accounts
             * @returns {*}
             */
            getSubCustomers: function () {
                return API.get('customer/subcustomers');
            },
            /**
             * Return registration command
             * @param {function} onSuccess Success callback
             * @returns {*}
             */
            registrationCommand: function (onSuccess) {
                return CommandFactory.create('customers', 'post', {
                    newsletter: false,
                    privacy: false
                }, {
                    successMessage: null
                }).onSuccess(function (customer) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(customer);
                    }
                });
            }
        };

    }
]);
