/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('CustomerSocketService', [
    'mm.socket.SocketService',
    function (
        SocketService
    ) {

        return {
            on : SocketService.on
        };

    }
]);