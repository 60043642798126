'use strict';
angular.module('B2B').factory('SearchService', [
    '$rootScope',
    function ($rootScope) {

        var query = null;

        return {
            getQuery : function() {
                return query;
            },
            setQuery : function (newQuery) {
                query = newQuery;
                $rootScope.$broadcast('search.query.changed', query);
            },
            clearQuery : function () {
                query = null;
                $rootScope.$broadcast('search.query.changed', query);
            }
        };
    }
]);