'use strict';

angular.module('B2B').provider('$exceptionHandler', {
    $get: ['ExceptionHandler', function (ExceptionHandler) {
        return (ExceptionHandler);
    }]
}).factory('ExceptionHandler', [
    '$log',
    '$window',
    function ($log,
              $window) {

        var loggingEnabled = true;

        /**
         * @param {string} message
         * @param {string}[cause]
         * @param {Array} [trace]
         */
        function logError(message, cause, trace, exception) {
            var req = new XMLHttpRequest();
            var jsFileHash = getJsHashCodeFromBrowser();
            var currentJsFileHash = getCurrentJsHashCode();

            if (jsFileHash !== currentJsFileHash) {
                message = 'Error cause of different, old javascript files';
            }

            req.open('post', '/api/error-log');
            req.setRequestHeader('Content-Type', 'application/json');
            req.onload = function () {
                loggingEnabled = true;
            };

            req.send(JSON.stringify({
                url: $window.location.href,
                message: message,
                trace: trace || null,
                cause: cause || '',
                userAgent: $window.navigator ? $window.navigator.userAgent : null,
                hash: jsFileHash,
                currentJsFileHash: currentJsFileHash,
                exception: exception
            }));
        }

        function getJsHashCodeFromBrowser() {
            return localStorage.getItem('jsHash');
        }

        function getCurrentJsHashCode() {
            var scripts = document.querySelectorAll('script');

            return [].map.call(scripts, function (element) {
                return element.getAttribute('src');
            }).filter(function (src) {
                return src.indexOf('app') >= 0;
                // return src.includes('app');
            })[0].replace('/app_', '').replace('.js', '');
        }

        return function (exception, cause) {
            var exceptionMessage = exception.toString();

            $log.warn(exceptionMessage, cause);

            // Loge one error at the same time - prevent browser crashes
            if (loggingEnabled) {
                loggingEnabled = false;
                if (typeof exception === 'object') {
                    StackTrace.fromError(exception).then(function (trace) {
                        logError(exceptionMessage, cause, trace, exception);
                    });
                } else {
                    logError(exceptionMessage, cause, exception);
                }
            }
        };
    }
]);
