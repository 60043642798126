'use strict';
angular.module('B2B').directive('bannerBlock', [
    '$rootScope',
    '$timeout',
    function ($rootScope,
              $timeout) {

        return {
            restrict: 'E',
            templateUrl: '/views/cms/blocks/banner-block.html',
            replace: false,
            scope: {
                block: '='
            },
            link: function (scope) {

                /**
                 * @param {Object} block
                 */
                function buildSlider() {
                    var timer,
                        block = scope.block,
                        showBanner = function (banner) {
                            $timeout.cancel(timer);
                            if (banner && !banner.shown) {
                                $rootScope.$broadcast('banner.view', {
                                    block: scope.block.key,
                                    type: 'slider',
                                    id: banner.id,
                                    name: banner.name
                                });
                                banner.shown = true;
                            }
                            scope.currentBanner = banner;
                            timer = $timeout(showNext, 5000);
                        },
                        showNext = function () {
                            var currentIndex = block.banners.indexOf(scope.currentBanner);
                            if (currentIndex === block.banners.length - 1) {
                                showBanner(block.banners[0]);
                            } else {
                                showBanner(block.banners[currentIndex + 1]);
                            }
                        };

                    scope.showBanner = showBanner;

                    scope.$on('$destroy', function () {
                        $timeout.cancel(timer);
                    });

                    showBanner(block.banners[0]);
                }

                /**
                 * Banner click broadcasting.
                 *
                 * @param {Object} banner
                 */
                scope.onBannerClick = function (banner) {
                    $rootScope.$broadcast('banner.click', {
                        block: scope.block.key,
                        type: 'banner',
                        id: banner.id,
                        name: banner.name
                    });
                };

                if (scope.block.banners.length > 1) {
                    buildSlider();
                }
                else {
                    if (scope.block.banners.length) {
                        $rootScope.$broadcast('banner.view', {
                            block: scope.block.key,
                            type: 'banner',
                            id: scope.block.banners[0].id,
                            name: scope.block.banners[0].name
                        });
                    }
                }
            }
        };
    }
]);