'use strict';

angular.module('B2B').filter('localdate', function () {
    return function (date) {
        if (!date) {
            return '-';
        }
        if (!(date instanceof Date)) {
            date = new Date(date);
            if (isNaN(date.getTime())) {
                return undefined;
            }
        }
        return date.toLocaleDateString();
    };
});

angular.module('B2B').directive('dateRangeDropdown', ['$document', '$timeout', function ($document, $timeout) {
    return {
        restrict: 'E',
        transclude: false,
        templateUrl: '/views/core/dom/date-range-dropdown.html',
        replace: true,
        scope: {
            placeholder: '@',
            start: '=?',
            end: '=?',
            range: '=',
            onRangeChange: '&'
        },
        link: function (scope, element, attrs) {

            var rangeChangeTimer,
                formatRangeDate = function (date) {
                    return date ? date.getTime() : null;
                },
                /**
                 * Generate range model basing on start and end markers
                 * Trigger <onRangeChange>
                 */
                bindRangeModel = function () {
                    if (scope.start || scope.end) {
                        scope.range = [formatRangeDate(scope.start), formatRangeDate(scope.end)];
                    } else {
                        scope.range = null;
                    }
                    if (scope.listVisible) {
                        $timeout.cancel(rangeChangeTimer);
                        rangeChangeTimer = $timeout(function () {
                            scope.onRangeChange();
                        });
                    }
                };


            // Init range values
            scope.start = scope.start ? new Date(scope.start) : null;
            scope.end = scope.end ? new Date(scope.end) : null;
            if (!(scope.range instanceof Array)) {
                bindRangeModel();
            }

            /**
             * Toggle date range picker visibility
             */
            scope.toggleDropdown = function () {
                scope.listVisible = !scope.listVisible;
            };
            /**
             * Hide date range picker visibility
             */
            scope.hideDropdown = function () {
                $timeout(function () {
                    scope.listVisible = false;
                });
            };

            /**
             * Clear range scope values
             *
             * @param {string} [value]
             */
            scope.clearValue = function (value) {
                if (!value || value === 'start') {
                    scope.start = null;
                }
                if (!value || value === 'end') {
                    scope.end = null;
                }
                bindRangeModel();
            };


            // Observers
            attrs.$observe('disabled', function (isDisabled) {
                scope.disableDatePickers = !!isDisabled;
            });

            scope.$watch('start.getTime()', function (value, oldValue) {
                if (value && scope.end && value > scope.end.getTime()) {
                    scope.end = new Date(value);
                }
                if (value !== oldValue) {
                    bindRangeModel();
                }
            });

            scope.$watch('end.getTime()', function (value, oldValue) {
                if (value && scope.start && value < scope.start.getTime()) {
                    scope.start = new Date(value);
                }
                if (value !== oldValue) {
                    bindRangeModel();
                }
            });

            $document.on('click', scope.hideDropdown);
            scope.$on('$destroy', function () {
                $document.off('click', scope.hideDropdown);
            });
        }
    };
}]);
