/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('BasicFilterService', [
    '$state', '$rootScope', 'SelectionListFilter', 'BooleanFilter', 'mm.core.auth.AuthService',
    function ($state, $rootScope, SelectionListFilter, BooleanFilter, AuthService) {

        var warehouseFilter = new SelectionListFilter({
                name: 'Magazyn',
                id: 'warehouse',
                options: [
                    {
                        value: '',
                        label: 'dowolny'
                    },
                    {
                        value: 'local',
                        label: 'lokalny'
                    },
                    {
                        value: 'logistic',
                        label: 'logistyczny'
                    }
                ]
            }),
            promotionalFilter = new BooleanFilter({
                name: 'Promocyjne',
                id: 'promotional'
            }),
            availableOnlyFilter = new BooleanFilter({
                name: 'Dostępne',
                id: 'availableOnly'
            }),
            data = {
                filters: [warehouseFilter, promotionalFilter, availableOnlyFilter]
            };

        // Search redirects for no filtering pages
        $rootScope.$on('promotional.filter.changed', function (event, checked) {
            if (checked && !$state.current.filtersEnabled) {
                $state.go('search', {
                    promotional: true
                });
            }
        });
        $rootScope.$on('availableOnly.filter.changed', function (event, checked) {
            if (checked && !$state.current.filtersEnabled) {
                $state.go('search', {
                    availableOnly: true
                });
            }
        });
        $rootScope.$on('warehouse.filter.changed', function (event, option) {
            if (option.value && !$state.current.filtersEnabled) {
                $state.go('search', {
                    warehouse: option.value
                });
            }
        });

        return {
            data: data,
            warehouseFilter: warehouseFilter,
            promotionalFilter: promotionalFilter,
            availableOnlyFilter: availableOnlyFilter,
            clearSelection: function () {
                warehouseFilter.clearSelection();
                promotionalFilter.clearSelection();
                availableOnlyFilter.clearSelection();
            }
        };
    }
]);