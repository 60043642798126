'use strict';

angular.module('B2B').controller('DiscountController', [
    '$scope',
    '$state',
    'ProductListFactory',
    function ($scope,
              $state,
              ProductListFactory) {

        $scope.discountType = $state.current.discountType;
        $scope.header = $state.current.header;

        $scope.list = ProductListFactory.create($scope, {
            viewType: 'details',
            facetingEnabled: false,
            itemsOnPage: 100
        });
        $scope.list.grid.setFilter('discount', $scope.discountType);

        $scope.now = new Date();
        $scope.promotion_start = new Date(2018, 4, 22, 9, 0, 0, 0);
        $scope.promotion_end = new Date(2018, 4, 28, 12, 30, 59, 0);

        // Check if specified promotion date.
        $scope.isPromotionTime = function () {
            return ($scope.now - $scope.promotion_start) >= 0 && ($scope.now - $scope.promotion_end) <= 0;
        };

        $scope.promotionConditions = [
            '- 200 zł – 14 dni dodatkowego terminu płatności do <b>CAŁEGO ZAMÓWIENIA</b>,',
            '- 300 zł – 21 dni dodatkowego terminu płatności do <b>CAŁEGO ZAMÓWIENIA</b>,',
            '- 500 zł –  28 dni dodatkowego terminu płatności do <b>CAŁEGO ZAMÓWIENIA</b>.'
        ];

        $scope.smallPromotionCondition = '* Dotyczy zamówień tylko B2B oraz maksymalny sumaryczny termin płatności może wynosić 40 dni.';

        $scope.list.init();
    }
]);
