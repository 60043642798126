'use strict';

angular.module('B2B').config(['AnalyticsProvider', function (AnalyticsProvider) {
    AnalyticsProvider.setAccount({
        tracker: 'UA-69450373-1',
        displayFeatures: true,
        enhancedLinkAttribution: true,
    }).setPageEvent('$stateChangeSuccess')
        .useECommerce(true, true)
        .setCurrency('PLN');

}]).run(['$rootScope', 'Analytics', function ($rootScope, Analytics) {

    $rootScope.$on('mm.auth.login', function (event, user) {
        // Set the User Id
        Analytics.set('&uid', user.id);
        if (!user && !user.userable) {
            throw new Error('No userable specified for user ' + user.id + ' .');
        }
        Analytics.set('dimension1', user.userable.type);
        Analytics.pageView();
    });

    $rootScope.$on('mm.auth.logout', function () {
        Analytics.set('&uid', null);
        Analytics.set('dimension1', null);
        Analytics.pageView();
    });

    $rootScope.$on('customer.changed', function (event, customer) {
        if (customer) {
            Analytics.set('dimension2', customer.clientNumber);
            Analytics.set('dimension3', customer.company);
            Analytics.set('dimension4', customer.eInvoice);
        } else {
            Analytics.set('dimension2', null);
            Analytics.set('dimension3', null);
            Analytics.set('dimension4', null);
        }
        Analytics.pageView();
    });

    $rootScope.$on('product.visited', function (event, product) {
        Analytics.addProduct(
            product.id,
            product.name,
            product.category.name,
            product.producer ? product.producer.name : undefined,
            null,
            product.price ? product.price.net : undefined,
            product.quantity
        );
        Analytics.trackDetail();
    });

    $rootScope.$on('cart.item.added', function (event, data) {
        Analytics.addProduct(data.product.id, data.product.name, null, null, null, data.product.price.net, data.quantity);
        Analytics.trackCart('add');
    });
    $rootScope.$on('cart.item.removed', function (event, data) {
        Analytics.addProduct(data.item.item.id, data.item.item.name, null, null, null, data.item.price.net, data.item.quantity);
        Analytics.trackCart('remove');
    });
    $rootScope.$on('cart.item.updated', function (event, data) {
        if (data.quantityDiff > 0) {
            Analytics.addProduct(data.item.item.id, data.item.item.name, null, null, null, data.item.price.net, data.quantityDiff);
            Analytics.trackCart('add');
        } else {
            Analytics.addProduct(data.item.item.id, data.item.item.name, null, null, null, data.item.price.net, Math.abs(data.quantityDiff));
            Analytics.trackCart('remove');
        }
    });

    $rootScope.$on('order.created', function (event, order) {
        var products = _.filter(order.items, {type: 'product'}),
            fees = _.filter(order.items, {type: 'fee'}),
            feeValue = 0;

        angular.forEach(fees, function (fee) {
            feeValue += fee.value;
        });

        angular.forEach(products, function (product) {
            Analytics.addProduct(product.productId, product.name, null, null, null, product.price, product.quantity);
        });

        Analytics.trackTransaction(order.orderId, 'B2B', order.netValue, order.grossValue - order.netValue, feeValue);
    });

    // Banners
    $rootScope.$on('banner.view', function (event, banner) {
        Analytics.addPromo('banner_' + banner.id, banner.name, '', banner.block + '_' + banner.type);
        Analytics.pageView();
    });
    $rootScope.$on('banner.click', function (event, banner) {
        Analytics.addPromo('banner_' + banner.id, banner.name, '', banner.block + '_' + banner.type);
        Analytics.promoClick(banner.name);
    });
}]);
