/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ProducerFilterService', [
    '$state', '$rootScope', 'ProducerService', 'SelectionFilter',
    function ($state, $rootScope, ProducerService, SelectionFilter) {

        var data = {
                filter: null
            },
            onValueSelectionChangeCallback = null;

        /**
         * @param {array} facetFilter Array of {id, count}
         * @param {array}
         */
        function bindFacetFilter(facetFilter, producers) {
            angular.forEach(facetFilter, function (facetFilterData) {
                angular.forEach(producers, function (producer) {
                    if (producer.id === facetFilterData.id) {
                        producer.count = facetFilterData.count;
                    }
                });
            });
        }

        /**
         * Create new filter or update filter data
         *
         * @param {array} values
         * @param {function} onChange
         * @returns {SelectionFilter}
         */
        function saveFilter(values, onChange) {
            onValueSelectionChangeCallback = onChange ? onChange : function (values) { //TODO use broadcast instead
                $state.go('search', {
                    producer: values.join()
                });
            };
            if (!data.filter) {
                data.filter = new SelectionFilter({
                    id: 'prod',
                    name: 'Producent',
                    values: values,
                    searchable: true
                });
                data.filter.onChange(function (values) {
                    $rootScope.$broadcast('producer.filter.selectionChanged', data.filter);
                    onValueSelectionChangeCallback(values);
                });
                $rootScope.$broadcast('producer.filter.created', data.filter);
            } else {
                data.filter.values = values;
                $rootScope.$broadcast('producer.filter.valuesChanged', data.filter);
            }

            return data.filter;
        }

        return {
            data: data,
            clearSelection: function () {
                if (data.filter) {
                    data.filter.clearSelection(true);
                    data.filter.toggled = false;
                    data.filter.showAllValues = false;
                }
            },
            /**
             * Reload producers filter
             *
             * @param {array} selectedIds array od id - selected producers
             * @param {array} facetFilter Array of {id, count}
             * @param {function} onChange
             * @returns {Promise}
             */
            loadFilter: function (selectedIds, facetFilter, onChange) {
                return ProducerService.getProducers().then(function (producers) {
                    var values = angular.copy(producers);

                    if (facetFilter) {
                        bindFacetFilter(facetFilter, values);
                        // Remove 0-count producers from filter
                        values = values.filter(function (producer) {
                            if (selectedIds && selectedIds.indexOf(producer.id) !== -1) {
                                producer.selected = true;
                            }
                            return producer.count;
                        });
                    } else if (data.filter) {
                        data.filter.toggled = false;
                        data.filter.showAllValues = false;
                    }
                    saveFilter(values, onChange);

                    return data.filter;
                });
            },
            removeFilter: function () {
                data.filter = null;
            }
        };
    }
]);