'use strict';

/**
 * AppController
 * Defines global scope properties and global behaviors
 *
 * @author Modest Machnicki
 */
angular.module('B2B').controller('AppController', [
    '$scope',
    '$state',
    '$window',
    '$document',
    '$timeout',
    'mm.core.auth.AuthService',
    'mm.core.api.HttpErrorService',
    'ScaffoldingService',
    'UserService',
    'CustomerService',
    function ($scope,
              $state,
              $window,
              $document,
              $timeout,
              AuthService,
              HttpErrorService,
              ScaffoldingService,
              UserService,
              CustomerService) {

        var vm = this;

        vm.maintenance = false;

        HttpErrorService.setAction(503, function () {
            vm.maintenance = true;
            vm.showMainTabs = false;
            $state.go('maintenance');
        });

        // Assign current router state for all templates
        $scope.$on('$stateChangeSuccess', function (event, toState) {
            this.bodyClass = toState.name.replace(/\./g, ' ');
            this.catalogMode = !!toState.catalogMode;
            //Determine if left bar (mainTabs) is visible
            this.showMainTabs = typeof toState.mainTabs === 'undefined' ? true : !!toState.mainTabs;
        }.bind(this));

        this.auth = AuthService.data;

        this.customer = CustomerService.customer;

        this.signOut = function () {
            AuthService.signOut().then(function () {
                $state.go('home');
            });
        };

        this.avatar = UserService.getImageSrc;

        this.scaffolding = ScaffoldingService.data;

        this.access = AuthService.access;


        /**
         * Go to auth or go back if auth is a current state
         */
        this.toggleAuthState = function () {
            if ($state.current.name === 'auth') {
                $window.history.back();
            } else {
                $state.go('auth');
            }
        };

        // Enabling translate component
        if ($window.location.search.indexOf('translate') !== -1) {
            this.translate = true;
        }
        $scope.$on('customer.changed', function (event, customer) {
            if (customer && customer.export) {
                vm.translate = true;
            }
            vm.currencyPickerEnabled = customer && customer.export;
        });

        // Force reload page when idle (no state change) for one hour
        (function () {
            var timer;

            function startCounter() {
                timer = $timeout(function () {
                    $window.location.reload(true);
                }, 3600000);
            }

            $scope.$on('$stateChangeStart', function () {
                $timeout.cancel(timer);
                startCounter();
            });
        })();

    }
]);