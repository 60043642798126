'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('CheckoutController', [
    '$scope', '$state', 'CheckoutService',
    function ($scope, $state, CheckoutService) {

        var vm = this;

        /**
         * Return step state name (without parent name)
         *
         * @param {string} state
         * @returns {*}
         */
        function parseCurrentStep(state) {
            return state.name.replace('checkout.', '');
        }

        vm.data = CheckoutService.data;
        CheckoutService.setStep(parseCurrentStep($state.current));
        vm.isStepEnabled = CheckoutService.isStepEnabled;
        vm.isStepAvailable = CheckoutService.isStepAvailable;

        $scope.$on('$stateChangeSuccess', function (event, toState) {
            CheckoutService.setStep(parseCurrentStep($state.current));
        });

    }
]);
