/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('SelectionHeaderFilter', ['HeaderFilter', function (HeaderFilter) {

    /**
     * @param {SelectionFilter} filter
     * @constructor
     */
    var SelectionHeaderFilter = function (filter, scope) {
        HeaderFilter.call(this, filter.name, scope);

        this.filter = filter;

        filter.onChange(function () {
            this.refreshValues();
        }.bind(this));
    };

    SelectionHeaderFilter.prototype = Object.create(HeaderFilter.prototype);
    SelectionHeaderFilter.prototype.constructor = SelectionHeaderFilter;

    /**
     * Bind selected values list
     */
    SelectionHeaderFilter.prototype.bindValues = function () {
        angular.forEach(this.filter.getSelectedValues(), function(filterValue){
            this.values.push({
                name: filterValue.name,
                remove: function(){
                    filterValue.selected = false;
                    this.filter.triggerChange();
                }.bind(this)
            });
        }.bind(this));
    };

    /**
     * Clear all attribute filter values
     */
    SelectionHeaderFilter.prototype.remove = function() {
        this.filter.clearSelection().triggerChange();
    };

    return SelectionHeaderFilter;
}]);