'use strict';

angular.module('B2B').controller('LgPromoController', [
    '$window', '$q', 'mm.core.api.API', 'mm.core.auth.AuthService', 'mm.core.api.CommandFactory', 'CustomerService',
    function ($window, $q, API, Auth, CommandFactory, CustomerService) {

        var vm = this;

        vm.loading = true;
        vm.submitted = false;

        /**
         * Validate, send form (command)
         */
        vm.register = function () {
            if (vm.command.fields.dataProcessingAgreement) {
                vm.loading = true;
                vm.command.execute().then(function () {
                    vm.loading = false;
                });
            }
        };

        /**
         * Redirect to auth page with redirect param
         */
        vm.login = function () {
            $window.location.replace('/auth?redirectAbs=' + $window.location.href);
        };

        /**
         * Form command
         */
        vm.command = CommandFactory.create('lg-promo', 'post', {
            dataProcessingAgreement: true,
            marketingAgreement: true
        });
        vm.command.onSuccess(function () {
            vm.submitted = true;
        });

        // Check auth and bind customer data
        $q.all([
            Auth.getUser(),
            CustomerService.getCustomer(),
            API.get('lg-promo')
        ]).then(function (results) {
            vm.user = results[0];
            if (vm.user && vm.user.hasAccess('is_customer')) {
                vm.customer = results[1];
                vm.info = results[2];
            }
        }).finally(function () {
            vm.loading = false;
        });

    }
]);
