/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('AttributeFilterService', [
    '$rootScope', '$state', 'mm.core.api.API', 'SelectionFilter', 'RangeFilter',
    function ($rootScope, $state, API, SelectionFilter, RangeFilter) {

        var data = {
                loading: false,
                filters: []
            },
            currentCategory = null;


        /**
         * Mark given attribute values as selected
         *
         * @param {Array} selectedAttributes
         */
        function selectAttributeValues(selectedAttributes) {
            angular.forEach(selectedAttributes, function (selectedAttribute) {
                angular.forEach(data.filters, function (filter) {
                    if (filter instanceof SelectionFilter && filter.id === 'at_' + selectedAttribute.id) {
                        angular.forEach(filter.values, function (value) {
                            if (selectedAttribute.values.indexOf(value.id) !== -1) {
                                value.selected = true;
                            }
                        });
                    } else if (filter instanceof RangeFilter && filter.id === 'at_' + selectedAttribute.id) {
                        filter.setRange(
                            selectedAttribute.values[0],
                            selectedAttribute.values[1]
                        );
                    }
                });
            });
        }

        /**
         * @param {object} attribute Attribute server dto
         * @param {function} onChange
         * @returns {SelectionFilter}
         */
        function buildSelectionFilter(attribute, onChange) {
            if (!attribute.values || !attribute.values.length) {
                return null;
            }
            return new SelectionFilter({
                id: 'at_' + attribute.id,
                name: attribute.name,
                values: attribute.values.map(function (valueData) {
                    return {id: valueData.id, name: valueData.value};
                }),
                onChange: function (values) {
                    if (typeof onChange === 'function') {
                        onChange(attribute.id, values);
                    }
                }
            });
        }

        /**
         * @param {object} attribute Attribute server dto
         * @param {function} onChange
         * @returns {RangeFilter}
         */
        function buildRangeFilter(attribute, onChange) {
            return new RangeFilter({
                id: 'at_' + attribute.id,
                disabled: true,
                name: attribute.name,
                unit: attribute.unit,
                onChange: function (values) {
                    if (typeof onChange === 'function') {
                        onChange(attribute.id, values);
                    }
                }
            });
        }

        /**
         * @param {Object} filterItem
         * @param {function} onChange
         * @returns {*}
         */
        function buildFilter(filterItem, onChange) {
            if (filterItem.type === 'selection') {
                return buildSelectionFilter(filterItem, onChange);
            } else if (filterItem.type === 'integer') {
                return buildRangeFilter(filterItem, onChange);
            }
            return null;
        }

        /**
         * Find facet data for given attribute
         *
         * @param {Array} facetData
         * @param {number} attributeId
         * @returns {*}
         */
        function getFacetAttributeData(facetData, attributeId) {
            if (facetData && attributeId) {
                return facetData.filter(function (attributeData) {
                    return attributeData.id === attributeId;
                })[0];
            }
            return null;
        }

        /**
         * Bind facet data (count or range) to filter item
         *
         * @param {object} filter
         * @param {Array} facetData
         */
        function bindFacetData(filter, facetData) {

            var facetAttributeData = getFacetAttributeData(facetData, parseInt(filter.id.replace('at_', '')));

            if (facetAttributeData) {
                if (filter instanceof SelectionFilter) {
                    angular.forEach(filter.values, function (filterValue) {
                        var facetValueData = facetAttributeData.values.filter(function (_facetValueData) {
                            return _facetValueData.id === filterValue.id;
                        })[0];
                        filterValue.count = facetValueData ? facetValueData.count : 0;
                    });
                } else if (filter instanceof RangeFilter) {
                    if (typeof facetAttributeData.min === 'number' && typeof facetAttributeData.max === 'number') {
                        filter.setMaxRange(facetAttributeData.min, facetAttributeData.max);
                        filter.disabled = false;
                    } else {
                        filter.disabled = true;
                    }
                }
            }
        }

        /**
         * Load attribute filter list
         *
         * @param {number} categoryId
         * @param {Array} attributes Attributes values to mark as selected
         * @param {Array} facetData Attributes faceting data
         * @param {function} callback Call after filter values changed
         */
        function loadFilters(categoryId, attributes, facetData, callback) {

            if (currentCategory !== categoryId || !data.filters.length) {
                data.loading = true;
                data.filters = [];

                API.get('categories/' + categoryId + '/filters').then(function (filterItems) {
                    angular.forEach(filterItems, function (filterItem) {
                        var filter = buildFilter(filterItem, callback);
                        if (filter) {
                            bindFacetData(filter, facetData);
                            data.filters.push(filter);
                        }
                    });
                    selectAttributeValues(attributes);
                    currentCategory = categoryId;
                    data.loading = false;
                    $rootScope.$broadcast('attribute.filters.loaded', data.filters);
                });

            } else {
                angular.forEach(data.filters, function (filter) {
                    filter.clearSelection(false);
                    bindFacetData(filter, facetData);
                });
                selectAttributeValues(attributes);
            }

        };

        /**
         * @param {Array} filters
         * @returns {Array}
         */
        function spliceEmptyFilters(filters) {
            angular.forEach(filters, function (filter) {
                if (filter instanceof SelectionFilter) {
                    filter.values = filter.values.filter(function (value) {
                        return value.count !== 0;
                    });
                }
            });
            return filters.filter(function (filter) {
                return filter instanceof SelectionFilter && filter.values.length > 0 || filter instanceof RangeFilter;
            });
        }

        return {
            data: data,
            loadFilters: loadFilters,
            buildFilter: buildFilter,
            bindFacetData: bindFacetData,
            spliceEmptyFilters: spliceEmptyFilters,
            clearSelection: function () {
                angular.forEach(data.filters, function (filter) {
                    filter.clearSelection(true);
                });
            },
            removeFilters: function () {
                data.filters = [];
            }
        };
    }
]);