/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ProducerHeaderFilter', ['HeaderFilter', 'ProducerFilterService',
    function (HeaderFilter, ProducerFilterService) {

        /**
         * @constructor
         */
        var ProducerHeaderFilter = function (scope) {
            HeaderFilter.call(this, 'Producent', scope);
        };

        ProducerHeaderFilter.prototype = Object.create(HeaderFilter.prototype);
        ProducerHeaderFilter.prototype.constructor = ProducerHeaderFilter;

        /**
         * Bind selected values list
         */
        ProducerHeaderFilter.prototype.bindValues = function () {
            if (ProducerFilterService.data.filter) {
                angular.forEach(ProducerFilterService.data.filter.getSelectedValues(), function (filterValue) {
                    this.values.push({
                        name: filterValue.name,
                        remove: function () {
                            filterValue.selected = false;
                            ProducerFilterService.data.filter.triggerChange();
                        }.bind(this)
                    });
                }.bind(this));
            }
        };

        /**
         * Clear all attribute filter values
         */
        ProducerHeaderFilter.prototype.remove = function() {
            ProducerFilterService.data.filter.clearSelection().triggerChange();
        };

        ProducerHeaderFilter.prototype.init = function() {
            this.scope.$on('producer.filter.created', function(){this.refreshValues();}.bind(this));
            this.scope.$on('producer.filter.selectionChanged', function(){this.refreshValues();}.bind(this));
            this.scope.$on('producer.filter.valuesChanged', function(){this.refreshValues();}.bind(this));

            this.refreshValues();
        };

        return ProducerHeaderFilter;
    }
]);