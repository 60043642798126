/**
 * @author Modest Machnicki
 * TODO create separate CategoryTreeService (maybe with cloned categoryTree ?)
 */
'use strict';
angular.module('B2B').factory('CategoryService', [
    '$rootScope',
    '$state',
    '$q',
    '$timeout',
    'mm.core.api.API',
    'Category',
    function ($rootScope, $state, $q, $timeout, API, Category) {

        var categoryTree,
            callbacks = {
                onCategoryFacetSelect: null
            },
            settings = {
                keepCategoryTreeState: false
            },
            data = {
                loadingDelayed: false,
                treeLoading: false,
                hasFacets: false
            };

        /**
         * Fetch category tree data
         *
         * @param boolean [force]
         * @returns {Promise}
         */
        var getCategoryTree = (function () {
            var promise;

            return function (force) {
                if (promise && !force) {
                    return promise;
                }
                promise = $q(function (resolve, reject) {
                    if (!categoryTree || force) {
                        data.treeLoading = true;
                        return API.get('categories').then(function (response) {
                            categoryTree = Category.apiResposneTransformer(response);

                            if (!data.loadingDelayed) {
                                data.treeLoading = false;
                            }
                            resolve(categoryTree);
                            promise = null;
                        }, function () {
                            reject();
                            promise = null;
                        });
                    } else {
                        resolve(categoryTree);
                        $timeout(function(){
                            promise = null;
                        });
                    }
                });

                return promise;
            };
        })();

        /**
         * Mark categories as current in catgory tree
         *
         * @param Category category
         */
        function markAsCurrent(category) {
            getCategoryTree().then(function (categoryTree) {
                Category.each(categoryTree, function (categoryNode) {
                    if (categoryNode !== category) {
                        categoryNode.current = false;
                        categoryNode.toggled = false;
                    }
                });
                category.current = true;
                category.expand();
            });

        }

        /**
         * Find category in categoryTree
         *
         * @param {number} categoryId
         * @returns {Promise}
         */
        function findCategory(categoryId) {
            return $q(function (resolve, reject) {
                getCategoryTree().then(function (categoryTree) {
                    var category;
                    if (category = Category.find(categoryTree, parseInt(categoryId))) {
                        resolve(category);
                    } else {
                        reject();
                    }
                }, reject);
            });
        }

        /**
         * Reset category tree settings and category states
         *
         * @returns {Promise}
         */
        function resetCategoryTreeState() {
            callbacks.onCategoryFacetSelect = null;
            return getCategoryTree().then(function () {
                Category.each(categoryTree, function (category) {
                    category.resetState();
                });
                data.hasFacets = false;
                $rootScope.$broadcast('categoryTree.stateReset');
            });
        }

        // Reset category state when route states change, omit given states
        $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            if ((!$state.current.keepCategoryTreeState && !settings.keepCategoryTreeState) || !toState.keepCategoryTreeState) {
                resetCategoryTreeState();
            }
            settings.keepCategoryTreeState = false;
        });

        return {
            data: data,
            callbacks: callbacks,
            getCategoryTree: getCategoryTree,
            markAsCurrent: markAsCurrent,
            find: function (categoryId) {
                return findCategory(categoryId);
            },
            /**
             * Show only categories that match the facets and show product count
             *
             * @param {array} categoriesData array of {id, count}
             * @param {function} function called after select facets category
             * @returns {Promise}
             */
            bindFacet: function (categoriesData, callback) {
                return resetCategoryTreeState().then(function () {
                    Category.each(categoryTree, function (category) {
                        category.visible = false;
                    });
                    angular.forEach(categoriesData, function (categoryData) {
                        findCategory(categoryData.id).then(function (category) {
                            if (category) {
                                category.bindFacet(categoryData.count);
                            }
                        });
                    });
                    if (typeof callback === 'function') {
                        callbacks.onCategoryFacetSelect = callback;
                    }
                    data.hasFacets = true;
                });
            },
            resetCategoryTreeState: resetCategoryTreeState,
            keepCategoryTreeState: function () {
                settings.keepCategoryTreeState = true;
            },
            /**
             * Allow to manipulate category tree data before rendering
             *
             * @param callback
             */
            delayTreeRendering: function (callback) {
                var result;
                if (typeof callback === 'function') {
                    data.loadingDelayed = true;
                    data.treeLoading = true;
                    result = callback();
                    if (result.then) { // Support promise callbacks
                        result.finally(function () {
                            data.loadingDelayed = false;
                            data.treeLoading = false;
                        });
                    }
                    else {
                        data.loadingDelayed = false;
                        data.treeLoading = false;
                    }
                }
            }
        };
    }
]);