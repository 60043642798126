/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('mmRange', ['$timeout', '$q', function ($timeout, $q) {

    return {
        restrict: 'E',
        templateUrl: '/views/core/dom/range.html',
        replace: true,
        scope: {
            min: '=',
            max: '=',
            start: '=',
            end: '=',
            unit: '=',
            onMarkerChange: '&'
        },
        controller: function ($scope, $element, $attrs) {
            var minOffset,
                maxOffset,
                offsetRange,
                minValue,
                maxValue,
                valueRange,
                step,
                precision,
                rangeMargin = 5,
                markers = [],
                watchersDisabled = false,
                watchersDisabledTimer,
                /**
                 * Allows to bind $scope properties without watcher trigger
                 *
                 * @param callback
                 */
                disableWatchers = function (callback) {
                    $timeout.cancel(watchersDisabledTimer);
                    watchersDisabled = true;
                    callback();
                    watchersDisabledTimer = $timeout(function () {
                        watchersDisabled = false;
                    }, 10);
                },
                getOffsetPercent = function (offset) {
                    return ((offset - minOffset) / offsetRange) * 100;
                },
                getValuePercent = function (value) {
                    return valueRange ? ((value - minValue) / valueRange) * 100 : 0;
                },
                calcOffset = function (value) {
                    return minOffset + (offsetRange * getValuePercent(value) / 100.0);
                },
                /**
                 * Calculate all basic dimensions basing on DOM element and scope data
                 */
                calcDimensions = function () {
                    minOffset = 0 + rangeMargin;
                    maxOffset = $element[0].offsetWidth - rangeMargin;
                    offsetRange = maxOffset - minOffset;
                    minValue = parseFloat($scope.min);
                    maxValue = parseFloat($scope.max);
                    valueRange = maxValue - minValue;
                    step = parseFloat($scope.step);
                    precision = 0;
                },
                /**
                 * Recalculate given value considering such factor as precision, step
                 *
                 * @param {number} value
                 * @param {number} precision Number of digits after decimal
                 * @param {number} step
                 * @param {number} floor Min value of a range
                 * @returns {string|*}
                 */
                roundStep = function (value, precision, step, floor) {
                    var decimals, remainder, roundedValue, steppedValue;

                    if (floor == null) {
                        floor = 0;
                    }
                    if (step == null) {
                        step = 1 / Math.pow(10, precision);
                    }
                    remainder = (value - floor) % step;
                    steppedValue = remainder > (step / 2) ? value + step - remainder : value - remainder;
                    decimals = Math.pow(10, precision);
                    roundedValue = steppedValue * decimals / decimals;
                    return parseFloat(roundedValue.toFixed(precision));
                },
                /**
                 * Build range basing on markers data
                 */
                calcRangeMarkerBased = function () {
                    var minMarker,
                        maxMarker;

                    if (markers.length === 2) {
                        if (markers[0].data.offset < markers[1].data.offset) {
                            minMarker = markers[0];
                            maxMarker = markers[1];
                        } else {
                            minMarker = markers[1];
                            maxMarker = markers[0];
                        }

                        disableWatchers(function () {
                            $timeout(function () {
                                $scope.start = minMarker.data.value;
                                $scope.rangeLeftOffset = minMarker.data.offset;
                                $scope.end = maxMarker.data.value;
                                $scope.rangeRightOffset = maxOffset - maxMarker.data.offset + rangeMargin;
                            });
                        });

                    }
                },
                /**
                 * Update markers (basing on scope data) and rebuild range
                 */
                bindMarkersData = function () {
                    var startValue,
                        endValue,
                        disabled = false;

                    if (markers.length === 2) {
                        calcDimensions();

                        startValue = typeof $scope.start === 'number' ? parseFloat($scope.start) : minValue;
                        endValue = typeof $scope.end === 'number' ? parseFloat($scope.end) : maxValue;

                        if (startValue < minValue) {
                            startValue = minValue;
                        }
                        if (endValue > maxValue) {
                            endValue = maxValue;
                        }
                        if (minValue === maxValue) {
                            disabled = true;
                        }

                        markers[0].update({
                            id: 0,
                            value: startValue,
                            offset: calcOffset(startValue),
                            disabled: disabled
                        });
                        markers[1].update({
                            id: 1,
                            value: endValue,
                            offset: endValue !== maxValue ? calcOffset(endValue) : maxOffset,
                            disabled: disabled
                        });
                        calcRangeMarkerBased();
                    }
                };

            //
            // CONTROLLER METHODS
            //

            /**
             * Calculate all basic dimensions basing on DOM element and scope data
             */
            this.calcDimensions = calcDimensions;

            /**
             * Register marker directive (range currently works only with two markers)
             */
            this.registerMarker = (function () {

                function bindUpdateMethod(callback) {
                    return function (data) {
                        this.data = this.data || {};

                        if (typeof data.id !== 'undefined') {
                            this.data.id = data.id;
                        }
                        if (typeof data.value !== 'undefined') {
                            this.data.value = data.value;
                        }
                        if (typeof data.offset !== 'undefined') {
                            this.data.offset = data.offset;
                        }
                        if (typeof data.disabled !== 'undefined') {
                            this.data.disabled = data.disabled;
                        }
                        callback(data);
                    };
                }

                return function (callback) {

                    var defer = $q.defer(),
                        timer,
                        trialsNumber = 0;

                    function loadMarker(markerIndex) {
                        $timeout.cancel(timer);
                        trialsNumber++;
                        // Load marker only if range widget has been rendered
                        if ($element[0].offsetWidth) {
                            if (markers.length === 1) {
                                calcDimensions();
                            }
                            markers[markerIndex] = {
                                update: bindUpdateMethod(callback)
                            };
                            if (markers[0] && markers[1]) {
                                bindMarkersData();
                            }
                            defer.resolve();
                        } else if (trialsNumber < 10) { // prevent infinite number of approaches
                            timer = $timeout(function () {
                                loadMarker(markerIndex);
                            }, 5);
                        }
                    }

                    if (markers.length) {
                        timer = $timeout(loadMarker(1), 5);
                    }
                    else {
                        markers[0] = null;
                        timer = $timeout(loadMarker(0), 5);
                    }

                    return defer.promise;
                };
            })();

            /**
             * Handle marker slide/move
             *
             * @param {object} event Browser event
             * @param {number} markerId Marker ID (which is index 0 or 1 currently)
             */
            this.onMarkerMove = function (event, markerId) {
                var eventX, newOffset, newPercent, newValue;

                eventX = event.clientX || event.touches[0].clientX;
                newOffset = eventX - $element[0].getBoundingClientRect().left;
                newOffset = Math.max(Math.min(newOffset, maxOffset), minOffset);
                newPercent = getOffsetPercent(newOffset);
                newValue = minValue + (valueRange * newPercent / 100.0);
                newValue = roundStep(newValue, precision, step, maxValue);

                markers[markerId].update({
                    value: newValue,
                    offset: newOffset
                });
                calcRangeMarkerBased();
            };

            /**
             * Trigger scoped callback after markers movement
             */
            this.onMarkerChange = function () {
                if (angular.isDefined($attrs.onMarkerChange)) {
                    $scope.onMarkerChange();
                }
            };

            //
            // SCOPE
            //

            // Assing defaults
            $scope.step = 1;

            /**
             * Handle scope watcher
             */
            (function () {
                var timer;

                function handleWatcher(value, oldValue) {
                    if (value !== oldValue) {
                        $timeout.cancel(timer);
                        timer = $timeout(function () {
                            if (!watchersDisabled) {
                                bindMarkersData();
                            }
                        });
                    }
                }

                $scope.$watch('start', handleWatcher);
                $scope.$watch('end', handleWatcher);
                $scope.$watch('min', handleWatcher);
                $scope.$watch('max', handleWatcher);
            })();

        }
    };

}]);

angular.module('B2B').directive('mmRangeMarker', ['$document', function ($document) {

    return {
        restrict: 'A',
        require: '^mmRange',
        scope: true,
        link: function (scope, element, attrs, rangeCtrl) {

            function onStart(event) {
                event.stopPropagation();
                event.preventDefault();
                if (!scope.disabled) {
                    rangeCtrl.calcDimensions();
                    $document.on('mousemove', onMove);
                    $document.on('mouseup', onEnd);
                    scope.active = true;
                }
            }

            function onMove(event) {
                event.stopPropagation();
                event.preventDefault();
                rangeCtrl.onMarkerMove(event, scope.markerId);
            }

            function onEnd(event) {
                event.stopPropagation();
                event.preventDefault();
                $document.off('mousemove', onMove);
                $document.off('mouseup', onEnd);
                scope.active = false;
                rangeCtrl.onMarkerChange();
            }


            rangeCtrl.registerMarker(function (markerData) {
                if (typeof markerData.id !== 'undefined') {
                    scope.markerId = markerData.id;
                }
                scope.disabled = markerData.disabled;
                scope.value = markerData.value;
                scope.left = markerData.offset;
            });

            element.on('mousedown', onStart);

        }
    };

}]);