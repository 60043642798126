
'use strict';
/**
 * @author Daniel Rosiak, Modest Machnicki
 */
angular.module('B2B').controller('ResetPasswordController', [
    '$scope', '$state', 'mm.core.auth.AuthService', 'mm.core.api.CommandFactory',
    function ($scope, $state, AuthService, CommandFactory) {

        if ($state.params.code) {
            $scope.loading = true;
            AuthService.verifyResetPasswordToken($state.params.code).then(function (user) {
                $scope.loading = false;
                $scope.user = user;
            }, function(){
                $scope.loading = false;
                $scope.error = 'Token wygasł lub jest nieprawidłowy.';
            });
        } else {
            $scope.error = 'Nieprawidłowy token.';
        }

        $scope.newPasswordCommand = CommandFactory.create('password', 'post', {
            token : $state.params.code
        }).onSuccess(function () {
            $scope.passwordChanged = true;
        });

    }]);
