/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ConfiguratorOfferForm', [
    '$timeout',
    '$rootScope',
    '$window',
    'mm.core.api.CommandFactory',
    'mm.core.auth.AuthService',
    'mm.core.dom.NoticeService',
    'CustomerService',
    function ($timeout,
              $rootScope,
              $window,
              CommandFactory,
              AuthService,
              NoticeService,
              CustomerService) {

        var ConfiguratorOfferForm = function () {
            this.id = null;
            this.toggled = false;
            this.configuratorSummary = null;
        };

        /**
         * Toggle form visibility
         */
        ConfiguratorOfferForm.prototype.toggle = function () {
            this.toggled = !this.toggled;
        };

        /**
         * Hide form
         */
        ConfiguratorOfferForm.prototype.hide = function () {
            this.toggled = false;
        };

        /**
         * @param {string} id
         * @returns {ConfiguratorOfferForm}
         */
        ConfiguratorOfferForm.prototype.setId = function (id) {
            this.id = id;
            this.buildCommand();
            return this;
        };

        /**
         * Set configurator summary object.
         *
         * @param {Object} summary
         */
        ConfiguratorOfferForm.prototype.setConfiguratorSummary = function (summary) {
            this.configuratorSummary = summary;
        };


        /**
         * Build form command
         */
        ConfiguratorOfferForm.prototype.buildCommand = function () {
            var address,
                company;

            this.command = CommandFactory.create('configurators/' + this.id + '/pdf', 'post', {
                sendMail: false,
                email: AuthService.data.user ? AuthService.data.user.email : '',
                hidePrice: false,
                useCalcPrice: false,
                attachTextData: false,
                header: '',
                footer: '',
                company: ''
            }, {
                successMessage: null
            }).onSuccess(function (pdfUrl) {
                $window.open(pdfUrl, '_blank');
            }).onError(function () {
                NoticeService.error('Wystąpił bład podczas generowania oferty.');
            });

            // Load command's company data
            if (CustomerService.customer.data && CustomerService.customer.data.address) {
                address = CustomerService.customer.data.address;
                company = address.name + '\n' + address.street;

                if (address.houseNo) {
                    company += ' ' + address.houseNo;
                }
                if (address.flatNo) {
                    company += '/' + address.flatNo;
                }
                company += '\n' + address.zipCode + ' ' + address.city;
                this.command.fields.company = company;
            }
        };

        /**
         * Execute command and download docuement.
         *
         * @param {string} format Document format
         */
        ConfiguratorOfferForm.prototype.execute = function (format) {
            this.command.uri = 'configurators/' + this.id + '/' + format;
            this.command.fields.calcPrice = this.configuratorSummary.calcPrice.net;
            this.command.execute();
        };


        return ConfiguratorOfferForm;
    }
]);