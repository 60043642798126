/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ComplaintInvoiceSuggester', [
    '$timeout', 'Grid',
    function ($timeout, Grid) {

        var timer,
            command,
            blockedWatcher = false,
            columns = {
                erpId: {
                    key: 'erpId',
                    searchable: true,
                    queryMatchType: 'like'
                },
                serialNumber: {
                    key: 'serialNumber',
                    searchable: true
                },
                productName: {
                    key: 'productName',
                    searchable: true,
                    queryMatchType: 'like'
                },
                partNumber: {
                    key: 'partNumber',
                    searchable: true
                },
                waybills: {
                    key: 'waybills',
                    searchable: true,
                }
            },
            fields = {
                INVOICE_ID: 'invoiceId',
                INVOICE_DATE: 'invoiceDate',
                SERIAL_NUMBER: 'productSerialNo',
                PRODUCT_NAME: 'productName',
                PART_NUMBER: 'productPn',
                WAYBILL: 'waybill'
            },
            disabledFields = [],
            suggestions = {
                loading: false,
                invoices: null,
                lines: null,
                serials: null,
                waybills: null,
                field: null
            },
            grid = (new Grid({
                stateParamsEnabled: false
            })).setResource('invoices').setItemsOnPage(5).setColumns([
                columns.erpId,
                columns.serialNumber,
                columns.productName,
                columns.partNumber,
                columns.waybills
            ]);

        function clearSuggestions() {
            angular.forEach(columns, function (column) {
                column.model = null;
            });
            grid.clearFilter('docDate');
            suggestions.loading = false;
            suggestions.invoices = null;
            suggestions.lines = null;
            suggestions.serials = null;
            suggestions.waybills = null;
            suggestions.field = null;
        }

        function setCommandField(field, value) {
            if (disabledFields.indexOf(field) === -1) {
                command.fields[field] = value;
            }
        }

        function buildSerialSuggestions(line) {
            var serialQuery = command.fields[fields.SERIAL_NUMBER] ?
                    command.fields[fields.SERIAL_NUMBER].toLowerCase() : '',
                serials = [];

            function parseLine(line) {
                var lineSerials = line.serialNumbers;
                if (serials.length < 5) {
                    if (serialQuery) {
                        lineSerials = line.serialNumbers.filter(function (serialNumber) {
                            return serialNumber.toLowerCase().indexOf(serialQuery) !== -1;
                        });
                    }
                    serials = serials.concat(
                        lineSerials.map(function (serialNumber) {
                            return {
                                value: serialNumber,
                                line: line
                            };
                        })
                    );
                }
            }

            if (line) {
                parseLine(line);
            } else if (suggestions.lines) {
                suggestions.lines.forEach(function (line) {
                    parseLine(line);
                });
            }
            suggestions.serials = serials.slice(0, 5);
        }

        function buildWaybillSuggestions(invoice) {
            var waybillQuery = command.fields[fields.WAYBILL],
                waybills = [];

            function parseInvoice(invoice) {
                var invoiceWaybills = invoice.waybills;
                if (waybills.length < 5) {
                    if (waybillQuery) {
                        invoiceWaybills = invoice.waybills.filter(function (waybill) {
                            return waybill.indexOf(waybillQuery) !== -1;
                        });
                    }
                    waybills = waybills.concat(
                        invoiceWaybills.map(function (waybill) {
                            return {
                                value: waybill,
                                invoice: invoice
                            };
                        })
                    );
                }
            }

            if (invoice) {
                parseInvoice(invoice);
            } else if (suggestions.invoices) {
                suggestions.invoices.forEach(function (invoice) {
                    parseInvoice(invoice);
                });
            }

            suggestions.waybills = waybills.slice(0, 5);
        }

        function searchInvoices(matchIdOnly) {
            var invoiceDate;
            if (command.fields[fields.INVOICE_ID]) {
                columns.erpId.model = command.fields[fields.INVOICE_ID];
            }
            if (!matchIdOnly) {
                if (command.fields[fields.INVOICE_DATE]) {
                    invoiceDate = command.fields[fields.INVOICE_DATE].getTime();
                    grid.setFilter('docDate', [invoiceDate, invoiceDate], 'range');
                }
                if (command.fields[fields.SERIAL_NUMBER]) {
                    columns.serialNumber.model = command.fields[fields.SERIAL_NUMBER];
                }
                if (command.fields[fields.PRODUCT_NAME]) {
                    columns.productName.model = command.fields[fields.PRODUCT_NAME];
                }
                if (command.fields[fields.PART_NUMBER]) {
                    columns.partNumber.model = command.fields[fields.PART_NUMBER];
                }
                if (command.fields[fields.WAYBILL]) {
                    columns.waybills.model = command.fields[fields.WAYBILL];
                }
            }

            grid.setParam('attachLineHits', true);
            return grid.loadPage(1);
        }

        function findInvoice(field, matchIdOnly) {
            $timeout.cancel(timer);
            timer = $timeout(function () {
                suggestions.field = field;
                suggestions.loading = true;
                searchInvoices(matchIdOnly).then(function (result) {
                    // Auto pick invoice, if there's one result in given date
                    if (field === fields.INVOICE_DATE && result.items.length === 1) {
                        pickInvoice(result.items[0]);
                    } else {
                        suggestions.invoices = result.items;
                        suggestions.lines = result.lines;
                        if (suggestions.invoices.length && (!suggestions.lines || !suggestions.lines.length)) {
                            suggestions.lines = [];
                            suggestions.invoices.forEach(function (invoice) {
                                if (invoice.lines && invoice.lines.length) {
                                    suggestions.lines = suggestions.lines.concat(invoice.lines);
                                }
                            });
                        }
                        buildSerialSuggestions();
                        buildWaybillSuggestions();

                        // If no invoice matches, try to find invoice basing on ID only
                        if (field === fields.INVOICE_ID && !matchIdOnly && !suggestions.invoices.length && command.fields[fields.INVOICE_ID]) {
                            clearSuggestions();
                            findInvoice(fields.INVOICE_ID, true);
                        }
                    }
                    suggestions.loading = false;
                });
            }, 200);
        }

        function pickInvoice(invoice, preventPickForwarding) {
            blockedWatcher = true;
            setCommandField(fields.INVOICE_ID, invoice.erpId);
            setCommandField(fields.INVOICE_DATE, new Date(invoice.docDate));
            suggestions.invoices = null;

            if (invoice.waybills.length === 1) {
                setCommandField(fields.WAYBILL, invoice.waybills[0]);
            }
            if (!preventPickForwarding) {
                if (invoice.lines.length === 1) {
                    pickProduct(invoice.lines[0], true);
                } else {
                    suggestions.lines = invoice.lines;
                    buildSerialSuggestions();
                    buildWaybillSuggestions(invoice);
                }
            }
            $timeout(function () {
                blockedWatcher = false;
            }, 100);
        };

        function pickProduct(product, preventPickForwarding) {
            var matchingInvoices;

            setCommandField(fields.PART_NUMBER, product.partNumber);
            setCommandField(fields.PRODUCT_NAME, product.name);
            suggestions.lines = null;

            if (product.serialNumbers && product.serialNumbers.length === 1) {
                setCommandField(fields.SERIAL_NUMBER, product.serialNumbers[0]);
            }
            if (!preventPickForwarding) {
                buildSerialSuggestions(product);
                if (suggestions.invoices) {
                    matchingInvoices = suggestions.invoices.filter(function (invoice) {
                        return invoice.lines.filter(function (line) {
                            return line.partNumber === product.partNumber;
                        }).length;
                    });
                    if (matchingInvoices.length === 1) {
                        pickInvoice(matchingInvoices[0], true);
                    }
                }
            }
        };

        function pickSerialNumber(serial) {
            setCommandField(fields.SERIAL_NUMBER, serial.value);
            pickProduct(serial.line);
            suggestions.serials = null;
        };

        function pickWaybill(waybill) {
            setCommandField(fields.WAYBILL, waybill.value);
            pickInvoice(waybill.invoice);
            suggestions.waybills = null;
        };

        function initScopeWatcher(scope) {
            // angular-datepicker doesn't support change callbacks properly
            scope.command = command;
            scope.$watch('command.fields.' + fields.INVOICE_DATE, function (value) {
                if (!blockedWatcher) {
                    if (!(value instanceof Date)) {
                        setCommandField(fields.INVOICE_DATE, '');
                        return;
                    }
                    // Clear invoice id field after date change
                    setCommandField(fields.INVOICE_ID, '');
                    clearSuggestions();
                    findInvoice(fields.INVOICE_DATE);
                }
            });
        };

        return {
            suggestions: suggestions,
            fields: fields,
            disable: function (fields) {
                if (typeof fields === 'string') {
                    disabledFields = [fields];
                } else {
                    disabledFields = fields;
                }
            },
            setCommand: function (_command) {
                command = _command;
            },
            onFieldChange: function (field) {
                clearSuggestions();
                findInvoice(field);
            },
            onFieldFocus: function (field) {
                $timeout.cancel(timer);
                suggestions.field = field;
            },
            onFieldBlur: function () {
                $timeout.cancel(timer);
                timer = $timeout(function () {
                    suggestions.field = null;
                }, 200);
            },
            onInvoiceFieldBlur: function () {
                if (command.fields[fields.INVOICE_ID] && suggestions.invoices.length === 1) {
                    pickInvoice(suggestions.invoices[0]);
                }
                this.onFieldBlur();
            },
            hasSuggestions: function (field, list) {
                if (suggestions[list]) {
                    if (command.fields[field] && suggestions[list].length > 1) {
                        return true;
                    }
                    if (!command.fields[field] && suggestions[list].length) {
                        return true;
                    }
                }
                return false;
            },
            pickInvoice: pickInvoice,
            pickProduct: pickProduct,
            pickSerialNumber: pickSerialNumber,
            pickWaybill: pickWaybill,
            initScopeWatcher: initScopeWatcher
        };
    }
]);
