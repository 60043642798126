/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('ScaffoldingService', function () {

    return {
        data : {
            mainTabLocked : false
        }
    };

});