'use strict';
/**
 * Mindsmill core Angular.js util package
 * @author Modest Machnicki
 */
angular.module('mm.core.api', []);
angular.module('mm.core.dom', []);
angular.module('mm.core.resource', []);
angular.module('mm.core', ['mm.core.api', 'mm.core.dom', 'mm.core.resource']);
angular.module('mm.socket', ['mm.core.api']);

angular.module('mm.core.dom', [])
    .provider('mmCoreDom', function () {

        // Default template paths
        this.templates = {
            dialog: '/views/core/dom/dialog.html',
            confirm: '/views/core/dom/confirm.html',
            notice: '/views/core/dom/notice.html'
        };

        this.$get = function () {
            return this;
        };
    });
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.api').factory('mm.core.api.API', [
    '$q', '$http', 'mm.core.api.HttpErrorService',
    function ($q, $http, HttpErrorService) {

        /**
         * Get default $http request config
         *
         * @param {string} method
         * @param {string} uri
         * @param {Object} data
         * @returns {{url: string, method: *, data: *}|*}
         */
        function buildDefaultConfig(method, uri, data, defer) {
            var isFormDataRequest = false,
                formData = new FormData(),
                deferredAbort = $q.defer(),
                config;

            // formData should be used only for FormData (multipart) requests
            if (data instanceof FormData) {
                isFormDataRequest = true;
                formData = data;
            } else {
                angular.forEach(data, function (value, key) {
                    if (value instanceof File) {
                        isFormDataRequest = true;
                        formData.append(key, value);
                    } else if (value !== null && value !== undefined) {
                        formData.append(key, typeof value === 'object' ? angular.toJson(value) : value);
                    }
                });
            }

            config = {
                url: '/api/' + uri,
                method: method,
                data: isFormDataRequest ? formData : data,
                timeout: deferredAbort.promise
            };

            if (isFormDataRequest) {
                config.transformRequest = angular.identity;
                config.headers = {'Content-Type': undefined};
                // Allow PHP side (Laravel) handle formData PUT requests
                if (method === 'PUT') {
                    config.method = 'POST';
                    config.data.append('_method', 'PUT');
                }
            }

            // Inject http request aborting method into promise result
            defer.promise.abort = function () {
                deferredAbort.resolve();
            };

            return config;
        }

        /**
         * Perform API request
         *
         * @param {string} method
         * @param {string} uri
         * @param {Object} data
         * @param {Object} [opts]
         * @returns {Promise}
         */
        function request(method, uri, data, opts) {
            var defer = $q.defer(),
                config = angular.extend({}, opts, buildDefaultConfig(method, uri, data, defer));

            if (method === 'GET' && data) {
                config.url += '?';
                angular.forEach(data, function (value, key) {
                    config.url += key + '=' + value + '&';
                });
            }

            $http(config).then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                HttpErrorService.error(angular.extend({}, config, {uri: uri}), {errors: response.data, code: response.status});
                defer.reject({errors: response.data, code: response.status});
            });

            return defer.promise;
        }


        return {
            get: function (uri, data, opts) {
                return request('GET', uri, data, opts);
            },
            post: function (uri, data, opts) {
                return request('POST', uri, data, opts);
            },
            delete: function (uri, data, opts) {
                return request('delete', uri, data, opts);
            },
            put: function (uri, data, opts) {
                return request('PUT', uri, data, opts);
            }
        };

    }]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.api').factory('mm.core.api.CommandFactory', [
    'mm.core.api.API', 'mm.core.dom.NoticeService', 'mm.core.api.HttpErrorService', 
    function (API, NoticeService, HttpErrorService) {

        /**
         * Create command instance
         *
         * @param {string} uri Resource URI
         * @param {string} method HTTP method
         * @param {object} [fields] Request parameters
         * @param {object} [opts] Command options
         * @constructor
         */
        var Command = function (uri, method, fields, opts) {
            this.uri = uri;
            this.method = method;
            this.defaultFields = fields || {};
            this.fields = angular.copy(this.defaultFields);
            this.errors = {};
            this.loading = false;
            this.callbacks = {
                onSuccess : [],
                onError : []
            };
            this.successMessage = 'Dane zostały zapisane.';

            if (opts) {
                if (typeof opts.successMessage !== 'undefined') {
                    this.successMessage = opts.successMessage;
                }
            }
        };

        /**
         * Make request to API with command data
         */
        Command.prototype.execute = function () {
            this.loading = true;
            return API[this.method](this.uri, this.fields).then(function (data) {
                this.clearErrors();
                for (var property in angular.copy(data)) {
                    if (typeof this.fields[property] !== 'undefined') {
                        this.fields[property] = data[property];
                    }
                }
                this.loading = false;
                if (this.successMessage) {
                    NoticeService.success(this.successMessage);
                }
                angular.forEach(this.callbacks.onSuccess, function(callback){
                    callback.call(this, data);
                }.bind(this));

                return data;
            }.bind(this), function (response) {
                this.clearErrors();
                this.loading = false;
                HttpErrorService.error({uri: this.uri, method: this.method, data: this.fields}, response);
                if (response.code === 422) {
                    angular.forEach(response.errors, function (message, command) {
                        this.errors[command] = message;
                    }.bind(this));
                } 
                /* else if (response.code === 403) {
                    NoticeService.error('Nie masz uprawnień do tej operacji.');
                } else {
                    NoticeService.error('Wystąpił błąd serwera.'); // obsłużyć
                }*/
                angular.forEach(this.callbacks.onError, function(callback){
                    callback(response);
                });
            }.bind(this));
        };

        /**
         * Remove all command data errors
         */
        Command.prototype.clearErrors = function () {
            this.errors = {};
        };

        /**
         * Clear command, set default fields values
         * @returns {Command}
         */
        Command.prototype.clear = function () {
            this.fields = angular.copy(this.defaultFields);

            return this;
        };

        /**
         * Add success callback
         *
         * @param {function} callback
         */
        Command.prototype.onSuccess = function (callback) {
            this.callbacks.onSuccess.push(callback);
            return this;
        };

        /**
         * Add error callback
         *
         * @param {function} callback
         */
        Command.prototype.onError = function (callback) {
            this.callbacks.onError.push(callback);
            return this;
        };

        return {
            /**
             * Return new command
             * @param {string} uri Resource URI
             * @param {string} method HTTP method
             * @param {object} [fields] Request parameters
             * @param {object} [opts] Command options
             * @returns {Command}
             */
            create: function (uri, method, fields, opts) {
                return new Command(uri, method, fields, opts);
            }
        };
    }
]);
/**
 * @author Daniel Rosiak
 */
'use strict';
angular.module('mm.core.api').factory('mm.core.api.HttpErrorService', [
    '$state', 'mm.core.dom.NoticeService',
    function ($state, NoticeService) {

        //@TODO: report errors to database / airbrake or smth

        /**
         * Do nothing when errors
         */
        var excluded = [];

        // sample excluded
        // excluded = [ 404, 422 ];

        /**
         * Actions on specific errors
         */
        var actions  = { };

        // sample actions
        /*
        actions = {
            
            403: function() {
                NoticeService.error('Nie masz uprawnień do tej operacji.');
            },
            500: function() {
                 $state.go('errors.500');
            },
            503: function() {
                 $state.go('errors.503');
            },
            default: function() {
                 NoticeService.error('Wystąpił błąd serwera.'); 
            }
        };
        */

        /**
         * Handle error
         * @param  {string} code Http error code
         * @return {void}
         */
        function handleAction(code) {
            //code is set in actions and is not excluded
            if(actions.hasOwnProperty(code) && excluded.indexOf(code) === -1 ) {
                actions[code]();
            }
            //if default action is set and
            //code is not set in actions and is not excluded
            else if(!actions.hasOwnProperty(code) && excluded.indexOf(code) === -1 && actions.hasOwnProperty('default')) {
                actions['default']();
            }
        }

        /**
         * TODO
         * @param  {object} request,
         * @param  {object} response
         * @return {void}
         */
        function handleReport(request, response) {

        }

        return {
            excluded: excluded,
            actions: actions,
            /**
             * Handle errors
             * @param {object} request {method, data, uri, url(optional)}
             * @param {object} response {code, errors}
             */
            error: function (request, response) {
                if(response && response.hasOwnProperty('code')) {
                    handleAction(response.code);
                }
                handleReport(request, response);
                return false;
            },
            /**
             * Exclude code from handling
             * @param  {string} code Http error code
             * @return {array} excluded
             */
            setExclude: function(code) {
                if( excluded.indexOf(code) === -1 ) {
                    excluded.push(code);
                }
                return excluded;
            },
            /**
             * Exclude array
             * @param  {array} excluded 
             * @return {array} excluded
             */
            setExcluded: function(_excluded) {
                excluded = _excluded;
                return excluded;
            },
            /**
             * Set action on code
             * @param  {string} code Http error code
             * @param  {function} handle
             * @return {actions}
             */
            setAction: function(code, handle) {
                actions[code] = handle;
                return actions;
            },
            /**
             * Set actions
             * @param  {object} actions
             * @return {actions}
             */
            setActions: function(_actions) {
                actions = _actions;
                return actions;
            }
        };
    }
]);
'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('mm.core').factory('mm.core.auth.AccessService', [
    '$q',
    'mm.core.api.API',
    function ($q, API) {

        var permissions = null;
        var roles = null;

        /**
         * Get App access permissions
         *
         * @returns {d.promise|promise|Q.promise|qFactory.Deferred.promise|m.ready.promise|fd.g.promise}
         */
        function getPermissions() {
            var deferred = $q.defer();
            if (permissions) {
                deferred.resolve(permissions);
            } else {
                API.get('access/permissions').then(function (_permissions) {
                    permissions = _permissions;
                    deferred.resolve(permissions);
                }, function () {
                    permissions = null;
                    deferred.reject();
                });
            }
            return deferred.promise;
        }

        /**
         * Get App access roles
         *
         * @returns {d.promise|promise|Q.promise|qFactory.Deferred.promise|m.ready.promise|fd.g.promise}
         */
        function getRoles() {
            var deferred = $q.defer();
            if (roles) {
                deferred.resolve(roles);
            } else {
                API.get('access/roles').then(function (_roles) {
                    roles = _roles;
                    deferred.resolve(roles);
                }, function () {
                    roles = null;
                    deferred.reject();
                });
            }
            return deferred.promise;
        }

        return {
            getPermissions : getPermissions,
            getRoles : getRoles
        };
    }
]);
'use strict';

angular.module('mm.core').factory('mm.core.auth.AuthService', [
    '$q',
    '$state',
    '$rootScope',
    '$timeout',
    'mm.core.api.API',
    'mm.core.auth.User',
    'mm.core.api.CommandFactory',
    function ($q, $state, $rootScope, $timeout, API, User, CommandFactory) {

        // cached auth data
        var data = {
                user: null,
                isLogged: false,
                isNotLogged: false
            },
            callbacks = {
                onUserBind: null
            },
            userBinded = false,
            /**
             * Get current logged user
             * @param {string} userType
             * @param {boolean} refresh Force API request
             *
             * @returns {d.promise|promise|Q.promise|qFactory.Deferred.promise|m.ready.promise|fd.g.promise}
             */
            getUser = (function () {
                var promise;

                return function (userType, refresh) {
                    if (promise) {
                        return promise;
                    }
                    promise = $q(function (resolve, reject) {
                        if (!userBinded || refresh) {
                            API.get('auth/user', {userType: userType}).then(function (user) {
                                bindUser(user);
                                resolve(data.user);
                                promise = null;
                            }).catch(function () {
                                bindUser(null);
                                reject();
                                promise = null;
                            });
                        } else if (data.user) {
                            resolve(data.user);
                            $timeout(function () {
                                promise = null;
                            });
                        } else {
                            reject();
                            $timeout(function () {
                                promise = null;
                            });
                        }
                    });

                    return promise;
                };
            })();

        /**
         * Bind User (or null - not logged) into the App.
         *
         * @param {Object|null} userData
         */
        function bindUser(userData) {
            if (userData) { //user is Logged
                data.user = User.build(userData);
                if (!data.isLogged) {
                    $rootScope.$broadcast('mm.auth.login', data.user);
                    $rootScope.$broadcast('mm.auth.changed');
                }
            } else {
                data.user = null;
                if (data.isLogged) {
                    $rootScope.$broadcast('mm.auth.logout');
                }
            }
            data.isLogged = userData ? true : false;
            data.isNotLogged = !data.isLogged;
            if (typeof callbacks.onUserBind === 'function') {
                callbacks.onUserBind(data);
            }
            userBinded = true;
        }

        /**
         * Logout request
         * @returns {Promise}
         */
        function signOut() {
            return API.post('auth/logout').then(function () {
                bindUser(null);
            });
        }

        // Get Laravel token.
        // Token will be auto injected as a cookie
        API.get('auth/token');

        return {
            data: data,
            getUser: getUser,
            bindUser: bindUser,
            /**
             * Set callback after each user (or null) binding
             *
             * @param {function} callback
             */
            onUserBind: function (callback) {
                callbacks.onUserBind = callback;
            },
            signIn: function (data, userType) {
                return API.post('auth/login', angular.extend({userType: userType}, data)).then(bindUser);
            },
            signOut: signOut,
            /**
             * Return sign-in command
             * @param {string} userType
             * @param {function} onSuccess Success callback
             * @returns {*}
             */
            signInCommand: function (userType, onSuccess) {
                return CommandFactory.create('auth/login', 'post', {userType: userType}, {
                    successMessage: null
                }).onSuccess(function (user) {
                    bindUser(user);
                    if (typeof onSuccess === 'function') {
                        onSuccess(data.user);
                    }
                });
            },
            signInSubUser: function (subUserId) {
                return API.post('auth/login/' + subUserId).then(function (user) {
                    bindUser(user);
                    return data.user;
                });
            },
            activate: function (code) {
                return API.post('auth/activate', {
                    code: code
                });
            },
            changePassword: function (data) {
                return API.post('auth/password', data);
            },
            requestResetPasswordToken: function (data) {
                return API.post('password/request', data);
            },
            verifyResetPasswordToken: function (code) {
                return API.post('password/verify', {
                    code: code
                });
            },
            setNewPasswordByReset: function (data, token) {
                return API.post('password', angular.extend({'token': token}, data));
            },
            /**
             * Save User setting
             * @param {string} key
             * @param {string} value
             * @returns {*}
             */
            saveSetting: function (key, value) {
                if (key && data.isLogged) {
                    return API.post('user/settings', {key: key, value: value}).then(function (user) {
                        bindUser(user);
                        return user;
                    });
                }
            },
            /**
             * Check user's permission - shortcut method.
             *
             * @param {string} permission
             * @returns {boolean}
             */
            access: function (permission) {
                if (data.user && data.user.hasAccess(permission)) {
                    return true;
                }
                return false;
            }
        };

    }
]);

/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').factory('mm.core.auth.SessionService', [
    '$rootScope',
    '$state',
    '$interval',
    'mm.core.auth.AuthService',
    'mm.core.api.HttpErrorService',
    function ($rootScope, $state, $interval, AuthService, HttpErrorService) {

        var userable,
            heartbeatInterval = 100000,
            expirationCallback = null;

        function interceptSessionExpiration() {
            if (AuthService.data.isLogged) {
                AuthService.getUser(userable, true).then(null, expirationCallback);
            }
        }

        /**
         * Check current auth user, handle session expiration and perform interval pings
         *
         * @param {Object} opts
         * @returns {Promise} Auth request
         */
        function init(opts) {
            userable = opts.userable;
            if (opts.onExpire && typeof opts.onExpire === 'function') {
                expirationCallback = opts.onExpire;
            }
            HttpErrorService.setAction(403, interceptSessionExpiration);
            $interval(interceptSessionExpiration, heartbeatInterval);

            return AuthService.getUser(userable);

            //TODO Maybe create idle manager and stop heartbeat requests when idle
        }

        return {
            init: init
        };
    }
]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core').factory('mm.core.auth.User', function () {

        var User = function (data) {
            this.id = parseInt(data.id);
            this.email = data.email;
            this.name = data.name;
            this.active = data.active;

            this.terms_accepted = data.terms_accepted;
            this.newsletter_accepted = data.newsletter_accepted;
            this.privacy_accepted = data.privacy_accepted;

            this.access = data.access || {};
            this.roles = data.roles || {};
            this.settings = data.settings || {};
            this.userable = data.userable;
        };

        /**
         * Determine if User has access to given permission
         *
         * @param {string} permission ID
         * @returns {boolean}
         */
        User.prototype.hasAccess = function (permission) {
            return !!this.access[permission];
        };

        /**
         * Determine if User has role of given name
         *
         * @param {string} role ID
         * @returns {boolean}
         */
        User.prototype.hasRole = function (role) {
            return !!this.roles[role];
        };

        User.build = function (data) {
            return new User(data);
        };

        return User;
    }
);

'use strict';

/**
 * Format boolean value as 'tak', 'nie'
 * @return {bool} bool value
 */
angular.module('mm.core').filter('boolean', function () {
    /**
     * @param {number} value
     */
    return function (value) {
        if (value)
            return 'Tak';
        else
            return 'Nie';
    };
});
'use strict';

/**
 * Return grammar form of word based on number parameter
 * @return {string} inflected word
 */
angular.module('mm.core').filter('inflect', function () {
    /**
     * @param {array} variety Inflect form of nouns, ex. ['produkt', 'produkty', 'produktów']
     * @param {number} number The size of variety
     */
    return function (variety, number) {
        var tmp;
        if (variety instanceof Array) {
            number = parseInt(number);
            if (number === 1) {
                return variety[0];
            }
            tmp = number % 100;
            if (tmp <= 21 && tmp >= 5) {
                return variety[2];
            } else if (tmp % 10 >= 2 && tmp % 10 <= 4) {
                return variety[1];
            }
            return variety[2];
        }
        return null;
    };
});
'use strict';

/**
 * Format number as price (string) - with PLN unit as a default
 * @return {string} price value
 */
angular.module('mm.core').filter('price', function () {
    /**
     * @param {number} value
     * @param {string} [currency]
     */
    return function (value, currency) {
        var price, decimalPart;

        currency = currency || 'PLN';

        if (typeof value === 'number') {
            price = value.toLocaleString("pl-PL");
            decimalPart = price.split(",")[1];
            if (decimalPart && decimalPart.length == 1) {
                price += "0";
            }
            return price += ' ' + currency;
        }
        return;
    };
});
/**
 * Allow to handle file input value change with custom function
 *
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core').directive('onFileLoad', function () {

    return {
        restrict: 'A',
        scope: {
            onFileLoad : '&'
        },
        link: function (scope, element, attrs) {
            element.bind('change', function (e) {
                scope.onFileLoad({files: e.target.files});
            });
        }
    };

});
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core').factory('mm.core.location.LocationService', [
    'mm.core.api.API',
    function (API) {
        return {
            getCountries: function () {
                return API.get('countries', null, { cache:true });
            },
            getProvinces: function (countryId) {
                return API.get('countries/' + countryId + '/provinces', null, { cache:true });
            }
        };
    }
]);
/**
 * @author Daniel Rosiak
 */
'use strict';
angular.module('mm.core').factory('mm.core.routing.BreadcrumbService', function($state) {

    var breadcrumbs = [];

    /**
     * Start with the current state and traverse up the path to build the
     * array of breadcrumbs that can be used in an ng-repeat in the template.
     */
    function refreshBreadcrumbsArray() {

        breadcrumbs = [];
        var currentState = $state.$current;

        while (currentState && currentState.name !== '') {

            if (currentState.breadcrumb) {

                var displayName = getDisplayName(currentState);
                var route = getRoute(currentState);
                if (displayName !== false) {
                    breadcrumbs.push({
                        displayName: displayName,
                        route: route,
                        abstract: currentState.abstract ? true : false
                    });
                }
            }

            currentState = currentState.parent;
        }
        breadcrumbs.reverse();
    }


    function getRoute(currentState) {

        if (!currentState.breadcrumb.state) {
            return currentState.name;
        }

        return currentState.breadcrumb.state;
    }

    /**
     * Resolve the displayName of the specified state. Take the property specified by the `displayname-property`
     * attribute and look up the corresponding property on the state's config object. If the string found begins with
     * a colon eg `:username`, that signifies that the displayName should be provided by the result of the named `resolve` property.
     * @param currentState
     * @returns {*}
     */
    function getDisplayName(currentState) {

        var resolveProperty;
        var displayName;

        if (currentState.breadcrumb.title.indexOf(':') === 0) {
            // the : syntax indicates a reference to a resolved property, so use that instead
            resolveProperty = currentState.breadcrumb.title.substr(1);
            displayName = currentState.locals.globals[resolveProperty];
        } else {
            displayName = currentState.breadcrumb.title;
        }

        return displayName;
    }

    return {
        list: function() {
            if(!breadcrumbs.length) {
                refreshBreadcrumbsArray();
            }
            return breadcrumbs;
        },
        refresh: function() {
            refreshBreadcrumbsArray();
        }
    };
});
'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('mm.socket').factory('mm.socket.SocketService', [
    '$rootScope', '$q', '$timeout', 'mm.core.api.API',
    function ($rootScope, $q, $timeout, API) {

        var socket = null,
            deferred = null,
            /**
             * Connect to WebSocket and authorize with JWT.
             *
             * @returns {Promise}
             */
            getSocket = function () {
                if (!deferred) {
                    deferred = $q.defer();

                    if (!socket) {
                        socket = io.connect('//' + document.domain + ':3000', {reconnection: false});
                    } else if (socket.disconnected) {
                        socket.connect();
                    }
                    authenticate(socket).then(function () {
                        deferred.resolve(socket);
                    });
                }

                return deferred.promise;
            },
            /**
             * Disconnect current socket, but keep socket instance.
             */
            disconnect = function () {
                if (socket) {
                    socket.disconnect();
                    $rootScope.$broadcast('mm.socket.disconnected');
                }
                deferred = null;
            };


        /**
         * //TODO Remove after creating JWT Auth service
         * @param {Object} socket
         * @returns {Promise}
         */
        function authenticate(socket) {
            return API.get('jwt').then(function (token) {
                if (token) {
                    socket.emit('authentication', {token: token});
                }
            });
        }


        // Handle socket connecting after auth state changes
        $rootScope.$on('mm.auth.logout', function () {
            disconnect();
        });
        $rootScope.$on('mm.auth.login', function () {
            if (socket) {
                if (socket.disconnected) {
                    socket.connect();
                }
                authenticate(socket);
            }
        });

        return {
            /**
             * @param {string|Array} event
             * @param {function} callback
             */
            on: function (event, callback) {
                getSocket().then(function (socket) {
                    if (angular.isArray(event)) {
                        angular.forEach(event, function (e) {
                            socket.on(e, function (data) {
                                $timeout(function () {
                                    callback(data);
                                });
                            });
                        });
                    } else {
                        socket.on(event, function (data) {
                            $timeout(function () {
                                callback(data);
                            });
                        });
                    }
                });
            },
            /**
             * Connect current socket or create new one.
             */
            connect: function () {
                getSocket();
            },
            disconnect: disconnect
        };

    }
]);
/**
 * Move array element <index> to new position <newIndex>
 */
Object.defineProperty(Array.prototype, 'move', {
    enumerable: false,
    value: function (index, newIndex) {
        if (newIndex >= this.length) {
            var k = newIndex - this.length;
            while ((k--) + 1) {
                this.push(undefined);
            }
        }
        this.splice(newIndex, 0, this.splice(index, 1)[0]);
        return this;
    }
});
'use strict';
angular.module('mm.core').factory('mm.core.utils.RecursionHelper', ['$compile', function($compile){
    return {
        /**
         * Manually compiles the element, fixing the recursion loop.
         * @param element
         * @param [link] A post-link function, or an object with function(s) registered via pre and post properties.
         * @returns An object containing the linking functions.
         */
        compile: function(element, link){
            // Normalize the link parameter
            if(angular.isFunction(link)){
                link = { post: link };
            }

            // Break the recursion loop by removing the contents
            var contents = element.contents().remove();
            var compiledContents;
            return {
                pre: (link && link.pre) ? link.pre : null,
                /**
                 * Compiles and re-adds the contents
                 */
                post: function(scope, element){
                    // Compile the contents
                    if(!compiledContents){
                        compiledContents = $compile(contents);
                    }
                    // Re-add the compiled contents to the element
                    compiledContents(scope, function(clone){
                        element.append(clone);
                    });

                    // Call the post-linking function, if any
                    if(link && link.post){
                        link.post.apply(null, arguments);
                    }
                }
            };
        }
    };
}]);
/**
 * @author Modest Machnicki
 *
 * Allow to generate random uuid keys and store/index data by uuid in internal memory (object)
 */
'use strict';
angular.module('mm.core').factory('mm.core.utils.uuid', function () {

    var storage = {};

    storage.main = {};

    return {
        /**
         * Generate new uuid key
         *
         * @returns {string} uuid key
         */
        new: function () {
            function _p8(s) {
                var p = (Math.random().toString(16) + '000000000').substr(2, 8);
                return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
            }

            return _p8() + _p8(true) + _p8(true) + _p8();
        },
        /**
         * Return empty uuid key (format)
         *
         * @returns {string}
         */
        empty: function () {
            return '00000000-0000-0000-0000-000000000000';
        },
        /**
         * Store data object in local memory indexed by uuid keys
         *
         * @param {object} data
         * @param {string} [type] Optional data category
         * @returns {string} uuid key
         */
        store: function (data, type) {
            var uuid = this.new(),
                index = type ? type : 'main';
            if (typeof storage[index] === 'undefined') {
                storage[index] = {};
            }
            storage[index][uuid] = data;
            return uuid;
        },
        /**
         * Return stored data
         *
         * @param {string} uuid
         * @param {string} [type] Optional data category
         * @returns {object}
         */
        get: function (uuid, type) {
            var index = type ? type : 'main';
            if (storage[index]) {
                return storage[index][uuid];
            }
            return undefined;
        },
        /**
         * Clear all stored data
         *
         * @param {string} [type] Data category to be remove
         */
        clear : function (type) {
            storage[type ? type : 'main'] = {};
        }
    };
});
'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('mm.core').directive('compileHtml', function ($compile) {
    return function (scope, element, attrs) {
        scope.$watch(
            function (scope) {
                return scope.$eval(attrs.compileHtml);
            },
            function (value) {
                element.html(value);
                $compile(element.contents())(scope);
            }
        )
    };
});
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').factory('mm.core.dom.ConfirmDialog', function () {

        /**
         * @param {string} message
         * @param {function} onConfirm
         * @param {function} onDeny
         * @constructor
         */
        var ConfirmDialog = function (message, onConfirm, onDeny) {
            this.message = message;
            this.onConfirm = onConfirm;
            this.onDeny = onDeny;
            this.shown = false;
        };

        /**
         * Show confirm
         *
         * @returns {ConfirmDialog}
         */
        ConfirmDialog.prototype.show = function () {
            this.shown = true;
            return this;
        };

        /**
         * Perform confirm action
         *
         * @returns {ConfirmDialog}
         */
        ConfirmDialog.prototype.confirm = function () {
            if (this.onConfirm) {
                this.onConfirm();
                this.shown = false;
            }
            return this;
        };

        /**
         * Perform deny action
         *
         * @returns {ConfirmDialog}
         */
        ConfirmDialog.prototype.deny = function () {
            if (this.onDeny) {
                this.onDeny();
                this.shown = false;
            }
            return this;
        };

        return ConfirmDialog;
    }
);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').factory('mm.core.dom.ConfirmDialogService', [
    '$q', 'mm.core.dom.ConfirmDialog',
    function ($q, ConfirmDialog) {

        var confirmDialogs = [];

        return {
            confirmDialogs: confirmDialogs,
            /**
             * Show confirm dialog.
             * Returns promise, where resolve - confirmed, reject - denied
             *
             * @param {string} message
             * @returns {Promise}
             */
            confirm: function (message) {
                var deferred = $q.defer(),
                    dialog = new ConfirmDialog(message, deferred.resolve, deferred.reject);

                confirmDialogs.push(dialog);
                dialog.show();

                return deferred.promise;
            }
        };

    }
]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').factory('mm.core.dom.Dialog', function () {

        var Dialog = function (data) {
            this.header = data.header;
            this.content = data.content;
            this.onCloseCallback = null;
            this.shown = false;
        };

        /**
         * Show dialog
         *
         * @returns {Dialog}
         */
        Dialog.prototype.show = function () {
            this.shown = true;
            return this;
        };

        /**
         * Set on close callback
         *
         * @param {function} callback
         * @returns {Dialog}
         */
        Dialog.prototype.onClose = function (callback) {
            this.onCloseCallback = callback;
            return this;
        };

        /**
         * Create Dialog instance form data object
         *
         * @param data
         * @returns {Dialog}
         */
        Dialog.build = function (data) {
            return new Dialog(data);
        };

        return Dialog;
    }
);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').factory('mm.core.dom.DialogService', [
    '$rootScope', '$document', 'mm.core.dom.Dialog',
    function ($rootScope, $document, Dialog) {

        var wrapperElement,
            openedDialogCount = 0,
            customDialogs = [],
            confirmDialogs = [];


        /**
         * Close last opened (top) dialog
         */
        function closeTopDialog() {
            $rootScope.$broadcast('dialog.close.top', openedDialogCount);
        }

        /**
         * Close last opened (top) dialog on ESC keydown
         */
        $document.on('keydown', function (e) {
            if (e.which === 27) {
                closeTopDialog();
            }
        });

        return {
            customDialogs: customDialogs,
            /**
             * Add custom dialog object to DOM
             *
             * @param {Object} data
             * @param {boolean} Determine if Dialog should be removed from DOM after close
             * @returns {Dialog}
             */
            addDialog: function (data, removeOnClose) {
                var dialog = Dialog.build(data);
                customDialogs.push(dialog);
                if (removeOnClose) {
                    dialog.onClose(function () {
                        customDialogs.splice(customDialogs.indexOf(dialog), 1);
                    });
                }
                return dialog;
            },
            confirm: function (message) {

            },
            /**
             * @param element Wrapper DOM element
             */
            registerWrapper: function (element) {
                wrapperElement = element;
            },
            /**
             * Move dialog DOM element into dialog wrapper
             *
             * @param {object} element DOM dialog element
             */
            registerDialog: function (element) {
                if (wrapperElement) {
                    wrapperElement.append(element);
                }
            },
            /**
             * Remove dialog from wrapper.
             * @param {object} element
             */
            unregisterDialog: function (element) {
                element.remove();
            },
            /**
             * Increment opened dialogs count
             */
            dialogOpened: function () {
                openedDialogCount++;
                $rootScope.$broadcast('dialog.count.changed', openedDialogCount);
                return openedDialogCount;
            },
            /**
             * Decrement opened dialogs count
             */
            dialogClosed: function () {
                openedDialogCount--;
                if (openedDialogCount < 0) {
                    openedDialogCount = 0;
                }
                $rootScope.$broadcast('dialog.count.changed', openedDialogCount);
            },
            closeTopDialog: closeTopDialog
        };

    }
]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').directive('mmDialogWrapper', [
    'mm.core.dom.DialogService', 'mm.core.dom.ConfirmDialogService',
    function (DialogService, ConfirmDialogService) {

        return {
            restrict: 'E',
            template: '<div id="mm-dialog-wrapper" ng-show="showWrapper">' +
            '<div class="mm-dialog-bg"  ng-style="{\'z-index\': zIndex}" ng-click="closeTopDialog()"></div>' +
            '<mm-dialog ng-repeat="dialog in customDialogs" header="{{:: dialog.header }}" show="dialog.shown"' +
            'on-close="dialog.onCloseCallback">' +
            '{{:: dialog.content }}' +
            '</mm-dialog>'+
            '<mm-confirm ng-repeat="dialog in confirmDialogs" dialog="dialog"></mm-confirm>' +
            '</div>',
            replace: true,
            transclude: true,
            scope: {},
            link: function (scope, element) {
                DialogService.registerWrapper(element);

                scope.customDialogs = DialogService.customDialogs;
                scope.closeTopDialog = DialogService.closeTopDialog;
                scope.confirmDialogs = ConfirmDialogService.confirmDialogs;

                scope.$on('dialog.count.changed', function (e, count) {
                    scope.zIndex = 99+count*2;
                    scope.showWrapper = !!count;
                });
            }
        };

    }
]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').directive('mmConfirm', ['mmCoreDom', function (mmCoreDom) {

    return {
        restrict: 'E',
        templateUrl: mmCoreDom.templates.confirm,
        replace: false,
        scope : {
            dialog : '='
        }
    };

}]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').directive('mmDialog', ['mm.core.dom.DialogService', '$window', '$timeout', 'mmCoreDom',
    function (DialogService, $window, $timeout, mmCoreDom) {

        return {
            restrict: 'E',
            templateUrl: mmCoreDom.templates.dialog,
            transclude: true,
            scope: {
                show: '=',
                header: '@',
                onOpen: '&',
                onClose: '&'
            },
            link: function (scope, element, attributes) {

                var dialogNumber = null;

                // fixed=true allows to set dialog fixed position style property
                if (attributes.fixed) {
                    scope.fixed = true;
                }

                /**
                 * Open dialog, set current dialog number and dialogs stack z-index
                 */
                function openDialog() {
                    dialogNumber = DialogService.dialogOpened();
                    scope.zIndex = 100 + dialogNumber * 2;
                    if (!scope.fixed) {
                        scope.marginTop = $window.scrollY;
                    }
                    scope.onOpen();
                }

                scope.closeDialog = function () {
                    scope.show = false;
                    dialogNumber = null;
                    scope.onClose();
                };

                DialogService.registerDialog(element);

                if (scope.show) {
                    $timeout(openDialog);
                }
                scope.$watch('show', function (currentVal, recentVal) {
                    if (currentVal === true && !recentVal) { //open dialog
                        openDialog();
                    } else if (currentVal === false && recentVal === true) { //close dialog
                        DialogService.dialogClosed();
                    }
                });

                /**
                 * Handle top dialog close event
                 * Close dialog if it's on the top of dialogs stack
                 */
                scope.$on('dialog.close.top', function (e, topDialogNumber) {
                    if (dialogNumber === topDialogNumber) {
                        $timeout(scope.closeDialog);
                    }
                });

                /**
                 * Remove dialog with scope destroy
                 */
                scope.$on('$destroy', function () {
                    DialogService.unregisterDialog(element);
                    if (scope.show) {
                        DialogService.dialogClosed();
                    }
                });

            }
        };

    }
]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').directive('mmDraggable', function () {

    // Draggable data object
    var dragData;

    return {
        restrict: 'A',
        scope: {
            dragData: '=',
            dropData: '=',
            onDrop: '&',
            validator: '&'
        },
        link: function (scope, element, attrs) {
            var el = element[0];

            /**
             * Bind dragg event to element
             */
            function bindDraggable() {
                el.draggable = true;

                el.addEventListener(
                    'dragstart',
                    function (e) {
                        e.dataTransfer.effectAllowed = 'move';
                        if (scope.dragData) {
                            dragData = scope.dragData;
                            e.dataTransfer.setData('mmDraggable', true); // FF works only with data
                        }
                        this.classList.add('drag');
                        return false;
                    },
                    false
                );

                el.addEventListener(
                    'dragend',
                    function (e) {
                        this.classList.remove('drag');
                        return false;
                    },
                    false
                );
            }

            /**
             * Validate if draggable item can be dropped
             *
             * @returns {boolean}
             */
            function validateDrop() {
                if (angular.isDefined(attrs.validator)) {
                    return scope.validator({dragData: dragData, dropData: scope.dropData});
                }
                return true;
            }

            /**
             * Bind drop event to element
             */
            function bindDroppable() {
                el.addEventListener(
                    'dragover',
                    function (e) {
                        if (validateDrop()) {
                            e.dataTransfer.dropEffect = 'move';
                            // allows us to drop
                            if (e.preventDefault) {
                                e.preventDefault();
                            }
                            this.classList.add('over');
                        }
                        return false;
                    },
                    false
                );

                el.addEventListener(
                    'dragenter',
                    function (e) {
                        if (validateDrop()) {
                            this.classList.add('over');
                            return false;
                        }
                    },
                    false
                );

                el.addEventListener(
                    'dragleave',
                    function (e) {
                        this.classList.remove('over');
                        return false;
                    },
                    false
                );

                el.addEventListener(
                    'drop',
                    function (e) {
                        // Stops some browsers from redirecting.
                        e.preventDefault();
                        if (e.stopPropagation) {
                            e.stopPropagation();
                        }

                        this.classList.remove('over');

                        if (angular.isDefined(attrs.onDrop) && validateDrop()) {
                            scope.onDrop({dragData: dragData, dropData: scope.dropData});
                            scope.$apply();
                        }

                        return false;
                    },
                    false
                );
            }

            //Init dragg and drop

            if (scope.dragData) {
                bindDraggable();
            }

            if (angular.isDefined(attrs.onDrop)) {
                bindDroppable();
            }

        }
    };

});
'use strict';
/**
 * @author Daniel Rosiak
 */
angular.module('mm.core')
    .directive('mmWysiwyg', function ($compile) {

        return {
            restrict: 'E',
            replace: 'true',
            template: '<textarea></textarea>',
            link: function (scope, element, attrs) {
                var options = {language: 'pl', uiColor: '#ffffff', filebrowserBrowseUrl: '/api/elfinder/ckeditor', entities_additional: ''};
                var userOptions = attrs.wysiwygOptions ? scope.$eval(attrs.wysiwygOptions) : {};
                element[0].setAttribute('ckeditor', JSON.stringify( angular.extend(options, userOptions)) );
                //TODO: currenly duplicates bindVarModel functionality, because ckeditor directive requires ng-model,
                // and bindVarModel was doubling the ng-model causing angular to throw a exception
                element[0].setAttribute('ng-model', scope.$eval(attrs.wysiwygModel));
                $compile(element[0])(scope);
            }
        };
    });
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').directive('mmNotice', [
    'mm.core.dom.NoticeService', 'mmCoreDom', function (NoticeService, mmCoreDom) {

        return {
            restrict: 'E',
            templateUrl: mmCoreDom.templates.notice,
            replace: true,
            scope: {},
            link: function (scope) {
                scope.noticeList = NoticeService.noticeList;
                scope.remove = NoticeService.removeNotice;
            }
        };

    }]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').factory('mm.core.dom.NoticeService', ['$timeout', function ($timeout) {

    var noticeList = [];

    return {
        noticeList : noticeList,
        notice : function (message, type, icon) {
            var notice;
            icon = icon || 'typcn typcn-info-large';
            notice = {message : message, type : type, icon : icon};
            noticeList.push(notice);
            $timeout(function(){
                this.removeNotice(notice);
            }.bind(this), 4000);
        },
        error : function (message) {
            this.notice(message, 'error', 'typcn typcn-cancel');
        },
        success : function (message) {
            this.notice(message, 'success', 'typcn typcn-tick');
        },
        warning : function (message) {
            this.notice(message, 'warning', 'typcn typcn-warning');
        },
        removeNotice : function (notice) {
            var index = noticeList.indexOf(notice);
            if (index !== -1) {
                noticeList.splice(index, 1);
            }
        }
    };

}]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.dom').directive('mmTooltip', function () {

    return {
        restrict: 'E',
        template: '<div class="mm-tooltip" ng-mouseover="show()" ng-mouseleave="hide()">' +
                '<div ng-if="contentShown" class="content" ng-transclude></div>' +
            '</div>',
        replace: true,
        transclude: true,
        scope : true,
        link: function (scope) {
            scope.show = function() {
                scope.contentShown = true;
            };
            scope.hide = function() {
                scope.contentShown = false;
            };
        }
    };

});
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.resource').factory('mm.core.resource.Grid', [
    'mm.core.api.API', '$q', '$timeout', '$location', '$state', '$filter', 'mm.core.resource.GridFilter',
    function (API, $q, $timeout, $location, $state, $filter, GridFilter) {

        var Grid = function (opts) {

            this.opts = {
                stateParamsEnabled: true
            };
            angular.extend(this.opts, opts);

            this.data = {
                columns: [],
                loading: false,
                items: [],
                pagination: {}
            };

            this.query = null;

            this.filters = {};

            this.resource = null;

            this.beforePageLoadCallback = [];
            this.onPageLoadCallback = [];

            // Additional request params
            this.params = {};

            this.searcher = {
                timer: null,
                performSearch: function () {
                    $timeout.cancel(this.timer);
                    this.timer = $timeout(function () {
                        this.loadPage(1);
                    }.bind(this), 300);
                }.bind(this)
            };

        };

        /**
         * @param {Object} [opts]
         * @returns {Object}
         */
        Grid.prototype.buildRequestParams = function (opts) {
            var params = {};
            opts = opts || {};

            if (this.data.sorting) {
                params.sort = this.data.sorting.column + ':' + this.data.sorting.direction;
            }

            params.page = opts.page ? opts.page : (this.data.pagination.page ? this.data.pagination.page : 1);

            if (this.data.pagination.itemsOnPage) {
                params.itemsOnPage = this.data.pagination.itemsOnPage;
            }

            params.scopeQuery = this.buildRequestScopeQueryParam();

            params.filters = this.buildRequestFiltersParam();

            if (this.query) {
                params.query = this.query;
            }

            if (this.params) {
                angular.extend(params, this.params);
            }

            return params;
        };

        /**
         * @returns {string}
         */
        Grid.prototype.buildRequestScopeQueryParam = function () {
            var columnFilters = this.data.columns.filter(function (column) {
                return column.searchable && column.model;
            }).map(function (column) {
                var param = (column.searchKey ? column.searchKey : column.key) + ':' + column.model;

                if (column.queryMatchType) {
                    param += ':' + column.queryMatchType;
                }

                return param;
            });

            return columnFilters.join(';');
        };

        /**
         * @returns {string}
         */
        Grid.prototype.buildRequestFiltersParam = function () {
            var setFilters = [];
            angular.forEach(this.filters, function (filter) {
                setFilters.push(filter);
            });
            var columnFilters = this.data.columns.filter(function (column) {
                return column.filter && column.model;
            });
            angular.forEach(columnFilters, function (column) {
                setFilters.push(new GridFilter(column.key, column.model, column.filter));
            });
            return setFilters.join(';');
        };


        /**
         * Read filters from query param.
         *
         * @param {string} filters
         */
        Grid.prototype.loadFiltersParams = function (filters) {
            angular.forEach(filters.split(';'), function (filterQuery) {
                var filter = new GridFilter(filterQuery);
                this.filters[filter.key] = filter;
            }.bind(this));
        }

        var checkIfColumnExists = function (columns, column) {
            var index = false;
            angular.forEach(columns, function (_column, _index) {
                if (_column.name === column.name && _column.key === column.key) {
                    index = _index;
                }
            });
            return index;
        };

        /**
         * @returns {string}
         */
        Grid.prototype.getUrl = function () {
            var query = '';

            angular.forEach(this.buildRequestParams(), function (value, key) {
                query += key + '=' + value + '&';
            });

            return this.resource + '?' + query;
        };

        /**
         * Receive items grid data from server
         * @param {number} [page]
         */
        Grid.prototype.loadPage = function (page) {
            // Allow call requests multiple times and avoid response conflicts
            this.pageRequests = this.pageRequests || {};
            if (!page) {
                page = this.data.pagination.page ? this.data.pagination.page : 1;
            }
            if (this.pageRequests[page]) {
                this.pageRequests[page].abort();
            }
            this.data.loading = true;
            this.pageRequests[page] = API.get(this.resource, this.buildRequestParams({page: page}));


            angular.forEach(this.beforePageLoadCallback, function (callback) {
                callback(this.data);
            }.bind(this));

            return this.pageRequests[page].then(function (result) {
                this.data.items = result.items ? result.items : result;
                this.data.pagination.page = parseInt(result.page);
                this.data.pagination.pages = parseInt(result.pages);
                this.data.pagination.itemsOnPage = parseInt(result.itemsOnPage);
                this.data.pagination.total = parseInt(result.total);
                this.data.loading = false;
                angular.forEach(this.onPageLoadCallback, function (callback) {
                    callback(this.data, result);
                }.bind(this));
                if (this.opts.stateParamsEnabled) {
                    this.updateState();
                }
                delete this.pageRequests[page];
                return result;
            }.bind(this), function () {
                return $q.reject();
            });
        };

        /**
         * Trigger searching (inc. timeout)
         */
        Grid.prototype.search = function () {
            this.searcher.performSearch();
        };


        Grid.prototype.setResource = function (_resource) {
            this.resource = _resource;
            return this;
        };

        Grid.prototype.setFilter = function (key, value, operator) {
            this.filters[key] = new GridFilter(key, value, operator);
            return this;
        };

        /**
         * Check if filter exists and optionally compare its value.
         *
         * @param {string} key
         * @param {string} [value]
         * @returns {boolean}
         */
        Grid.prototype.hasFilter = function (key, value) {
            if (this.filters[key]) {
                if (value !== undefined && this.filters[key].value === value) {
                    return true;
                }
                if (value === undefined) {
                    return true;
                }
            }
            return false;
        };

        Grid.prototype.clearFilter = function (key) {
            delete this.filters[key];
            return this;
        };

        Grid.prototype.setColumns = function (columns) {
            this.data.columns = columns;
            return this;
        };

        Grid.prototype.addColumn = function (_column) {
            if (!checkIfColumnExists(this.data.columns, _column)) {
                this.data.columns.push(_column);
            }
            return this;
        };

        Grid.prototype.removeColumn = function (_column) {
            var index = checkIfColumnExists(this.data.columns, _column);
            if (index) {
                this.data.columns.splice(index, 1);
            }
            return this;
        };

        Grid.prototype.changeSort = function (column, direction) {
            this.setSort(column, direction);
            return this.loadPage();
        };

        Grid.prototype.setSort = function (column, direction) {
            this.data.sorting = column ? {'column': column, 'direction': direction ? direction : 'asc'} : null;
            return this;
        };

        Grid.prototype.setQuery = function (query) {
            this.query = query ? query : null;
            return this;
        };

        Grid.prototype.clearQuery = function () {
            this.query = null;
            return this;
        };

        /**
         * Allow to add custom query param
         *
         * @param {string} param
         * @param {string} value
         * @returns {Grid}
         */
        Grid.prototype.setParam = function (param, value) {
            this.params[param] = value;
            return this;
        };

        /**
         * Remove custom query param
         *
         * @param {string} param
         * @returns {Grid}
         */
        Grid.prototype.clearParam = function (param) {
            delete this.params[param];
            return this;
        };

        /**
         * Set number of items on page
         * @param {number} itemsOnPage
         */
        Grid.prototype.setItemsOnPage = function (itemsOnPage) {
            this.data.pagination.itemsOnPage = parseInt(itemsOnPage);
            return this;
        };

        /**
         * @param {function} callback
         * @returns {Grid}
         */
        Grid.prototype.onPageLoad = function (callback) {
            if (typeof callback === 'function') {
                this.onPageLoadCallback.push(callback);
            }
            return this;
        };

        /**
         * @param {function} callback
         * @returns {Grid}
         */
        Grid.prototype.beforePageLoad = function (callback) {
            if (typeof callback === 'function') {
                this.beforePageLoadCallback.push(callback);
            }
            return this;
        };

        /**
         * Load grid data from state (query) params.
         *
         * @returns {Grid}
         */
        Grid.prototype.loadState = function () {
            var params = $location.search();

            this.data.pagination.page = params.page ? params.page : 1;
            if (params.filters) {
                this.loadFiltersParams(params.filters);
            }

            //TODO read sorting and another params

            return this;
        };

        /**
         * Build state params object.
         *
         * @returns {Object}
         */
        Grid.prototype.getStateParams = function () {
            return this.buildRequestParams();
        };

        /**
         * Rebuild state URI basing on grid settings
         */
        Grid.prototype.updateState = function () {
            $timeout(function () {
                $state.current.reloadOnSearch = false;
                $location.search(this.getStateParams());
                $timeout(function () {
                    $state.current.reloadOnSearch = undefined;
                });
            }.bind(this));
        };


        /**
         * Return item-record cell value
         * @param column
         * @param item
         * @returns {*}
         */
        Grid.prototype.getCellValue = function (column, item) {
            var value = _.get(item, column.valueKey ? column.valueKey : column.key),
                options = column.options || {};

            if (column.value && typeof column.value === 'function') {
                return column.value(item);
            }
            if ((!column.key || column.key === '') && (!column.value || column.value === '')) {
                return ''; // Optimize to not crash browser
            }
            if (column.type) {
                if (column.type === 'date') {
                    return (new Date(value)).toLocaleDateString();
                }
                if (column.type === 'select' && !angular.isArray(column.values)) {
                    return column.values[value];
                }
                if (column.type === 'price') {
                    return $filter('price')(value, options.currencyKey ? _.get(item, options.currencyKey) : null);
                }
            }
            return value;
        };

        /**
         * Read state data and load initial grid result.
         */
        Grid.prototype.init = function () {
            this.data.items = [];
            if (this.opts.stateParamsEnabled) {
                this.loadState();
            }
            this.loadPage();
        };

        return Grid;
    }
]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.resource').factory('mm.core.resource.GridFactory', [
    'mm.core.resource.Grid',
    function (Grid) {

        return {
            create: function (opts) {
                return new Grid(opts);
            }
        };
    }
]);
/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('mm.core.resource').factory('mm.core.resource.GridFilter', function () {

    /**
     * @param {string} key Filter key or query string representing filter
     * @param {string} [value]
     * @param {string} [operator]
     * @constructor
     */
    var GridFilter = function (key, value, operator) {
        if (key && !value && !operator) {
            this.readFromString(key);
        } else {
            this.key = key;
            this.value = value;
            this.operator = operator ? operator : '=';
        }
    };

    GridFilter.prototype.toString = function () {
        if (this.operator === '=') {
            return this.key + ':' + this.value;
        } else if (this.operator === '!=') {
            return this.key + ':!' + this.value;
        } else if (this.operator === 'in') {
            return this.value.length ? this.key + ':in[' + this.value.join() + ']' : '';
        } else if (this.operator === 'range') {
            return this.value.length ? this.key + ':range[' + this.value.join() + ']' : '';
        }
    };

    /**
     * @param {string} param Query param
     */
    GridFilter.prototype.readFromString = function (param) {
        var data = param.split(':'),
            matches;

        if (data.length === 2) {
            this.key = data[0];
            this.value = data[1];
            this.operator = '=';

            if (matches = this.value.match(/!(.*)/)) {
                this.value = matches[1];
                this.operator = '!=';
                return;
            }
            if (matches = this.value.match(/in\[(.*)\]/)) {
                this.value = matches[1].split(',');
                this.operator = 'in';
                return;
            }
            if (matches = this.value.match(/range\[([^,]*,[^,]*)\]/)) {
                this.value = matches[1].split(',');
                this.operator = 'range';
                return;
            }
        }
    };

    return GridFilter;
});