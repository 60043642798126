'use strict';
angular.module('B2B').directive('maxOnBlock', [
    '$timeout',
    '$rootScope',
    'mm.core.api.API',
    'mm.core.auth.AuthService',
    function ($timeout, $rootScope, API, Auth) {
        return {
            restrict: 'E',
            templateUrl: '/views/cms/blocks/promotion/max_on.html',
            replace: false,
            scope: {
                block: '='
            },
            link: function (scope) {
                var timer;

                /**
                 * Set product name font size.
                 *
                 * @param product
                 * @returns {string}
                 */
                scope.setFontSize = function (product) {
                    return product.name.length > 80 ? '1.4rem' : '1.6rem';
                };

                /**
                 * Percentage bar max width (in pixels);
                 *
                 * @type {number}
                 */
                var meterWidth = 150;

                /**
                 * Sets percentage bar proportionally.
                 *
                 * @param product
                 *
                 * @returns {string}
                 */
                function setMeterQuotient(productIndex) {
                    var product = scope.products[productIndex];

                    if (product.quantity === 0) {
                        return 0;
                    }

                    return ((product.quantity / product.discount.amount) * meterWidth).toString() + 'px';
                }

                /**
                 * Fetch promoted products and display.
                 */
                function loadPromotionsBlock() {
                    scope.loading = true;
                    API.get('products', {
                        itemsOnPage: 1,
                        filters: 'discount:max_on'
                    }).then(function (result) {
                        scope.products = result.items;
                        scope.productCount = result.total;

                        scope.block.offerPreviewEnabled = Auth.data.isLogged && Auth.data.user.hasAccess('offer_preview');
                        scope.selectProduct(0);

                        scope.products.forEach(function (value, index) {
                            value.meterQuotient = setMeterQuotient(0);
                        });

                        if (scope.productCount) {
                            $rootScope.$broadcast('banner.view', {
                                block: scope.block.key,
                                type: 'promotion',
                                id: 'max-on-block',
                                name: 'Max on'
                            });
                        }
                    }, function () {
                        scope.productCount = 0;
                    }).finally(function () {
                        scope.loading = false;
                    });
                }

                /**
                 * Handle products carousel
                 */
                scope.selectProduct = function (num) {
                    $timeout.cancel(timer);
                    if (num < 0) {
                        num = scope.products.length - 1;
                    }
                    if (num > (scope.products.length - 1)) {
                        num = 0;
                    }
                    scope.current = num;
                    timer = $timeout(function () {
                        scope.showNext();
                    }, 3000);
                };

                scope.showNext = function () {
                    scope.selectProduct(scope.current + 1);
                };
                scope.showPrev = function () {
                    scope.selectProduct(scope.current - 1);
                };

                scope.$on('mm.auth.logout', function () {
                    loadPromotionsBlock();
                });

                scope.$on('mm.auth.changed', function () {
                    loadPromotionsBlock();
                });

                scope.$on('currency.changed', function () {
                    loadPromotionsBlock();
                });

                scope.$on('$destroy', function () {
                    $timeout.cancel(timer);
                });

                loadPromotionsBlock();
            }
        };
    }

]);
