'use strict';
/**
 * @author Modest Machnicki
 */
angular.module('B2B').controller('UserImageController', [
    '$timeout', 'UserService',
    function ($timeout, UserService) {

        var FILE_TYPES_ALLOWED = ['image/png', 'image/jpeg'],
            MAX_FILE_SIZE = 1024 * 1024,
            vm = this;

        /**
         * Handle file input change, submit
         *
         * @param files
         */
        vm.loadFile = function (files) {
            var imageFile = files[0];
            vm.error = null;
            if (imageFile && validateFile(imageFile)) {
                vm.loading = true;
                UserService.updateImage(imageFile).then(function () {
                    var img = new Image();
                    img.onload = img.onerror = function () {
                        $timeout(function(){
                            vm.loading = false;
                        });
                    };
                    img.src = UserService.getImageSrc('medium');
                    vm.removable = true;
                }, function (response) {
                    if (response.status === 422 && response.data.image) {
                        vm.error = response.data.image[0];
                    }
                    vm.loading = false;
                });
            }
        };

        /**
         * Remove user image
         */
        vm.remove = function () {
            vm.loading = true;
            UserService.removeImage().then(function(){
                vm.loading = false;
                vm.removable = false;
            });
        };

        /**
         * @param {object} file
         * @returns {boolean}
         */
        function validateFile(file) {
            if (FILE_TYPES_ALLOWED.indexOf(file.type) === -1) {
                vm.error = 'Niepoprawny format pliku. Dozwolone są PNG lub JPG.';
                return false;
            }
            if (file.size > MAX_FILE_SIZE) {
                vm.error = 'Plik jest zbyt duży. Maksymalna wielkość to 1 M.';
                return false;
            }
            return true;
        }

        /**
         * Mark as removable if image has an 'app' source
         */
        UserService.checkImageSource().then(function(source){
            if (source === 'app') {
                vm.removable = true;
            }
        });

    }
]);