'use strict';

angular.module('B2B').directive('cartsTab', [
    '$state', 'mm.core.auth.AuthService', 'mm.core.dom.NoticeService', 'CartService', 'CartViewHelper', 'MainTabsService',
    function ($state, Auth, NoticeService, CartService, CartViewHelper, MainTabsService) {

        return {
            restrict: 'E',
            templateUrl: '/views/scaffolding/tabs/carts-tab.html',
            replace: false,
            link: function (scope) {
                scope.data = CartService.data;
                scope.helper = CartViewHelper.instance();
                scope.removeCartProduct = CartService.removeCartProduct;

                scope.newCartForm = {
                    visible: false,
                    loading: false,
                    show: function () {
                        this.loading = true;
                        CartService.getCartNameSuggestion().then(function (name) {
                            this.command.fields.name = name;
                            this.visible = true;
                            this.loading = false;
                        }.bind(this), function () {
                            this.loading = false;
                            NoticeService.error('Nie można utworzyć ' + (Auth.access('export_customer') ? 'oferty. ' : 'koszyka.'));
                        }.bind(this));
                    },
                    command: CartService.getCreatingCartCommand().onSuccess(function () {
                        scope.newCartForm.visible = false;
                    })
                };

                scope.removeCurrentCart = function () {
                    CartService.removeCurrentCart();
                    scope.newCartForm.visible = false;
                };

                /**
                 * @param {Cart} cart
                 */
                scope.setCurrentCart = function (cart) {
                    CartService.setCurrentCart(cart);
                    scope.helper.toggle(cart);
                    scope.newCartForm.visible = false;
                };

                /**
                 * Decrease item quantity by 1.
                 *
                 * @param {Cart} cart
                 * @param {Object} item Cart item
                 */
                scope.decreaseItemQuantity = function (cart, item) {
                    CartService.updateCartProductQuantity(cart, item, item.quantity - 1);
                };

                /**
                 * Increase item quantity by 1.
                 *
                 * @param {Cart} cart
                 * @param {Object} item Cart item
                 */
                scope.increaseItemQuantity = function (cart, item) {
                    CartService.updateCartProductQuantity(cart, item, item.quantity + 1);
                };

                scope.goToCheckout = function () {
                    MainTabsService.hideTabs();
                    $state.go('checkout.cart');
                };
            }
        };

    }
]);