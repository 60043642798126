/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('UserService', [
    '$http', 'mm.core.api.API', 'mm.core.auth.AuthService',
    function ($http, API, AuthService) {

        var timestamp = new Date().getTime();

        /**
         * Refresh user image src by add new timestamp to url
         */
        function refreshImagesSrc() {
            timestamp = new Date().getTime();
        }

        /**
         * Update user avatar image
         *
         * @param {File} file
         * @returns {*}
         */
        function updateImage(file) {
            return API.post('user/image', { image: file }).then(refreshImagesSrc);
        }

        /**
         * @param {string} size
         * @returns {*}
         */
        function getImageSrc (size) {
            if (AuthService.data.user) {
                return '/api/user/' + AuthService.data.user.id  + '/image?size=' + size + '&rand=' + timestamp;
            } else {
                return null;
            }
        }

        /**
         * Get X-image-source header value of current image
         *
         * @returns {*}
         */
        function checkImageSource() {
            return $http.get(getImageSrc('small')).then(function(data){
                var headers = data.headers();
                return headers['x-image-source'];
            });
        }

        /**
         * Remove user image
         */
        function removeImage() {
            return API.delete('user/image').then(refreshImagesSrc);
        }

        return {
            checkImageSource : checkImageSource,
            getImageSrc: getImageSrc,
            updateImage: updateImage,
            removeImage: removeImage
        };

    }
]);