/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('CartOfferService', [
    '$timeout',
    '$rootScope',
    '$window',
    'mm.core.api.CommandFactory',
    'mm.core.auth.AuthService',
    'mm.core.dom.NoticeService',
    'CustomerService',
    function ($timeout,
              $rootScope,
              $window,
              CommandFactory,
              AuthService,
              NoticeService,
              CustomerService) {

        var form = {},
            timer,
            buildCartOfferModels = function () {
                form.clear();
            },
            validateOfferItem = function (item) {
                item.offerPrice = _.replace(item.offerPrice, ',', '.');
                if (isNaN(item.offerPrice) || !item.offerPrice) {
                    item.offerPrice = item.price.net;
                } else {
                    item.offerPrice = parseFloat(item.offerPrice);
                }
            },
            /**
             * Helper - cart items iterator.
             *
             * @param {function} callback
             * @param {boolean} [includeDisabled]
             */
            eachOfferItem = function (callback, includeDisabled) {
                angular.forEach(form.cart.items.concat(form.cart.fees), function (item) {
                    if (!item.offerDisabled || includeDisabled) {
                        callback(item);
                    }
                });
            },
            calculateValue = function () {
                var netValue = 0,
                    grossValue = 0;

                eachOfferItem(function (item) {
                    var itemValue;
                    validateOfferItem(item);
                    itemValue = item.offerPrice * item.quantity;
                    netValue += itemValue;
                    grossValue += parseFloat((itemValue + itemValue * item.price.vatRate).toFixed(2));
                });

                form.netValue = parseFloat(netValue.toFixed(2));
                form.grossValue = parseFloat(grossValue.toFixed(2));
                form.taxValue = form.grossValue - form.netValue;
            },
            getCartItemsValue = function (gross) {
                var value = 0;

                eachOfferItem(function (item) {
                    value += item.price[gross ? 'gross' : 'net'] * item.quantity;
                });
                return value;
            };

        form.cart = null;

        /**
         * Clear all custom prices, margins.
         */
        form.clear = function () {
            if (form.cart) {
                eachOfferItem(function (item) {
                    item.offerPrice = item.price.net;
                    item.offerDisabled = false;
                }, true);
                form.netValue = form.cart.netValue;
                form.grossValue = form.cart.grossValue;
                form.taxValue = form.grossValue - form.netValue;
            }
        };

        /**
         * Round up cart item prices,
         */
        form.roundPrices = function () {
            eachOfferItem(function (item) {
                item.offerPrice = Math.ceil(item.offerPrice);
            });
            calculateValue();
        };

        form.margin = {
            value: 0,
            apply: function () {
                var value = _.replace(this.value, ',', '.');
                if (!isNaN(value = parseFloat(value))) {
                    eachOfferItem(function (item) {
                        item.offerPrice = parseFloat((item.offerPrice + item.offerPrice * value / 100).toFixed(2));
                    });
                    calculateValue();
                }
                this.value = 0;
            }
        };

        form.itemValueChanged = function () {
            $timeout.cancel(timer);
            timer = $timeout(function () {
                calculateValue();
            }, 800);
        };

        /**
         * Calculate items price by custom cart (summary) value.
         *
         * @param {boolean} gross If true, gross value will be used.
         */
        form.cartValueChanged = function (gross) {
            var value,
                marginRatio;

            $timeout.cancel(timer);

            timer = $timeout(function () {
                if (gross) {
                    value = _.replace(form.grossValue, ',', '.');
                } else {
                    value = _.replace(form.netValue, ',', '.');
                }

                if (!isNaN(value = parseFloat(value))) {
                    marginRatio = value / getCartItemsValue(gross);
                    eachOfferItem(function (item) {
                        item.offerPrice = Math.round(item.price.net * marginRatio * 100) / 100;
                    });
                }

                calculateValue();
            }, 600);

        };

        /**
         * Toggles offer form visibility.
         *
         * @param {Cart} cart
         */
        form.toggle = function (cart) {
            if (cart === form.cart) {
                form.cart = null;
            } else {
                form.cart = cart;
                buildCartOfferModels();
            }
        };

        form.command = CommandFactory.create('carts/pdf', 'post', {
            sendMail: false,
            email: AuthService.data.user ? AuthService.data.user.email : '',
            hideItemsPrice: false,
            attachTextData: false,
            header: '',
            footer: '',
            company: ''
        }, {
            successMessage: null
        }).onSuccess(function (pdfUrl) {
            $window.open(pdfUrl, "_blank");
        }).onError(function () {
            NoticeService.error('Wystąpił bład podczas generowania oferty.');
        });

        form.executeCommand = function () {
            var formItems = [],
                productIds = [];
            if (form.cart) {
                angular.forEach(form.cart.items, function (item) {
                    if (!item.offerDisabled) {
                        formItems.push({
                            partNumber: item.item.partNumber,
                            name: item.item.name,
                            price: {
                                net: item.offerPrice,
                                gross: parseFloat((item.offerPrice + item.offerPrice * item.price.vatRate).toFixed(2)),
                                vatRate: item.price.vatRate
                            },
                            quantity: item.quantity
                        });
                        if (item.item.type === 'product') {
                            productIds.push(item.item.id);
                        }
                    }
                });
                angular.forEach(form.cart.fees, function (item) {
                    if (!item.offerDisabled) {
                        formItems.push({
                            partNumber: null,
                            name: item.name,
                            price: {
                                net: item.offerPrice,
                                gross: parseFloat((item.offerPrice + item.offerPrice * item.price.vatRate).toFixed(2)),
                                vatRate: item.price.vatRate
                            },
                            quantity: item.quantity
                        });
                    }
                });

                form.command.fields.items = formItems;
                form.command.fields.productIds = productIds;
                form.command.fields.grossValue = form.grossValue;
                form.command.fields.netValue = form.netValue;

                form.command.execute();
            }
        };

        /**
         * Toggles item visibility in offer.
         *
         * @param {Object} cartItem
         */
        form.toggleItemVisibility = function (cartItem) {
            cartItem.offerDisabled = !cartItem.offerDisabled;
            calculateValue();
        };

        $rootScope.$on('cart.item.updated', function () {
            $timeout(function () {
                buildCartOfferModels();
            });
        });
        $rootScope.$on('cart.item.removed', function () {
            $timeout(function () {
                buildCartOfferModels();
            });
        });
        $rootScope.$on('cart.item.added', function () {
            $timeout(function () {
                buildCartOfferModels();
            });
        });

        (function () {
            var address,
                company;
            if (CustomerService.customer.data && CustomerService.customer.data.address) {
                address = CustomerService.customer.data.address;
                company = address.name + '\n' + address.street;

                if (address.houseNo) {
                    company += ' ' + address.houseNo;
                }
                if (address.flatNo) {
                    company += '/' + address.flatNo;
                }
                company += '\n' + address.zipCode + ' ' + address.city;
                form.command.fields.company = company;
            }
        })();

        return form;
    }
]);