'use strict';

angular.module('B2B').directive('currencyPicker', ['CurrencyService', function (CurrencyService) {

    return {
        restrict: 'E',
        templateUrl: '/views/scaffolding/currency-picker.html',
        replace: false,
        link: function (scope) {

            scope.currencies = CurrencyService.getAvailableCurrencies();

            CurrencyService.getCurrency().then(function (currency) {
                scope.currentCurrency = currency;
            });

            /**
             * Set the new user default currency.
             */
            scope.setCurrency = function setCurrency() {
                CurrencyService.setCurrency(scope.currentCurrency).then(function (currency) {
                    scope.currentCurrency = currency;
                });
            };
        }
    };
}]);