'use strict';

/**
 * @author Modest Machnicki
 */
angular.module('B2B').factory('CmsBlockService', ['$timeout', '$q', 'mm.core.api.API', function ($timeout, $q, API) {

    var blockRequests = [],
        requestTimer;

    /**
     * Perform group blocks request
     * Resolves cmsBlock request promises
     */
    function fetchBlockData() {
        var tmpBlockRequests = angular.copy(blockRequests);
        blockRequests = [];
        API.get('cms/blocks', {
            keys: tmpBlockRequests.map(function (blockRequest) {
                return blockRequest.key;
            })
        }).then(function (blocks) {
            angular.forEach(tmpBlockRequests, function (blockRequest) {
                var filteredBlock = blocks.filter(function (block) {
                    return block.key === blockRequest.key;
                })[0];
                if (filteredBlock) {
                    blockRequest.defer.resolve(filteredBlock);
                } else {
                    blockRequest.defer.reject();
                }
            });
        });
    }

    return {
        /**
         * Request for a cms block
         *
         * @param {string} blockKey
         * @returns {Promise}
         */
        requestBlock: function (blockKey) {
            var defer = $q.defer();
            $timeout.cancel(requestTimer);
            blockRequests.push({
                key: blockKey,
                defer: defer
            });
            requestTimer = $timeout(fetchBlockData);
            return defer.promise;
        }
    };

}]);