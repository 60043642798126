'use strict';
angular.module('B2B').directive('maxOffBlock', [
    '$rootScope', 'mm.core.api.API',
    function ($rootScope, API) {

        return {
            restrict: 'E',
            templateUrl: '/views/cms/blocks/promotion/max_off.html',
            replace: false,
            scope: {
                block: '='
            },
            link: function (scope) {

                /**
                 * Fetch promoted products and display.
                 */
                function loadPromotionsBlock() {
                    scope.loading = true;
                    API.get('products', {
                        itemsOnPage: 1,
                        filters: 'discount:max_off'
                    }).then(function (result) {
                        scope.productCount = result.total;
                        if (scope.productCount) {
                            $rootScope.$broadcast('banner.view', {
                                block: scope.block.key,
                                type: 'promotion',
                                id: 'max-off-block',
                                name: 'Max Off'
                            });
                        }
                    }, function () {
                        scope.productCount = 0;
                    }).finally(function () {
                        scope.loading = false;
                    });
                }

                scope.$on('mm.auth.logout', function () {
                    loadPromotionsBlock();
                });

                scope.$on('mm.auth.changed', function () {
                    loadPromotionsBlock();
                });

                /**
                 * Banner click broadcasting.
                 *
                 * @param {Object} banner
                 */
                scope.onBannerClick = function () {
                    $rootScope.$broadcast('banner.click', {
                        block: scope.block.key,
                        type: 'promotion',
                        id: 'max-off-block',
                        name: 'Max Off'
                    });
                };

                loadPromotionsBlock();
            }
        };
    }
]);