/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productOfferTab', [
    '$window',
    'mm.core.auth.AuthService',
    'mm.core.api.CommandFactory',
    'CustomerService',
    function ($window, AuthService, CommandFactory, CustomerService) {

        return {
            restrict: 'E',
            templateUrl: '/views/catalog/product/product-offer-tab.html',
            replace: true,
            scope: {
                product: '=',
                calcPrice: '='
            },
            link: function (scope) {

                scope.command = CommandFactory.create(null, 'post', {
                    sendMail: false,
                    email: AuthService.data.user ? AuthService.data.user.email : '',
                    hidePrice: false,
                    useCalcPrice: false,
                    attachDescription: false,
                    attachTextData: false,
                    header: '',
                    footer: '',
                    company: ''
                }, {
                    successMessage: null
                }).onSuccess(function (pdfUrl) {
                    $window.open(pdfUrl, "_blank");
                });

                // Toggle off useCalcPrice if hidePrice is selected
                scope.hidePriceChanged = function () {
                    if (scope.command.fields.hidePrice) {
                        scope.command.fields.useCalcPrice = false;
                    }
                };

                scope.auth = AuthService.data;

                /**
                 * Fill additional command fields and execute command.
                 */
                scope.executeCommand = function (format) {
                    scope.command.uri = 'products/' + scope.product.id + '/' + format;
                    if (scope.command.fields.useCalcPrice) {
                        scope.command.fields.calcNetPrice = parseFloat(scope.product.calcPrice.net);
                        scope.command.fields.calcGrossPrice = parseFloat(scope.product.calcPrice.gross);
                    } else {
                        scope.command.fields.calcNetPrice = null;
                        scope.command.fields.calcGrossPrice = null;
                    }
                    scope.command.execute();
                };

                (function () {
                    var address,
                        company;
                    if (CustomerService.customer.data && CustomerService.customer.data.address) {
                        address = CustomerService.customer.data.address;
                        company = address.name + '\n' + address.street;

                        if (address.houseNo) {
                            company += ' ' + address.houseNo;
                        }
                        if (address.flatNo) {
                            company += '/' + address.flatNo;
                        }
                        company += '\n' + address.zipCode + ' ' + address.city;
                        scope.command.fields.company = company;
                    }
                })();

            }
        };

    }
]);