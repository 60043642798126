/**
 * @author Daniel Rosiak
 */
'use strict';
angular.module('B2B').factory('CatalogBreadcrumbService', function () {

     function generate(currentCategory) {
        var breadcrumbs = [];
        while (currentCategory) {
            breadcrumbs.push(currentCategory);
            currentCategory = currentCategory.parent;
        }
        return breadcrumbs.reverse();
    }

    return {
        generate: function (category) {
            return generate(category);
        }
    };
});