'use strict';
angular.module('B2B').factory('MessageService', [
    '$rootScope', 'mm.core.api.API', 'mm.core.auth.AuthService', 'CustomerSocketService',
    function ($rootScope, API, Auth, CustomerSocketService) {

        var data = {
                list: null,
                loading: false,
                unreadCount: 0
            },
            booted = false;

        /**
         * Load user's messages.
         *
         * @returns {Promise}
         */
        function getMessages() {
            data.loading = true;
            data.list = null;
            return API.get('messages').then(function (items) {
                data.list = items;
                data.loading = false;
                countUnreadMessages();
            });
        }

        /**
         * Refresh unread count.
         */
        function countUnreadMessages() {
            data.unreadCount = _.filter(data.list, function (item) {
                return !item.read;
            }).length;
        }

        /**
         * Bind events, fetch messages.
         */
        function bootService() {
            if (!booted) {
                $rootScope.$on('mm.auth.login', function () {
                    getMessages();
                });
                $rootScope.$on('mm.auth.logout', function () {
                    data.list = null;
                    data.loading = false;
                });
                CustomerSocketService.on('message:pushed', function (event) {
                    var currentUser = Auth.data.user;
                    if (currentUser.id === event.user.id && data.list) {
                        data.list.unshift(event.message);
                        countUnreadMessages();
                    }
                });
                getMessages();
                booted = true;
            }
        }

        /**
         * @param {string} id
         * @returns {Promise}
         */
        function getMessage(id) {
            return API.get('messages/' + id).then(function (message) {
                // mark as read
                var item = _.find(data.list, {id: message.id});
                item.read = true;
                countUnreadMessages();

                return message;
            });
        }

        /**
         * @param {string} id
         * @returns {Promise}
         */
        function removeMessage(id) {
            _.remove(data.list, function (item) {
                return item.id === id;
            });
            countUnreadMessages();
            return API.delete('messages/' + id);
        }


        return {
            data: data,
            run: bootService,
            getMessage: getMessage,
            removeMessage: removeMessage
        };

    }
]);