/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('suppliersDialog', function () {

    return {
        restrict: 'E',
        templateUrl: '/views/catalog/product/suppliers-dialog.html',
        replace: false,
        scope : {
            product : '=',
            show : '=',
            loading: '='
        }
    };

});