/**
 * Sends product mistake report.
 */
'use strict';

angular.module('B2B').directive('productMistakeReport', [
    'mm.core.api.CommandFactory',
    function (CommandFactory) {

        return {
            restrict: 'E',
            templateUrl: '/views/catalog/product/product-mistake-report.html',
            replace: true,
            link: function (scope) {

                scope.dialog = {
                    shown: false,
                    open: function () {
                        this.shown = true;
                    }
                };

                scope.command = CommandFactory.create(
                    'products/' + scope.product.id + '/mistake-report',
                    'post',
                    null,
                    {
                        successMessage: 'Zgłoszenie zostało wysłane. Dziękujemy.'
                    }
                ).onSuccess(function () {
                    scope.dialog.shown = false;
                    scope.command.clear();
                });

            }
        };

    }
]);