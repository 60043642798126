/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productImages', ['$timeout', 'Analytics',
    function ($timeout, Analytics) {

    return {
        restrict: 'E',
        templateUrl: '/views/catalog/product/product-images.html',
        replace: true,
        link: function (scope) {

            scope.loading = true;

            scope.layer = {
                shown: false,
                show: function () {
                    this.shown = true;
                }
            };

            //Preload image src
            function preload(src, callback) {
                var img = new Image();
                img.onload = callback;
                img.onerror = callback;
                img.src = src;
            }

            // Carousel
            function initImageCarousel() {
                var firstVisibleIndex = 0;

                function refreshNav() {
                    if (firstVisibleIndex + 4 < scope.product.images.length) {
                        scope.nextBtnShown = true;
                    } else {
                        scope.nextBtnShown = false;
                    }
                    if (firstVisibleIndex > 0) {
                        scope.prevBtnShown = true;
                    } else {
                        scope.prevBtnShown = false;
                    }
                    scope.navOffset = firstVisibleIndex * -78;
                }

                scope.moveRight = function () {
                    if (firstVisibleIndex + 4 < scope.product.images.length) {
                        firstVisibleIndex++;
                    }
                    refreshNav();
                };

                scope.moveLeft = function () {
                    if (firstVisibleIndex > 0) {
                        firstVisibleIndex--;
                    }
                    refreshNav();
                };

                refreshNav();
            }

            // Init main image, preload it and all thumbnails
            function init() {
                var left = scope.product.images.length + 1;

                function endLoadProccess() {
                    left--;
                    if (left === 0) {
                        $timeout(function () {
                            scope.loading = false;
                        });
                    }
                }

                angular.forEach(scope.product.images, function (image) {
                    if (image.main) {
                        scope.setAsCurrent(image);
                        Analytics.trackEvent('product-image', 'original', scope.product.id);
                        preload(image.src.original, endLoadProccess);
                        image.loaded = true;
                    }
                    Analytics.trackEvent('product-image', 'thumbnail', scope.product.id);
                    preload(image.src.thumbnail, endLoadProccess);
                });

                initImageCarousel();
            }

            // Set given image as current main image
            scope.setAsCurrent = function (image) {
                if (image) {
                    scope.currentImage = image;
                    scope.currentIndex = scope.product.images.indexOf(image);

                    if (!image.loaded) {
                        scope.mainImageLoading = true;
                        Analytics.trackEvent('product-image', 'original', scope.product.id);
                        preload(image.src.original, function () {
                            $timeout(function () {
                                scope.mainImageLoading = false;
                                image.loaded = true;
                            });
                        });
                    }
                }
            };

            if (scope.product.images && scope.product.images.length) {
                init();
            }
        }
    };

}]);
