'use strict';
angular.module('B2B').factory('Grid', [
    'mm.core.resource.Grid',
    function (BaseGrid) {

        /**
         * @param {Object} options
         * @constructor
         */
        var Grid = function (opts) {
            BaseGrid.call(this, opts);

        };
        Grid.prototype = Object.create(BaseGrid.prototype);
        Grid.prototype.constructor = Grid;

        /**
         * @param {Object} column
         * @returns {boolean}
         */
        Grid.prototype.isColumnVisible = function (column) {
            if (typeof column.isVisible === 'function') {
                return column.isVisible();
            }
            if (typeof column.isVisible === 'boolean') {
                return column.isVisible;
            }
            return true;
        };

        return Grid;
    }
]);