/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('cmsBlock', [
    '$rootScope',
    'CmsBlockService',
    '$timeout',
    'mm.core.api.API',
    function ($rootScope,
              CmsBlockService,
              $timeout,
              API) {

        return {
            restrict: 'E',
            templateUrl: '/views/cms/block.html',
            replace: true,
            scope: {
                key: '@',
                type: '@'
            },
            link: function (scope) {

                /**
                 * @param {Object} block
                 */
                function buildProducerSlider(block) {

                    var timer,
                        slideWidth = 148;

                    // Init rotation with over 6 producers
                    scope.sliderOffsetLeft = 0;
                    scope.animateSlider = false;

                    /**
                     * Change slider container left offset (with animation performing or not).
                     *
                     * @param {number} offsetLeft
                     * @param {boolean} animate
                     */
                    function setOffset(offsetLeft, animate) {
                        if (animate) {
                            scope.animateSlider = true;
                            $timeout(function () {
                                scope.sliderOffsetLeft = offsetLeft;
                            }, 100);
                            $timeout(function () {
                                scope.animateSlider = false;
                            }, 3000);
                        } else {
                            scope.animateSlider = false;
                            scope.sliderOffsetLeft = offsetLeft;
                        }
                    }

                    if (block.producers.length > 6) {
                        scope.prevProducer = function (animated) {
                            $timeout.cancel(timer);
                            setOffset(-slideWidth, false);
                            block.producers.unshift(block.producers.pop());
                            setOffset(0, animated);
                            timer = $timeout(function () {
                                scope.nextProducer(true);
                            }, 5000);
                        };
                        scope.nextProducer = function (animated) {
                            $timeout.cancel(timer);
                            setOffset(0, false);
                            block.producers.push(block.producers.shift());
                            setOffset(-slideWidth, animated);

                            timer = $timeout(function () {
                                scope.nextProducer(true);
                            }, 5000);
                        };

                        scope.nextProducer(true);

                        scope.$on('$destroy', function () {
                            $timeout.cancel(timer);
                        });
                    }
                }

                scope.loading = true;
                scope.keyClass = scope.key.replace(/\./g, '-');

                /**
                 * Banner click broadcasting.
                 *
                 * @param {Object} banner
                 */
                scope.onBannerClick = function (banner) {
                    $rootScope.$broadcast('banner.click', {
                        block: scope.key,
                        type: 'banner',
                        id: banner.id,
                        name: banner.name
                    });
                };

                CmsBlockService.requestBlock(scope.key).then(function (block) {
                    scope.block = block;
                    scope.settings = angular.fromJson(scope.block.settings);

                    if (scope.type) {
                        scope.block.type = scope.type;
                    }

                    switch (scope.block.type) {
                        case 'banners' :
                            angular.forEach(scope.block.banners, function (banner) {
                                $rootScope.$broadcast('banner.view', {
                                    block: scope.key,
                                    type: 'banner',
                                    id: banner.id,
                                    name: banner.name
                                });
                            });
                            break;
                        case 'producers':
                            block.producers = [];
                            API.get('cms/block/producers').then(function (producers) {
                                angular.forEach(producers, function (producer) {
                                    block.producers.push({
                                        id: producer.id,
                                        name: producer.name,
                                        logoSrc: producer.logoSrc,
                                        link: producer.productsCount > 0 ? '/search?producer=' + producer.id : '',
                                        productsCount: producer.productsCount
                                    });
                                });

                                buildProducerSlider(block);
                            });
                            break;
                        case 'news':
                            block.loading = true;
                            API.get('cms/block/news').then(function (news) {
                                block.news = news;
                            }).finally(function () {
                                block.loading = false;
                            });
                            break;
                    }

                }).finally(function () {
                    scope.loading = false;
                });
            }
        };
    }
]);
