'use strict';

angular.module('B2B').controller('ConfiguratorController', [
    '$scope',
    '$state',
    '$window',
    'mm.core.api.API',
    'mm.core.api.CommandFactory',
    'mm.core.auth.AuthService',
    'mm.core.dom.NoticeService',
    'mm.core.dom.ConfirmDialogService',
    'AttributeFilterService',
    'CartService',
    'CustomerService',
    'ConfiguratorProductPicker',
    'ConfiguratorOfferForm',
    function ($scope,
              $state,
              $window,
              API,
              CommandFactory,
              AuthService,
              NoticeService,
              ConfirmDialogService,
              AttributeFilterService,
              CartService,
              CustomerService,
              ConfiguratorProductPicker,
              ConfiguratorOfferForm) {
        var vm = this,
            components = [];


        vm.value = 0;
        vm.valid = false;
        vm.hasUnsavedChanged = false;

        function clearUnsavedChanges() {
            vm.hasUnsavedChanged = false;
            $window.onbeforeunload = undefined;
        }

        function reportUnsavedChanges() {
            vm.hasUnsavedChanged = true;
            vm.offerForm.hide();
            $window.onbeforeunload = function (event) {
                var result = 'Czy na pewno chcesz opuścić konfigurator? Niezapisane zmiany zostaną utracone.';
                event.returnValue = result;
                return result;
            };
        }

        (function (vm) {

            var loadingPromise;

            vm.components = {
                groups: null,
                clear: function () {
                    this.groups = null;
                    components = [];
                },
                loading: false,
                load: function (configuratorId) {
                    var uri = configuratorId ? 'configurators/' + configuratorId + '/components' : 'configurators/components';

                    if (loadingPromise) {
                        loadingPromise.abort();
                    }

                    this.loading = true;

                    loadingPromise = API.get(uri, configuratorId ? null : {
                        components: components.filter(function (component) {
                            return component.product
                        }).map(function (component) {
                            return component.id + ':' + component.product.id + ':' + component.quantity;
                        }).join(';')
                    });

                    return loadingPromise.then(function (componentGroups) {
                        components = [];
                        this.groups = componentGroups;
                        angular.forEach(this.groups, function (group) {
                            components = components.concat(group);
                        });
                        vm.cases.loadCurrentCase();
                        vm.summary.refresh(true);
                        this.loading = false;
                    }.bind(this));
                }
            };
        })(vm);

        vm.configurators = {
            list: null,
            currentId: null,
            loadList: function () {
                this.list = null;
                API.get('configurators').then(function (configurators) {
                    this.list = configurators;
                }.bind(this));
            },
            load: function (id) {
                this.currentId = id;
                vm.currentStep = 'loading';
                vm.components.clear();
                API.get('configurators/' + id).then(function (configurator) {

                    this.buildCommand(configurator);

                    vm.components.load(id).then(function () {
                        vm.currentStep = 'components';
                    });

                }.bind(this), function () {
                    vm.currentStep = 'not-found';
                });
            },
            loadComponentsIntoCommand: function () {
                vm.command.fields.components = components.filter(function (component) {
                    return component.product;
                }).map(function (component) {
                    return {
                        componentId: component.id,
                        productId: component.product.id,
                        quantity: component.quantity
                    };
                });
            },
            buildCommand: function (configurator) {
                var id = configurator ? configurator.id : null;
                vm.command = CommandFactory.create('configurators' + (id ? '/' + id : ''), id ? 'put' : 'post', {
                    name: configurator ? configurator.name : '',
                    note: configurator ? configurator.note : '',
                    traderNote: configurator ? configurator.traderNote : '',
                    guaranteeId: configurator ? configurator.guaranteeId : null
                }, {
                    successMessage: configurator ? 'Konfiguracja została zapisana.' : 'Utworzono nową konfigurację.'
                }).onSuccess(function (result) {
                    if (configurator) {
                        angular.extend(_.find(this.list, {id: result.id}), result);
                    } else {
                        this.list.push(result);
                        this.currentId = result.id;
                        this.buildCommand(result);
                        $state.transitionTo('configurator.item', {id: result.id}, {notify: false});
                    }
                }.bind(this));
            },
            create: function () {
                this.currentId = null;
                this.buildCommand();
                vm.components.clear();
                vm.cases.currentCase = null;
                vm.currentStep = 'cases';
                vm.components.load();
            },
            remove: function (configurator) {
                ConfirmDialogService.confirm('Czy na pewno chcesz usunąć "' + configurator.name + '"?').then(function () {
                    configurator.loading = true;
                    API.delete('configurators/' + configurator.id).then(function () {
                        NoticeService.warning('Usunięto ' + configurator.name + '.');
                        _.remove(this.list, function (item) {
                            return item.id === configurator.id;
                        });
                        if (configurator.id === this.currentId) {
                            $state.go('configurator');
                        }
                    }.bind(this));
                }.bind(this));
            }
        };

        vm.summary = {
            price: null,
            calcPrice: null,
            loading: false,
            calculateValue: function (partialValue) {
                this.loading = true;
                this.price = null;
                API.post('configurators/price', {
                    caseId: vm.cases.currentCase.id,
                    value: partialValue,
                    guaranteeId: vm.command.fields.guaranteeId
                }).then(function (value) {
                    this.price = value;
                    this.loading = false;
                }.bind(this), function () {
                    this.price = null;
                    this.loading = false;
                }.bind(this));
            },
            clearWarnings: function (component) {
                component.warnings = [];

                if (component.relation) {
                    components.filter(function (item) {
                        return item.relation === component.relation && item.warnings.indexOf('required_relation') !== -1;
                    }).forEach(function (item) {
                        item.warnings = [];
                    });
                }
            },
            refresh: function (refreshSummaryValue) {
                var componentsValue = 0,
                    valid = false;

                if (components.length && vm.cases.currentCase) {
                    valid = true;
                    angular.forEach(components, function (component) {
                        if (component.product && component.price) {
                            if (!component.quantity) {
                                component.quantity = 1;
                            }
                            component.value = component.price.net * component.quantity;
                            componentsValue += component.value;
                        } else {
                            component.value = null;
                        }
                        if (component.warnings && component.warnings.length) {
                            valid = false;
                        }
                    });
                }

                if (valid && refreshSummaryValue) {
                    this.calculateValue(componentsValue);
                } else {
                    this.value = null;
                }

                vm.valid = valid;
            }
        }

        vm.actions = {
            loading: false,
            save: function (disableLoader) {

                if (!disableLoader) {
                    this.loading = true;
                }

                vm.configurators.loadComponentsIntoCommand();

                return vm.command.execute().then(function (configurator) {
                    if (!disableLoader) {
                        this.loading = false;
                    }
                    clearUnsavedChanges();
                    return configurator;
                }.bind(this));
            },
            createOffer: function () {
                this.save(false).then(function (configurator) {
                    vm.offerForm.setId(configurator.id).toggle();
                });
            },
            quantity: 1,
            addToCart: function () {
                this.loading = true;
                this.save(true).then(function (configurator) {
                    if (configurator) {
                        CartService.addToCart(configurator, this.quantity, 'configurator').finally(function () {
                            this.loading = false;
                            this.quantity = 1;
                        }.bind(this));
                    } else {
                        this.loading = false;
                    }
                }.bind(this));
            }
        };

        vm.cases = {
            currentCase: null,
            list: null,
            loading: true,
            selectCase: function (item) {
                this.currentCase = item;
            },
            loadCurrentCase: function () {
                var caseComponent = _.find(components, {type: 'cases'});
                if (caseComponent && caseComponent.product) {
                    this.currentCase = _.find(this.list, {id: caseComponent.product.id});
                }
            },
            slider: {
                index: 0,
                next: function () {
                    if (this.index < vm.cases.list.length - 3) {
                        this.index++;
                    }
                },
                prev: function () {
                    if (this.index > 0) {
                        this.index--;
                    }
                }
            },
            applyStep: function () {
                var caseComponent = _.find(components, {type: 'cases'});
                if (this.currentCase && caseComponent) {
                    caseComponent.product = this.currentCase;
                    caseComponent.quantity = 1;
                    caseComponent.price = this.currentCase.price;
                    caseComponent.warnings = [];

                    // Pick first available case guarantee
                    if (this.currentCase.guarantees && this.currentCase.guarantees.length) {
                        vm.command.fields.guaranteeId = this.currentCase.guarantees[0].id;
                    }

                    vm.components.load();
                    vm.currentStep = 'components';
                    reportUnsavedChanges();
                }
            },
            guaranteeChanged: function () {
                vm.summary.refresh(true);
                reportUnsavedChanges();
            }
        };

        vm.productPicker = new ConfiguratorProductPicker({
            onSelect: function (product) {
                vm.picker.pick(product);
            },
            onPreview: function (product) {
                vm.productDialog.open(product);
            }
        });

        vm.picker = {
            shown: false,
            loading: false,
            products: null,
            component: null,
            show: function (component) {
                this.component = component;
                vm.productPicker.requestComponent(this.component)
                    .setCase(vm.cases.currentCase)
                    .setComponents(components)
                    .load();
                this.shown = true;
            },
            pick: function (product) {
                this.component.product = product;
                this.component.quantity = 1;
                this.component.maxQuantity = null;
                this.component.price = product.price;
                vm.summary.clearWarnings(this.component);
                this.shown = false;
                vm.summary.refresh();
                vm.components.load();
                reportUnsavedChanges();
            },
            remove: function (component) {
                if (!component.system) {
                    component.product = null;
                    component.quantity = null;
                    component.maxQuantity = null;
                    component.price = null;
                    component.warnings = [];
                    vm.summary.refresh();
                    vm.components.load();
                    reportUnsavedChanges();
                }
            },
            quantityChanged: function () {
                vm.summary.refresh();
                vm.components.load();
                reportUnsavedChanges();
            }
        };

        vm.changeComponentProduct = function (component) {
            if (component.type === 'cases') {
                vm.currentStep = 'cases';
            } else {
                vm.picker.show(component);
            }
        };

        vm.productDialog = {
            shown: false,
            loading: false,
            product: null,
            open: function (product) {
                this.product = product;
                this.shown = true;
                this.loading = true;
                API.get('products/' + product.id).then(function (product) {
                    angular.extend(this.product, product);
                    this.loading = false;
                }.bind(this));
            }
        };

        vm.offerForm = new ConfiguratorOfferForm();
        vm.offerForm.setConfiguratorSummary(vm.summary);

        API.get('configurator/cases').then(function (cases) {
            vm.cases.list = cases;
            vm.cases.loading = false;
        });


        if (CustomerService.customer.data) {
            vm.configurators.loadList();
            if ($state.params.id) {
                vm.configurators.load($state.params.id);
            } else {
                vm.configurators.create();
            }
        } else {
            vm.currentStep = 'not-logged';
        }

        $scope.$on('customer.changed', function (event, customer) {
            if (customer) {
                vm.configurators.loadList();
                if ($state.params.id) {
                    vm.configurators.load($state.params.id);
                } else {
                    vm.configurators.create();
                }
            } else {
                vm.currentStep = 'not-logged';
                clearUnsavedChanges();
            }
        });

        $scope.$on('$stateChangeStart', function (event, toState, toParams) {
            if (vm.hasUnsavedChanged) {
                event.preventDefault();
                ConfirmDialogService.confirm(
                    'Czy na pewno chcesz opuścić konfigurator?<br/>Niezapisane zmiany zostaną utracone.'
                ).then(function () {
                    clearUnsavedChanges();
                    $state.go(toState, toParams, {reload: true});
                });
            }
        });

        $scope.$on('$destroy', function () {
            clearUnsavedChanges();
        });

        $scope.$on('$stateChangeSuccess', function (event, state, params) {
            if (state.name === 'configurator.item' && params.id) {
                vm.configurators.load(params.id);
            } else if (state.name === 'configurator') {
                vm.configurators.create();
            }
        });


    }
]);
