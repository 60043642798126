/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').directive('productBadges', function () {

    return {
        restrict: 'E',
        templateUrl: '/views/catalog/product/product-badges.html',
        replace: true,
        link: function (scope) {

            scope.promotional = {
                /**
                 * Determines if promotional badge should be displayed.
                 *
                 * @returns {boolean}
                 */
                enabled: function () {
                    return !scope.product.discount && scope.product.promotional;
                }
            };

            scope.maxDiscount = {
                /**
                 * Determines if max discount badge should be displayed.
                 *
                 * @returns {boolean}
                 */
                enabled: function () {
                    return scope.product.promotional &&
                        scope.product.discount && scope.product.discount.type === 'max_discount';
                }
            };

            scope.maxOn = {
                /**
                 * Determines if max on badge should be displayed.
                 *
                 * @returns {boolean}
                 */
                enabled: function () {
                    return scope.product.promotional &&
                        scope.product.discount && scope.product.discount.type === 'max_on';
                }
            };

            scope.percentDiscount = {
                /**
                 * Determines if percent discount badge should be displayed.
                 *
                 * @returns {boolean}
                 */
                enabled: function () {
                    return scope.product.promotional &&
                        scope.product.discount && scope.product.discount.type === 'max_off';
                },
                /**
                 * Get percent value of product discount.
                 *
                 * @returns {string}
                 */
                getLabel: function () {
                    var discountValue;
                    if (scope.product.basicPrice && scope.product.price) {
                        discountValue = scope.product.basicPrice.net - scope.product.price.net;
                        return Math.round(discountValue * 100 / scope.product.basicPrice.net) + '%';
                    }
                }
            };

        }
    };

});
