'use strict';

angular.module('B2B').controller('ComplaintShippingFormController', [
    '$scope', 'mm.core.api.CommandFactory', 'mm.core.auth.AuthService', 'ComplaintInvoiceSuggester',
    function ($scope, CommandFactory, AuthService, ComplaintInvoiceSuggester) {

        this.command = CommandFactory.create('complaints/shipping', 'post', {
            reportingPerson: AuthService.data.user.name
        }, {
            successMessage: 'Zgłoszenie zostało przesłane.'
        });
        this.command.onSuccess(function () {
            this.command.clear();
        }.bind(this));

        this.suggester = ComplaintInvoiceSuggester;
        ComplaintInvoiceSuggester.disable([
            ComplaintInvoiceSuggester.fields.INVOICE_DATE
        ]);
        ComplaintInvoiceSuggester.setCommand(this.command);

        this.complaintReasonChanged = function () {
            // Reset serial number field
            if (this.command.fields.complaintReason !== 'Uszkodzenia') {
                this.command.fields.productSerialNo = '';
            }
        };
    }
]);
