'use strict';

angular.module('B2B').factory('ProductListFactory', [
    '$state',
    '$location',
    '$timeout',
    '$document',
    '$window',
    'ProductGrid',
    'mm.core.auth.AuthService',
    'mm.core.api.API',
    'CatalogFiltersService',
    'ProducerFilterService',
    'CategoryService',
    'AttributeFilterService',
    'PriceFilterService',
    'SearchService',
    'BasicFilterService',
    function ($state,
              $location,
              $timeout,
              $document,
              $window,
              ProductGrid,
              AuthService,
              API,
              CatalogFiltersService,
              ProducerFilterService,
              CategoryService,
              AttributeFilterService,
              PriceFilterService,
              SearchService,
              BasicFilterService) {
        /**
         * @param {ProductGrid} grid
         * @param {Object} $scope
         * @param {Object} opts
         * @constructor
         */
        var ProductList = function (grid, $scope, opts) {

            this.grid = grid;
            this.viewType = null;
            this.sorting = null;
            this.stickyHeader = false;
            this.$scope = $scope;
            this.sortOptions = [];

            this.setDefaultSorting();

            if (opts.viewType) {
                this.viewType = opts.viewType;
            } else {
                loadViewType(this);
            }
            if (opts.userSettingsEnabled) {
                loadUserSettings();
            }
        };

        /**
         * @param {Object} list
         */
        ProductList.prototype.setDefaultSorting = function () {
            this.sortOptions = [
                {
                    key: null,
                    direction: null,
                    label: 'Domyślnie'
                },
                {
                    key: 'name',
                    direction: 'asc',
                    label: 'Nazwa A-Z'
                },
                {
                    key: 'name',
                    direction: 'desc',
                    label: 'Nazwa Z-A'
                },
                {
                    key: 'producer.name',
                    direction: 'asc',
                    label: 'Producent A-Z'
                },
                {
                    key: 'producer.name',
                    direction: 'desc',
                    label: 'Producent Z-A'
                },
                {
                    key: 'price',
                    direction: 'desc',
                    label: 'Cena malejąco',
                    isEnabled: function () {
                        return AuthService.data.isLogged && AuthService.data.user.hasAccess('offer_preview');
                    }
                },
                {
                    key: 'price',
                    direction: 'asc',
                    label: 'Cena rosnąco',
                    isEnabled: function () {
                        return AuthService.data.isLogged && AuthService.data.user.hasAccess('offer_preview');
                    }
                },
                {
                    key: 'partNumber',
                    direction: 'asc',
                    label: 'PN A-Z'
                },
                {
                    key: 'partNumber',
                    direction: 'desc',
                    label: 'PN Z-A'
                },
                {
                    key: 'quantity',
                    direction: 'desc',
                    label: 'Ilość malejąco'
                },
                {
                    key: 'quantity',
                    direction: 'asc',
                    label: 'Ilość rosnąco'
                }
            ];
            this.sorting = this.sortOptions[0];

            // Validate grid's current sorting option
            (function (list) {
                var sorting;
                if (list.grid.data.sorting) {
                    sorting = _.find(list.sortOptions, {
                        key: list.grid.data.sorting.column,
                        direction: list.grid.data.sorting.direction
                    });
                    if (sorting && sorting.isEnabled && !sorting.isEnabled()) {
                        list.grid.setSort(list.sorting.key, list.sorting.direction);
                    }
                }
            })(this);
        }


        /**
         * Set sorting property in ProductList
         *
         * @param {string} key
         * @param {string} direction (asc, desc)
         */
        ProductList.prototype.setSorting = function (key, direction) {
            this.sorting = this.sortOptions.filter(function (option) {
                return option.key === key && option.direction === direction;
            })[0];
        };

        /**
         * @param {Object} sorting
         * @returns {boolean}
         */
        ProductList.prototype.isSortingEnabled = function (sorting) {
            if (typeof sorting.isEnabled === 'function') {
                return sorting.isEnabled();
            }
            return true;
        };

        /**
         * Perform sorting action.
         *
         * @param {string} [key]
         * @param {string} [direction] (asc, desc)
         */
        ProductList.prototype.sort = function (key, direction) {
            if (key) {
                this.setSorting(key, direction ? direction : 'asc');
            }
            this.grid.changeSort(this.sorting.key, this.sorting.direction);
        };

        /**
         * Change listing view type (tiles, listing etc.)
         *
         * @param {string} viewType
         */
        ProductList.prototype.setViewType = function (viewType) {
            this.viewType = viewType;
            AuthService.saveSetting('catalog.viewType', viewType);
        };

        /**
         * Load view type init value
         *
         * @param {ProductList} productList
         */
        function loadViewType(productList) {
            AuthService.getUser().then(function (user) {
                productList.viewType = user.settings['catalog.viewType'] ? user.settings['catalog.viewType'] : 'tiles';
            }, function () {
                productList.viewType = 'tiles'; //default view for guests
            });
        }

        /**
         * Load user filters settings
         */
        function loadUserSettings() {
            AuthService.getUser().then(function (user) {
                var value = user.settings['filter.availableOnly'];
                if (value && value !== '0') {
                    BasicFilterService.availableOnlyFilter.setChecked(true);
                }
            });
        }

        function handleWindowScroll(productList) {
            var recentScrollY = 0,
                timer;

            function scrollHandle(event) {
                var documentHeight = $document[0].body.offsetHeight,
                    screenHeight = $window.innerHeight,
                    scrollOffset = $window.pageYOffset;

                $timeout.cancel(timer);

                timer = $timeout(function () {

                    //Stick pagination header to the top
                    if (productList.grid.opts.stickyHeaderEnabled) {
                        productList.stickyHeader = $window.pageYOffset >= 65;
                    }

                    // Check conditions for auto load next page
                    if (recentScrollY <= scrollOffset &&
                        (scrollOffset > (documentHeight - screenHeight - 100) ||
                        (documentHeight === screenHeight))) {
                        productList.grid.loadNextPage();
                    }

                    recentScrollY = $window.pageYOffset;
                });
            }

            $document.on('scroll', scrollHandle);
            $document.on('wheel', scrollHandle);
            productList.$scope.$on('$destroy', function () {
                $document.off('scroll', scrollHandle);
                $document.off('wheel', scrollHandle);
            });
        }


        /**
         * Init product list view and perform searching
         *
         * @returns {Promise}
         */
        ProductList.prototype.init = function () {
            var productList = this;

            PriceFilterService.removeFilter();
            AttributeFilterService.removeFilters();
            BasicFilterService.clearSelection();
            SearchService.clearQuery();

            this.grid.loadState();

            if ($state.params.query) {
                SearchService.setQuery(decodeURIComponent($state.params.query));
            }
            if ($state.params.warehouse) {
                BasicFilterService.warehouseFilter.setValue($state.params.warehouse);
            }
            if ($state.params.promotional) {
                BasicFilterService.promotionalFilter.setChecked(true);
            }
            if ($state.params.availableOnly) {
                BasicFilterService.availableOnlyFilter.setChecked(true);
            }

            if (this.grid.data.sorting) {
                this.setSorting(this.grid.data.sorting.column, this.grid.data.sorting.direction);
            }

            handleWindowScroll(this);

            // Build grid
            this.grid.setColumns([
                {
                    name: 'P/N',
                    key: 'partNumber',
                    sortable: true
                },
                {
                    name: 'Prod.',
                    key: 'producer.name',
                    sortable: true
                },
                {
                    name: 'Nazwa',
                    key: 'name',
                    className: 'name',
                    sortable: true
                },
                {
                    name: 'Opis',
                    className: 'description'
                },
                {
                    name: 'Szt.',
                    key: 'quantity',
                    sortable: true
                },
                {
                    name: 'Info'
                },
                {
                    name: 'Magazyn',
                    key: 'warehouse',
                    sortable: false
                },
                {
                    name: 'Cena',
                    key: 'price',
                    className: 'price',
                    sortable: true,
                    isVisible: function () {
                        return AuthService.data.isLogged && AuthService.data.user.hasAccess('offer_preview');
                    }
                }
            ]);

            productList.$scope.$on('mm.auth.changed', function () {
                productList.search();
            });

            productList.$scope.$on('currency.changed', function () {
                productList.search();
            });

            productList.$scope.$on('search.query.changed', function (event, query) {
                productList.grid.setQuery(query);
                productList.search();
            });

            // Basic filters
            productList.$scope.$on('warehouse.filter.changed', function (event, option) {
                if (option.value) {
                    productList.grid.setFilter('warehouse', option.value);
                } else {
                    productList.grid.clearFilter('warehouse');
                }
                productList.search();
            });
            productList.$scope.$on('promotional.filter.changed', function (event, checked) {
                if (checked) {
                    productList.grid.setFilter('promotional', true);
                } else {
                    productList.grid.clearFilter('promotional');
                }
                productList.search();
            });
            productList.$scope.$on('availableOnly.filter.changed', function (event, checked) {
                AuthService.saveSetting('filter.availableOnly', checked);
                if (checked) {
                    productList.grid.setFilter('availableOnly', true);
                } else {
                    productList.grid.clearFilter('availableOnly');
                }
                productList.search();
            });

            return this.search($state.params.page);
        };

        ProductList.prototype.loadFacets = function () {
            var searchTimeout,
                list = this,
                /**
                 * Prevent mutli trigger search on one digest time
                 * TODO replace with scope observers
                 */
                timeoutSearch = function () {
                    $timeout.cancel(searchTimeout);
                    searchTimeout = $timeout(function () {
                        list.search();
                    });
                },
                categoryId = this.grid.getCategoryId();

            return this.grid.loadFacets(this.grid.opts.facetingEnabled, this.grid.opts.categoryTreeFacetingEnabled)
                .then(function () {

                    if (this.grid.opts.facetingEnabled) {
                        //Bind facet filters
                        ProducerFilterService.loadFilter(
                            this.grid.getProducers(),
                            this.grid.facet.producers,
                            function (values) {
                                this.grid.setProducers(values);
                                timeoutSearch();
                            }.bind(this)
                        );

                        PriceFilterService.loadFilter(
                            this.grid.getPriceFilter(),
                            this.grid.facet.price,
                            function (priceRange) {
                                this.grid.setPriceFilter(priceRange);
                                timeoutSearch();
                            }.bind(this)
                        );

                        if (categoryId) {
                            AttributeFilterService.loadFilters(
                                categoryId,
                                this.grid.getAttributes(),
                                this.grid.facet.attributes,
                                function (attributeId, values) {
                                    this.grid.setAttribute(attributeId, values); //TODO use scope event
                                    timeoutSearch();
                                }.bind(this)
                            );
                        } else {
                            AttributeFilterService.removeFilters();
                        }
                    }

                    // Bind category facets
                    if (this.grid.facet.categories && this.grid.opts.categoryTreeFacetingEnabled) {
                        CategoryService.bindFacet(this.grid.facet.categories, function (category) {
                            this.grid.setCategoryIds([category.id]);
                            $state.go('search', this.grid.getStateParams({page: 1}));
                        }.bind(this)).then(function () {
                            if (categoryId) {
                                CategoryService.find(categoryId).then(function (category) {
                                    CategoryService.markAsCurrent(category);
                                });
                            }
                        }.bind(this));

                        // If results belongs to one category, select it
                        if (this.grid.facet.categories.length === 1 && !categoryId) {
                            this.grid.setCategoryIds([this.grid.facet.categories[0].id]);
                            this.loadFacets();
                        }
                    } else if (this.grid.opts.categoryTreeFacetingEnabled) {
                        CategoryService.resetCategoryTreeState();
                    }
                }.bind(this));
        };


        /**
         * Perform searching with facet filtering binding
         *
         * @param {number} [page]
         * @returns {Promise}
         */
        ProductList.prototype.search = function (page) {
            var gridResult = this.grid.search(page);

            if (this.grid.opts.facetingEnabled || this.grid.opts.categoryTreeFacetingEnabled) {
                this.loadFacets();
            }

            if (!this.grid.opts.facetingEnabled) {
                ProducerFilterService.loadFilter();
            }

            return gridResult;
        };

        /**
         * @returns {Promise}
         */
        ProductList.prototype.innerQueryChanged = function () {
            if (this.grid.innerQuery && this.grid.innerQuery.length > 1) {
                this.grid.delaySearch();
            } else if (!this.grid.innerQuery || !this.grid.innerQuery.length) {
                this.grid.delaySearch();
            }
        };

        /**
         * Clear second (inner) query search param.
         */
        ProductList.prototype.clearInnerQuery = function () {
            this.grid.innerQuery = null;
            this.grid.search();
        };

        // Used to toggling filters section
        ProductList.prototype.filtersBar = CatalogFiltersService.bar;

        /**
         * ProductDialog API
         *
         * @type {{shown: boolean, product: null, open: Function}}
         */
        ProductList.prototype.productDialog = {
            shown: false,
            product: null,
            loading: false,
            open: function (product) {
                this.product = product;
                this.shown = true;
                this.loading = true;
                API.get('products/' + product.id).then(function (product) {
                    angular.extend(this.product, product);
                    this.loading = false;
                }.bind(this));
            }
        };

        /**
         * SuppliersDialog API
         */
        ProductList.prototype.suppliersDialog = {
            shown: false,
            product: null,
            loading: false,
            open: function (product) {
                this.product = product;
                this.shown = true;
                this.loading = true;
                API.get('products/' + product.id).then(function (product) {
                    angular.extend(this.product, product);
                    this.loading = false;
                }.bind(this));
            }
        };


        return {
            /**
             * @param {Object} $scope
             * @param {Object} opts ProductList options
             * @returns {ProductList}
             */
            create: function ($scope, opts) {
                return new ProductList(new ProductGrid(opts), $scope, opts);
            }
        };
    }
]);
