/**
 * @author Modest Machnicki
 */
'use strict';
angular.module('B2B').factory('BooleanHeaderFilter', ['HeaderFilter',
    function (HeaderFilter) {

        /**
         * @constructor
         */
        var BooleanHeaderFilter = function (filter, scope) {
            HeaderFilter.call(this, filter.name, scope);
            this.filter = filter;
        };

        BooleanHeaderFilter.prototype = Object.create(HeaderFilter.prototype);
        BooleanHeaderFilter.prototype.constructor = BooleanHeaderFilter;

        /**
         * Bind selected values list
         */
        BooleanHeaderFilter.prototype.bindValues = function () {
            if (this.filter.checked) {
                this.values.push({
                    name: 'TAK',
                    remove: function () {
                        this.filter.clearSelection();
                    }.bind(this)
                });
            }
        };

        /**
         * Clear all attribute filter values
         */
        BooleanHeaderFilter.prototype.remove = function () {
            this.filter.clearSelection();
        };

        BooleanHeaderFilter.prototype.init = function () {
            this.scope.$on(this.filter.id + '.filter.changed', function () {
                this.refreshValues();
            }.bind(this));
            this.refreshValues();
        };

        return BooleanHeaderFilter;
    }
]);